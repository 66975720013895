import Typography from '@mui/material/Typography'
import BackIcon from '@mui/icons-material/KeyboardArrowLeft'
import getToday from 'helpers/getToday'
import format from 'date-fns/format'

function Header() {
  const today = getToday()
  const date = format(today, 'M/d')
  return (
    <div
      style={{
        position: 'absolute',
        top: 24,
        height: 80,
        backgroundColor: '#D34500',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 280,
        borderRadius: '28px 28px 0 0',
        paddingTop: 48,
      }}
    >
      <BackIcon style={{ position: 'absolute', left: 16 }} />
      <Typography style={{ fontWeight: 'bold' }}>{`${date} 日報表`}</Typography>
    </div>
  )
}

export default Header
