import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import useDateRange from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useDateRange'
import useCustomFields from 'components/pages/ReportsPage/_components/DailyReportTemplate/_hooks/useCustomFields'
import format from 'date-fns/format'
import React, { Suspense, useState } from 'react'
import DailyCustomFieldChart from './_components/DailyCustomFieldChart'
import useDailyReports from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useDailyReports'

const DailyRevenueChart = React.lazy(
  () => import('./_components/DailyRevenueChart')
)

const DailyCustomersChart = React.lazy(
  () => import('./_components/DailyCustomersChart')
)

const DailyOvertimeHoursChart = React.lazy(
  () => import('./_components/DailyOvertimeHoursChart')
)

interface Props {
  open: boolean
  onClose: () => void
}

function DailyReportsChartDialog({ open, onClose }: Props) {
  const dailyReports = useDailyReports()
  const customFields = useCustomFields()
  const { startDate, endDate } = useDateRange()
  const sDate = format(startDate, 'yyyy/M/d')
  const eDate = format(endDate, 'yyyy/M/d')
  const [chartType, setChartType] = useState('revenue')

  const onSelect = (event: SelectChangeEvent<string>) => {
    setChartType(event.target.value)
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="primary" variant="h5">
            {`每日回報: ${sDate} ~ ${eDate} `}
          </Typography>

          {chartType.startsWith('custom-') && (
            <div style={{ display: 'flex' }}>
              總計：
              {dailyReports?.reduce((acc, dailyReport) => {
                const title = chartType.split('custom-')[1]

                if (!dailyReport.metaJson) return acc
                if (!JSON.parse(dailyReport.metaJson)?.customFields) return acc

                const value =
                  JSON.parse(dailyReport.metaJson).customFields[title] ?? 0

                return acc + parseInt(value)
              }, 0)}
            </div>
          )}

          <Select value={chartType} onChange={onSelect}>
            <MenuItem value={'revenue'}>營業額</MenuItem>
            <MenuItem value={'customers'}>來客數</MenuItem>
            <MenuItem value={'overtimeHours'}>總超時</MenuItem>
            {customFields.map(customField => (
              <MenuItem value={`custom-${customField.title}`}>
                {customField.title}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Suspense fallback="">
          {chartType === 'revenue' && <DailyRevenueChart />}
          {chartType === 'customers' && <DailyCustomersChart />}
          {chartType === 'overtimeHours' && <DailyOvertimeHoursChart />}
          {chartType.startsWith('custom-') && (
            <DailyCustomFieldChart
              customField={
                customFields.find(
                  ({ title }) => chartType === `custom-${title}`
                )!
              }
            />
          )}
        </Suspense>
      </DialogContent>
    </Dialog>
  )
}

export default DailyReportsChartDialog
