import { useAtom } from 'jotai'
import { userSessionsAtom } from '../_atoms/Sessions'
import { useEffect } from 'react'

function useSessions() {
  const [sessions, query] = useAtom(userSessionsAtom)

  useEffect(() => query(), [query])

  return sessions
}

export default useSessions
