import { sessionAtom } from 'atoms/Session'
import { gqlClientAtom } from 'gql'
import { BusinessWorkplace } from 'gql/types'
import { atom } from 'jotai'
import { SetDefaultDomainDialog_GetBusinessWorkplaces } from './_gql'

const queryResultAtom = atom<BusinessWorkplace[] | null>(null)

export const workplacesAtom = atom(
  get => get(queryResultAtom),
  (get, set) => {
    const queryData = async () => {
      const client = get(gqlClientAtom)
      const businessId = get(sessionAtom)?.businessId
      if (!client || !businessId) return
      const result = await client
        .query(SetDefaultDomainDialog_GetBusinessWorkplaces, {
          businessId,
        })
        .toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

const parse = (result: any): BusinessWorkplace[] | null =>
  result?.data?.businessWorkplaces
