import { WritableAtom } from 'jotai'
import { useImmerAtom } from 'jotai-immer'
import { useCallback } from 'react'

const useImmerReducer = (
  anAtom: WritableAtom<any, [(draft: any) => any], void | Promise<void>>,
  reducer: (prev: any, action: any) => void
) => {
  const [state, setStateWithImmer] = useImmerAtom(anAtom)
  const dispatch = useCallback(
    (action: any) => {
      setStateWithImmer(draft => reducer(draft, action))
    },
    [setStateWithImmer, reducer]
  )
  return [state, dispatch]
}

export default useImmerReducer
