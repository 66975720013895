import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { workplaceDailyReportsAtom } from '../../DailyReportsPage/_atoms/WorkplaceDailyReports'
import useWorkplaceId from './useWorkplaceId'
import useYear from './useYear'

function useDailyReports() {
  const workplaceId = useWorkplaceId()
  const year = useYear()
  const sDate = `${year}-01-01`
  const eDate = `${year}-12-31`
  const [dailyReports, queryDailyReports] = useAtom(
    workplaceDailyReportsAtom(`${workplaceId}:${sDate}:${eDate}`)
  )

  useEffect(() => queryDailyReports(), [queryDailyReports])

  return dailyReports
}

export default useDailyReports
