const getLeaveTypeName = (type: string) => {
  switch (type) {
    case 'tw_annual':
      return '特休'

    case 'tw_comp':
      return '補休'

    case 'tw_personal':
      return '事假'

    case 'tw_sick':
      return '病假'

    case 'tw_menstruation':
      return '生理假'

    case 'tw_typhoon':
      return '颱風假'

    case 'tw_official':
      return '公假'

    case 'tw_wedding':
      return '婚假'

    case 'tw_maternity':
      return '產假'

    case 'tw_funeral':
      return '喪假'

    default:
      throw new Error(`unknown leave type: ${type}`)
  }
}

export default getLeaveTypeName
