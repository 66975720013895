import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { sessionAtom, sessionReducer } from 'atoms/Session'
import axios from 'axios'
import { currentUserAtom } from 'components/layouts/PageLayout/AppBar/_components/CurrentUserName'
import addDays from 'date-fns/addDays'
import getUnixTime from 'date-fns/getUnixTime'
import { getEnv } from 'env'
import useImmerReducer from 'helpers/useImmerReducer'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import ExitIcon from '@mui/icons-material/ExitToApp'
import Cookies from 'js-cookie'

function ExitButton() {
  const currentUser = useAtomValue(currentUserAtom)
  const [password, setPassword] = useState()
  const [passwordError, setPasswordError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [session, dispatch] = useImmerReducer(sessionAtom, sessionReducer)

  const [showConfirmPasswordDialog, setShowConfirmPasswordDialog] =
    useState(false)

  const handleDisableTimeClockMode = () => {
    setShowConfirmPasswordDialog(true)
  }

  const handlePasswordChanged = (event: any) => {
    setPassword(event.target.value)
  }

  const handleConfirmPassword = async () => {
    setIsSubmitting(true)
    try {
      const { status } = await axios.post(
        `${getEnv().authApiUrl}/confirm_password`,
        {
          password,
        },
        { withCredentials: true }
      )

      if (status === 200) {
        Cookies.set('timeClockMode', 'false', { expires: 365 })
        dispatch({
          type: 'update',
          session: {
            ...session,
            mode: undefined,
            expireAt: getUnixTime(addDays(new Date(), 59)),
          },
        })
      }
    } catch (error: any) {
      if (error.response?.data?.error === 'incorrect password') {
        setPasswordError('密碼錯誤')
      }
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <Button
        startIcon={<ExitIcon />}
        variant="outlined"
        onClick={handleDisableTimeClockMode}
      >
        關閉打卡鐘模式
      </Button>

      <Dialog
        maxWidth="xs"
        aria-labelledby="simple-dialog-title"
        open={showConfirmPasswordDialog}
        onClose={() => setShowConfirmPasswordDialog(false)}
      >
        <DialogTitle>{`請輸入 ${
          currentUser?.name || '...'
        } 的密碼`}</DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <TextField
              autoFocus
              autoComplete="new-password"
              type="password"
              variant="outlined"
              value={password}
              onChange={handlePasswordChanged}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  handleConfirmPassword()
                }
              }}
            />
            <span style={{ color: 'red' }}>{passwordError}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!password || isSubmitting}
            onClick={handleConfirmPassword}
            color="primary"
            variant="contained"
          >
            {isSubmitting ? (
              <CircularProgress style={{ width: 24, height: 24 }} />
            ) : (
              'OK'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExitButton
