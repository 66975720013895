import addMonths from 'date-fns/addMonths'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import startOfMonth from 'date-fns/startOfMonth'
import getToday from 'helpers/getToday'
import { atom } from 'jotai'

const today = getToday()
const startDate = format(startOfMonth(addMonths(today, -1)), 'yyyy-MM-dd')
const endDate = format(endOfMonth(addMonths(today, -1)), 'yyyy-MM-dd')

export const dateRangeAtom = atom(`${startDate}:${endDate}`)
