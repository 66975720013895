export const getEnv = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return {
      version: process.env.REACT_APP_VERSION!,
      debug: process.env.REACT_APP_PROD_DEBUG!,
      websiteUrl: process.env.REACT_APP_PROD_CREW_WEBSITE_URL!,
      wsUrl: process.env.REACT_APP_PROD_CREW_WS_URL!,
      authApiUrl: process.env.REACT_APP_PROD_CREW_AUTH_API_URL!,
      paymentApiUrl: process.env.REACT_APP_PROD_CREW_PAYMENT_API_URL!,
      apiUrl: process.env.REACT_APP_PROD_CREW_API_URL!,
      restApiUrl: process.env.REACT_APP_PROD_CREW_REST_API_URL!,
      oauthUrl: process.env.REACT_APP_CREW_OAUTH_URL!,
      ecpayCheckoutUrl: process.env.REACT_APP_PROD_ECPAY_CHECKOUT_URL!,
      gmapApiKey: process.env.REACT_APP_PROD_GMAP_API_KEY!,
      firebaseApiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY!,
      firebaseAuthDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN!,
      firebaseProjectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID!,
      firebaseStorageBucket:
        process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET!,
      firebaseMessagingSenderId:
        process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID!,
      firebaseAppId: process.env.REACT_APP_PROD_FIREBASE_APP_ID!,
      firebaseMeasurementId:
        process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID!,
      mapboxToken: process.env.REACT_APP_PROD_MAPBOX_TOKEN!,
      sentryDsn: process.env.REACT_APP_PROD_SENTRY_DSN!,
      storybookMode: !!process.env.STORYBOOK_MODE,
    }
  }

  return {
    version: process.env.REACT_APP_VERSION!,
    debug: process.env.REACT_APP_DEBUG!,
    websiteUrl: process.env.REACT_APP_CREW_WEBSITE_URL!,
    wsUrl: process.env.REACT_APP_CREW_WS_URL || '/socket',
    authApiUrl: process.env.REACT_APP_CREW_AUTH_API_URL || '/auth',
    paymentApiUrl: process.env.REACT_APP_CREW_PAYMENT_API_URL!,
    apiUrl: process.env.REACT_APP_CREW_API_URL || '/api/graphql/v1',
    restApiUrl: process.env.REACT_APP_CREW_REST_API_URL! || '/api/v1',
    oauthUrl: process.env.REACT_APP_CREW_OAUTH_URL! || '/oauth',
    ecpayCheckoutUrl: process.env.REACT_APP_ECPAY_CHECKOUT_URL!,
    gmapApiKey: process.env.REACT_APP_GMAP_API_KEY!,
    firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
    firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
    firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
    firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
    firebaseMessagingSenderId:
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
    firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID!,
    firebaseMeasurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
    mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN!,
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
    storybookMode: !!process.env.STORYBOOK_MODE,
  }
}
