import EnterIcon from '@mui/icons-material/ExitToApp'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { businessModeAtom, sessionAtom, sessionReducer } from 'atoms/Session'
import { businessAtom } from 'atoms/Session/Business'
import { BroadcastChannel } from 'broadcast-channel'
import { BusinessWorkplace } from 'gql/types'
import sortByIndex from 'helpers/sortByIndex'
import useImmerReducer from 'helpers/useImmerReducer'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useWorkplaces from './_hooks/useWorkplaces'

interface Props {
  open: boolean
  onClose: () => void
}

function SetDefaultDomainDialog({ onClose, open }: Props) {
  const businessMode = useAtomValue(businessModeAtom)
  const business = useAtomValue(businessAtom)
  const workplaces = useWorkplaces()
  const [session, dispatch] = useImmerReducer(sessionAtom, sessionReducer)
  const location = useLocation()
  const navigate = useNavigate()

  const channel = useMemo(() => new BroadcastChannel('sessionWorkplaceId'), [])

  const handleSetDefaultWorkplace = (workplaceId?: string) => async () => {
    channel.postMessage({ workplaceId })
    dispatch({ type: 'update', session: { workplaceId } })
    if (
      (!workplaceId && !['/attendance'].includes(location.pathname)) ||
      location.pathname === '/alerts' ||
      location.pathname === '/dailyReports' ||
      location.pathname === '/billing'
    ) {
      navigate('/', { replace: true })
    } else {
      window.location.reload()
    }
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  if (!business || !session || !workplaces) return null

  const sortedWorkplaces = JSON.parse(
    JSON.stringify(
      workplaces.filter(
        ({ id, active }) => active && id !== session.workplaceId
      )
    )
  ).sort(sortByIndex) as BusinessWorkplace[]

  const showBusinessModeButton =
    (business.isAdmin || business.isOwner) && !businessMode

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        請選擇檢視門店
        {showBusinessModeButton && (
          <Button
            variant="outlined"
            endIcon={<EnterIcon />}
            style={{ position: 'absolute', right: 16 }}
            onClick={handleSetDefaultWorkplace()}
          >
            進入企業設定頁面
          </Button>
        )}
      </DialogTitle>
      <DialogContent dividers style={{ width: 600 }}>
        <List>
          {sortedWorkplaces.sort(sortByIndex).map(workplace => (
            <ListItem
              button
              key={workplace.id}
              onClick={handleSetDefaultWorkplace(workplace.id)}
            >
              <ListItemText primary={workplace.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default SetDefaultDomainDialog
