import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import { businessAtom } from 'atoms/Session/Business'
import { workplaceAtom } from 'atoms/Session/Workplace'
import isLowRes from 'helpers/isLowRes'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import SetDefaultDomainDialog from './_components/SetDefaultDomainDialog'
import { businessModeAtom } from 'atoms/Session'

function DomainButton() {
  const businessMode = useAtomValue(businessModeAtom)
  const business = useAtomValue(businessAtom)
  const workplace = useAtomValue(workplaceAtom)
  const [showDialog, setShowDialog] = useState(false)

  if (!business) return null

  const isPrivileged =
    business.isOwner ||
    business.isAdmin ||
    business.myPrivilegedWorkplaces.length > 0

  return (
    <>
      <Button
        style={{
          textTransform: 'none',
          color: 'white',
          fontSize: isLowRes ? 14 : 18,
        }}
        disabled={!isPrivileged}
        size="medium"
        onClick={() => setShowDialog(true)}
        endIcon={isPrivileged ? <ArrowDropDownIcon /> : null}
      >
        <span>{businessMode ? '請選擇檢視門店' : workplace!.name}</span>
      </Button>
      <SetDefaultDomainDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </>
  )
}

export default DomainButton
