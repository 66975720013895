import { useAtom } from 'jotai'
import { activeBusinessesAtom } from '../atoms/ActiveBusinesses'
import { useEffect } from 'react'

function useActiveBusinesses() {
  const [activeBusinesses, query] = useAtom(activeBusinessesAtom)

  useEffect(() => query(), [query])

  return activeBusinesses
}

export default useActiveBusinesses
