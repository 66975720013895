import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Column, Row, Text } from '@react-email/components'
import getRoundedNumber from 'helpers/getRoundedNumber'
import { useEffect } from 'react'
import useWorkplaceLeaderboards from './_hooks/useWorkplaceLeaderboards'

const formatter = new Intl.NumberFormat('zh-TW', {
  style: 'currency',
  currency: 'TWD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

function WorkplaceLeaderboards() {
  const [workplaceLeaderboards, queryWorkplaceLeaderboards] =
    useWorkplaceLeaderboards()

  useEffect(() => queryWorkplaceLeaderboards(), [queryWorkplaceLeaderboards])

  if (!workplaceLeaderboards)
    return (
      <div style={{ height: 80, width: '100%' }}>
        <CircularProgress size={20} style={{ marginRight: 16 }} />
        <Typography>載入資料中...</Typography>
      </div>
    )

  return (
    <>
      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>排班人事成本</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>門店</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>人事成本</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {workplaceLeaderboards?.laborCosts.map(laborCost => (
        <Row>
          <Column width={'60%'}>
            <Text style={{ margin: '0 8px', color: 'transparent' }}>dummy</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'50%'}>
                <Text style={{ margin: 8 }}>{laborCost.name}</Text>
              </Column>
              <Column align="right" width={'50%'}>
                <Text style={{ margin: 8, color: '#d34500' }}>
                  {formatter.format(laborCost.value!)}
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}

      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>員工打卡准點率</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>門店</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>打卡准點率</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {workplaceLeaderboards?.attendanceOntimeRates.map(
        attendanceOntimeRate => (
          <Row>
            <Column width={'60%'}>
              <Text style={{ margin: '0 8px', color: 'transparent' }}>
                dummy
              </Text>
            </Column>
            <Column width={'40%'}>
              <Row>
                <Column align="center" width={'50%'}>
                  <Text style={{ margin: 8 }}>{attendanceOntimeRate.name}</Text>
                </Column>
                <Column align="right" width={'50%'}>
                  <Text style={{ margin: 8, color: '#d34500' }}>
                    {getRoundedNumber(attendanceOntimeRate.value! * 100)}%
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
        )
      )}

      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>員工請假次數</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>門店</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>請假次數</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {workplaceLeaderboards?.leaveCounts.map(leaveCount => (
        <Row>
          <Column width={'60%'}>
            <Text style={{ margin: '0 8px', color: 'transparent' }}>dummy</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'50%'}>
                <Text style={{ margin: 8 }}>{leaveCount.name}</Text>
              </Column>
              <Column align="right" width={'50%'}>
                <Text style={{ margin: 8, color: '#d34500' }}>
                  {leaveCount.value}
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}
    </>
  )
}

export default WorkplaceLeaderboards
