import { businessAtom } from 'atoms/Session/Business'
import { workplaceAtom } from 'atoms/Session/Workplace'
import { atom } from 'jotai'

export const authAtom = atom(get => {
  const business = get(businessAtom)
  const workplace = get(workplaceAtom)

  if (!business) return null

  const hasFullPrivilege =
    business?.isOwner ||
    business?.isAdmin ||
    workplace?.privileges?.includes('all') ||
    false

  return {
    isOwner: business?.isOwner || false,
    isAdmin: business?.isAdmin || false,
    hasFullPrivilege,
    hasAttendancePrivilege:
      hasFullPrivilege ||
      workplace?.privileges?.includes('attendance') ||
      false,
    hasSchedulingPrivilege:
      hasFullPrivilege ||
      workplace?.privileges?.includes('scheduling') ||
      false,
    hasStaffManagementPrivilege:
      hasFullPrivilege ||
      workplace?.privileges?.includes('staff_management') ||
      false,
    hasPayrollPrivilege:
      hasFullPrivilege || workplace?.privileges?.includes('payroll') || false,
    hasTimeClockPrivilege:
      hasFullPrivilege ||
      workplace?.privileges?.includes('time_clock') ||
      false,
  }
})
