import { CircularProgress } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import {
  Column,
  Container,
  Heading,
  Hr,
  Img,
  Row,
  Section,
  Text,
} from '@react-email/components'
import { businessAtom } from 'atoms/Session/Business'
import Select from 'components/atoms/Select'
import addMonths from 'date-fns/addMonths'
import differenceInMonths from 'date-fns/differenceInMonths'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import startOfMonth from 'date-fns/startOfMonth'
import getToday from 'helpers/getToday'
import parseDate from 'helpers/parseDate'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useMemo } from 'react'
import { dateRangeAtom } from './_atoms'
import EmployeeLeaderboards from './_components/EmployeeLeaderboards'
import WorkplaceLeaderboards from './_components/WorkplaceLeaderboards'
import useMonthlyReportSummary from './_hooks/useMonthlyReportSummary'

const formatter = new Intl.NumberFormat('zh-TW', {
  style: 'currency',
  currency: 'TWD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

function MonthlyReportsPage() {
  const today = getToday()
  const [dateRange, setDateRange] = useAtom(dateRangeAtom)
  const business = useAtomValue(businessAtom)

  const [summary, querySummary] = useMonthlyReportSummary()

  useEffect(() => querySummary(), [querySummary])

  const startDate = parseDate(dateRange.split(':')[0])

  const startDates = useMemo(() => {
    if (!business) return []
    const maxStartDate = startOfMonth(addMonths(today, -1))
    const months = differenceInMonths(
      maxStartDate,
      parseDate(business.trialEndDate)
    )
    return months > 0
      ? [...Array(months).keys()].map(index => addMonths(maxStartDate, -index))
      : []
  }, [business, today])

  const auditPayrollCount = summary?.auditPayrollCount
  const auditShiftCount = (business?.activeEmployeeCount || 0) * 31
  const shiftsPublished = summary?.shiftCount
  const timeSaving = summary?.timeSaving
  const costSaving = summary?.costSaving
  const clockInCount = summary?.clockInCount
  const leaveApprovedCount = summary?.approvedLeaveCount

  const handleStartDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = parseDate(e.target.value)
    const sDate = format(newStartDate, 'yyyy-MM-dd')
    const eDate = format(endOfMonth(newStartDate), 'yyyy-MM-dd')
    setDateRange(`${sDate}:${eDate}`)
  }

  return (
    <div style={{ flex: 1, overflowY: 'auto' }}>
      <Container
        style={{
          backgroundColor: 'white',
          border: '1px solid #eaeaea',
          borderRadius: 8,
          margin: '48px auto',
          padding: 20,
          maxWidth: 800,
        }}
      >
        <Section>
          <Row>
            <Column>
              <Img
                src="https://images.aiboucrew.com/assets/appIcon128.png"
                width="48"
                height="48"
                alt="Aibou Crew"
                style={{
                  borderRadius: 6,
                }}
              />
            </Column>

            <Column align="right">
              <Heading
                style={{
                  fontSize: 20,
                  color: '#D34500',
                }}
              >
                <Select
                  style={{ marginRight: 8 }}
                  value={format(startDate, 'yyyy-MM-dd')}
                  onChange={handleStartDateChanged}
                >
                  {startDates.map(date => (
                    <MenuItem value={format(date, 'yyyy-MM-dd')}>
                      {`${format(date, 'yyyy')} 年 ${format(date, 'M')} 月`}
                    </MenuItem>
                  ))}
                </Select>
                數據分析報告 (Beta)
              </Heading>
            </Column>
          </Row>
        </Section>

        <Section style={{ marginTop: 32 }}>
          <Row align="center" width={'100%'}>
            <Column
              width={'30%'}
              style={{
                border: '2px solid lightgrey',
                borderRadius: 8,
                padding: '8px 8px',
                margin: 8,
              }}
            >
              <Text
                style={{
                  color: '#d34500',
                  fontSize: 24,
                  margin: '8px 0',
                }}
              >
                省成本
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                已節省
                {timeSaving ? (
                  <span style={{ color: '#d34500', margin: '0 8px' }}>
                    {formatter.format(timeSaving * 200)}
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
                時間成本
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                已節省
                {costSaving ? (
                  <span style={{ color: '#d34500', margin: '0 8px' }}>
                    {formatter.format(costSaving)}
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
                額外加班費
              </Text>
              <Text
                style={{ margin: 0, textAlign: 'end', color: 'transparent' }}
              >
                dummy
              </Text>
            </Column>
            <Column width={'3%'} />
            <Column
              width={'30%'}
              style={{
                border: '2px solid lightgrey',
                borderRadius: 8,
                padding: '8px 8px',
              }}
            >
              <Text style={{ margin: 0, fontSize: 24, color: '#d34500' }}>
                省時間
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                已發佈
                {shiftsPublished ? (
                  <span style={{ color: '#d34500', margin: '0 8px' }}>
                    {shiftsPublished}次
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
                排班
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                已節省
                {timeSaving ? (
                  <span style={{ color: '#d34500', margin: '0 8px' }}>
                    {timeSaving}小時
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
                人工作業
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                自動合法計薪
                {auditPayrollCount ? (
                  <span style={{ color: '#d34500', margin: '0 8px' }}>
                    {auditPayrollCount}
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
                人次
              </Text>
            </Column>
            <Column width={'3%'} />
            <Column
              width={'30%'}
              style={{
                border: '2px solid lightgrey',
                borderRadius: 8,
                padding: '8px 8px',
              }}
            >
              <Text style={{ margin: 0, fontSize: 24, color: '#d34500' }}>
                省煩惱
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                記錄打卡
                {clockInCount ? (
                  <span style={{ color: '#d34500', marginLeft: 8 }}>
                    {clockInCount * 2}次
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                核准請假
                {leaveApprovedCount ? (
                  <span style={{ color: '#d34500', margin: '0 8px' }}>
                    {leaveApprovedCount}次
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: 'end',
                  color: 'grey',
                }}
              >
                勞基法檢測
                {leaveApprovedCount ? (
                  <span style={{ color: '#d34500', margin: '0 8px' }}>
                    {auditShiftCount}
                  </span>
                ) : (
                  <CircularProgress size={12} style={{ margin: '0 8px' }} />
                )}
                班次
              </Text>
            </Column>
          </Row>
        </Section>

        <Text style={{ marginTop: 32, fontSize: 20, color: '#d34500' }}>
          門市表現
        </Text>
        <Hr style={{ marginTop: -16 }} />

        <WorkplaceLeaderboards />

        <Hr />

        <Text style={{ marginTop: 32, fontSize: 20, color: '#d34500' }}>
          員工表現
        </Text>
        <Hr style={{ marginTop: -16 }} />

        <EmployeeLeaderboards />

        <Hr />
      </Container>
    </div>
  )
}

export default MonthlyReportsPage
