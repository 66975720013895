import { businessAtom } from 'atoms/Session/Business'
import { gqlClientAtom } from 'gql'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { MonthlyReportsPage_MonthlyReportsSummaryEmployeeLeaderboards } from './_gql'
import { BusinessMonthlyReportEmployeeLeaderboards } from 'gql/types'

const queryResultAtom = atomFamily((dateRange: string) =>
  atom<BusinessMonthlyReportEmployeeLeaderboards | null>(null)
)

export const employeeLeaderboardsAtom = atomFamily((dateRange: string) =>
  atom(
    get => get(queryResultAtom(dateRange)),
    (get, set) => {
      const queryData = async () => {
        const client = get(gqlClientAtom)
        const business = get(businessAtom)
        if (!client || !business) return
        const [startDate, endDate] = dateRange.split(':')
        const result = await client
          .query(MonthlyReportsPage_MonthlyReportsSummaryEmployeeLeaderboards, {
            businessId: business.id,
            startDate,
            endDate,
          })
          .toPromise()
        if (result.data) {
          set(queryResultAtom(dateRange), parse(result))
        }
      }
      queryData()
    }
  )
)

const parse = (result: any): BusinessMonthlyReportEmployeeLeaderboards | null =>
  result?.data?.businessMonthlyReportEmployeeLeaderboards
