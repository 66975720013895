import { useAtom } from 'jotai'
import { shiftsAtom } from '../_atoms/Shifts'
import { useEffect } from 'react'

function useShifts() {
  const [shifts, query] = useAtom(shiftsAtom)

  useEffect(() => query(), [query])

  return shifts
}

export default useShifts
