import { LinearProgress } from '@mui/material'
import useDailyReports from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useDailyReports'
import { CustomField } from 'components/pages/ReportsPage/_components/DailyReportTemplate/_components/CustomFields'
import format from 'date-fns/format'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart, LineChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import parseDate from 'helpers/parseDate'

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
  GridComponent,
  VisualMapComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
])

interface OptionsProps {
  customField: CustomField
  series: any
}

const options = ({ customField, series }: OptionsProps) => ({
  tooltip: {
    trigger: 'axis',
    formatter: (params: any) => {
      var output = params[0].axisValueLabel + '<br/>'

      output += '<table class="w-full">'

      params.reverse().forEach((param: any) => {
        const value = param.data[1]
        if (value !== '0') {
          output += `<tr>
            <td>${param.marker}</td>
            <td>${param.seriesName}</td>
            <td class="text-right font-bold tabular-nums">${value}</td>
          </tr>`
        }
      })

      return output + '</table>'
    },
  },

  calculable: true,
  xAxis: [
    {
      type: 'category',
      axisLabel: { interval: 1, rotate: 45 },
    },
  ],
  yAxis: [
    {
      type: 'value',
      name: customField.title,
    },
  ],
  series,
})

interface Props {
  customField: CustomField
}

function DailyCustomFieldChart({ customField }: Props) {
  const dailyReports = useDailyReports()

  if (!dailyReports)
    return (
      <div style={{ width: 850, height: 450 }}>
        <div style={{ width: '100%', marginRight: 8 }}>
          <LinearProgress />
        </div>
      </div>
    )

  const series = [
    {
      type: 'bar',
      name: customField.title,
      stack: 1,
      color: 'green',
      data: dailyReports.map(dailyReport => {
        if (!dailyReport.metaJson) return 0
        if (!JSON.parse(dailyReport.metaJson)?.customFields) return 0

        const value =
          JSON.parse(dailyReport.metaJson).customFields[customField.title] ?? 0

        return [format(parseDate(dailyReport.date), 'M/d'), value]
      }),
    },
  ]

  return (
    <ReactEChartsCore
      notMerge
      lazyUpdate
      echarts={echarts}
      style={{ width: 850, height: 450 }}
      option={options({
        series,
        customField,
      })}
    />
  )
}

export default DailyCustomFieldChart
