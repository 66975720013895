import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import AppBar from './AppBar'
import Drawer from './Drawer'
import { useLocation } from 'react-router-dom'

interface PageLayoutProps {
  children: JSX.Element
}

export const DrawerHeader = styled('div')(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

function PageLayout({ children }: PageLayoutProps) {
  const location = useLocation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [location])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <AppBar open={open} onOpen={handleDrawerOpen} />
      <Drawer open={open} onClose={handleDrawerClose} />
      <div
        style={{
          marginLeft: 73,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <DrawerHeader />
        {children}
      </div>
    </div>
  )
}

export default PageLayout
