import { useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  width?: number
  height?: number
  children: any
  onClose: () => void
}

const NewWindow = ({ children, onClose, width = 600, height = 800 }: Props) => {
  const newWindow = useMemo(() => {
    const screenLeft = window.screenLeft
    const screenTop = window.screenTop
    const left = window.innerWidth / 2 - width / 2 + screenLeft
    const top = window.innerHeight / 2 - height / 2 + screenTop

    return window.open(
      'about:blank',
      'newWin',
      `width=${width},height=${height},left=${left},top=${top}`
    )
  }, [height, width])

  useEffect(() => {
    if (newWindow) {
      newWindow.onbeforeunload = () => {
        onClose()
      }
    }
  }, [newWindow, onClose])

  if (!newWindow) return null

  return createPortal(children, newWindow.document.body)
}

export default NewWindow
