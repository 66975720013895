import { useSetAtom } from 'jotai'
import useWorkplaceId from '../../DailyReportsPage/_hooks/useWorkplaceId'
import useDailyReportTemplate from './useDailyReportTemplate'
import { setWorkplaceDailyReportTemplateAtom } from '../_atoms/DailyReportTemplate'
import { CustomField } from '../_components/CustomFields'
import sortByIndex from 'helpers/sortByIndex'

function useSetCustomFields() {
  const dailyReportTemplate = useDailyReportTemplate()
  const workplaceId = useWorkplaceId()
  const setDailyReportTemplate = useSetAtom(setWorkplaceDailyReportTemplateAtom)

  return (customFields: CustomField[]) =>
    setDailyReportTemplate({
      dailyReportTemplate: {
        workplaceId,
        templateJson: JSON.stringify({
          ...JSON.parse(dailyReportTemplate?.templateJson!),
          fields: customFields.sort(sortByIndex).map((customField, index) => ({
            index: index,
            title: customField.title,
            type: customField.type,
            unit: customField.unit,
          })),
        }),
      },
    })
}

export default useSetCustomFields
