import useWorkplaceId from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useWorkplaceId'
import useWorkplaces from 'components/pages/ReportsPage/_hooks/useWorkplaces'

function useWorkplaceName() {
  const workplaces = useWorkplaces()
  const workplaceId = useWorkplaceId()
  return workplaces.find(({ id }) => id === workplaceId)?.name
}

export default useWorkplaceName
