import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import DragIcon from '@mui/icons-material/DragIndicator'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import useCustomFields from 'components/pages/ReportsPage/_components/DailyReportTemplate/_hooks/useCustomFields'
import useSetCustomFields from 'components/pages/ReportsPage/_components/DailyReportTemplate/_hooks/useSetCustomFields'
import { useState } from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { CustomField } from '../../../..'
import styles from './index.module.css'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'

const getFieldTypeLabel = (customField: CustomField) => {
  switch (customField.type) {
    case 'expense':
      return '支出'
    case 'income':
      return '收入'
    case 'custom':
      return '自訂'
    default:
      return '支出'
  }
}

interface Props {
  customField: CustomField
  index: number
}

function DraggableListItem({ customField, index }: Props) {
  const customFields = useCustomFields()
  const setCustomFields = useSetCustomFields()

  const [title, setTitle] = useState(customField.title)
  const [type, setType] = useState(customField.type)
  const [unit, setUnit] = useState(customField.unit)
  const [editMode, setEditMode] = useState(false)

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value.trim())

  const onTypeSelect = (event: SelectChangeEvent<string>) =>
    setType(event.target.value as any)

  const onUnitSelect = (event: SelectChangeEvent<string>) =>
    setUnit(event.target.value as any)

  const handleDone = () => {
    setEditMode(false)

    setCustomFields([
      ...customFields.filter(({ title: tl }) => tl !== customField.title),
      {
        index,
        title,
        type,
        unit,
      },
    ])
  }

  const handleDelete = () => {
    setCustomFields([
      ...customFields.filter(({ title: tl }) => tl !== customField.title),
    ])
  }

  const invalidInput =
    !title ||
    !!customFields.find(
      field =>
        field.title === title && field.type === type && field.unit === unit
    )

  return (
    <Draggable
      key={customField.title}
      draggableId={customField.title}
      index={index}
    >
      {(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot
      ) => (
        <ListItem
          className={styles.root}
          divider
          key={customField.title}
          ref={draggableProvided.innerRef}
          sx={{
            background: snapshot.isDragging ? 'rgb(235,235,235)' : 'none',
          }}
          style={{
            ...draggableProvided.draggableProps.style,
          }}
          {...draggableProvided.draggableProps}
        >
          {/* <ListItemIcon
            {...draggableProvided.dragHandleProps}
            style={{ cursor: 'grab' }}
          >
            <DragIcon />
          </ListItemIcon> */}

          {!editMode && (
            <>
              <ListItemText primary={customField.title} />
              <Chip label={getFieldTypeLabel(customField)} />
              {customField.type === 'custom' && (
                <Chip
                  style={{ marginLeft: 8 }}
                  label={customField.unit || '$'}
                />
              )}
              <IconButton
                className={styles.icon}
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
              {customFields.length > 1 && (
                <IconButton className={styles.icon} onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          )}

          {editMode && (
            <>
              <TextField
                fullWidth
                size="small"
                value={title}
                onChange={onTitleChange}
              />

              <FormControl
                style={{ marginLeft: 8, flexShrink: 0 }}
                variant="outlined"
              >
                <Select
                  size="small"
                  defaultValue="expense"
                  value={type}
                  onChange={onTypeSelect}
                >
                  <MenuItem value={'expense'}>支出</MenuItem>
                  <MenuItem value={'income'}>收入</MenuItem>
                  <MenuItem value={'custom'}>自訂</MenuItem>
                </Select>
              </FormControl>

              {type === 'custom' && (
                <FormControl
                  style={{ marginLeft: 8, flexShrink: 0 }}
                  variant="outlined"
                >
                  <Select
                    size="small"
                    defaultValue={'$'}
                    value={unit}
                    onChange={onUnitSelect}
                  >
                    <MenuItem value={'$'}>$</MenuItem>
                    <MenuItem value={'人'}>人</MenuItem>
                    <MenuItem value={'次'}>次</MenuItem>
                  </Select>
                </FormControl>
              )}

              <IconButton
                disabled={invalidInput}
                style={{ color: invalidInput ? 'lightgrey' : 'green' }}
                onClick={handleDone}
              >
                <DoneIcon />
              </IconButton>
              <IconButton
                style={{ color: 'red' }}
                onClick={() => setEditMode(false)}
              >
                <CancelIcon />
              </IconButton>
            </>
          )}
        </ListItem>
      )}
    </Draggable>
  )
}

export default DraggableListItem
