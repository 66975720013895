import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useCustomFields from '../../_hooks/useCustomFields'
import useSetCustomFields from '../../_hooks/useSetCustomFields'
import ReorderableList from './_components/ReorderableList'

export interface CustomField {
  index: number
  title: string
  type?: 'expense' | 'income' | 'custom'
  unit?: string
}

const getNewCustomField = (customFields: CustomField[]) => {
  let newIndex = customFields.length
  let newTitle = `自訂欄位 ${newIndex}`
  // eslint-disable-next-line no-loop-func
  while (customFields.findIndex(({ title }) => title === newTitle) > -1) {
    newIndex = newIndex + 1
    newTitle = `自訂欄位 ${newIndex}`
  }

  return {
    index: customFields.length,
    title: newTitle,
    type: 'expense',
  } as CustomField
}

function CustomFields() {
  const customFields = useCustomFields()
  const setCustomFields = useSetCustomFields()

  const handleAdd = () => {
    const newCustomField = getNewCustomField(customFields)
    setCustomFields([newCustomField, ...customFields])
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 8,
          position: 'sticky',
        }}
      >
        <Typography variant="h6">門店自訂欄位</Typography>
        <Button
          disabled={customFields.length >= 50}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          新增
        </Button>
      </div>
      <ReorderableList />
    </div>
  )
}

export default CustomFields
