import ReportIcon from '@mui/icons-material/Assignment'
import BillingIcon from '@mui/icons-material/CreditCard'
import ReferralIcon from '@mui/icons-material/Redeem'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EmployeesIcon from '@mui/icons-material/Group'
import SchedulerIcon from '@mui/icons-material/Event'
import PreferencesIcon from '@mui/icons-material/Settings'
import WorkplaceIcon from '@mui/icons-material/Storefront'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ReactComponent as CostMarginAlertsIcon } from 'assets/running_with_errors_black_24dp.svg'
import { FormattedMessage } from 'react-intl'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useAtomValue } from 'jotai'
import { businessAtom } from 'atoms/Session/Business'
import { useMemo } from 'react'
import getToday from 'helpers/getToday'
import parseDate from 'helpers/parseDate'
import isAfter from 'date-fns/isAfter'

function BusinessDrawer() {
  const business = useAtomValue(businessAtom)
  const location = useLocation()

  const isSubscribed = useMemo(
    () =>
      business?.subscriptionPlanEndDate &&
      isAfter(parseDate(business?.subscriptionPlanEndDate), getToday()),
    [business?.subscriptionPlanEndDate]
  )

  return (
    <>
      <List>
        <Link component={RouterLink} to="/">
          <ListItemButton selected={location.pathname === '/'}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Dashboard"
                  id="SApozu"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>
        <Link component={RouterLink} to="/reports">
          <ListItemButton selected={location.pathname.startsWith('/reports')}>
            <ListItemIcon>
              <SchedulerIcon fill="grey" />
              <Typography
                variant="caption"
                style={{ position: 'absolute', bottom: 2, right: 2 }}
              >
                beta
              </Typography>
            </ListItemIcon>
            <ListItemText primary="門店報表" />
          </ListItemButton>
        </Link>
        <Link component={RouterLink} to="/targetRevenueSettings">
          <ListItemButton
            selected={location.pathname.startsWith('/targetRevenueSettings')}
          >
            <ListItemIcon>
              <ReportIcon fill="grey" />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Target Revenue Settings"
                  id="br9rR/"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>
        <Link component={RouterLink} to="/alerts">
          <ListItemButton selected={location.pathname.startsWith('/alerts')}>
            <ListItemIcon>
              <CostMarginAlertsIcon fill="grey" />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Alerts"
                  id="O7P+Q0"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>

        <Link component={RouterLink} to="/employees">
          <ListItemButton selected={location.pathname.startsWith('/employees')}>
            <ListItemIcon>
              <EmployeesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Employees"
                  id="NgeFUU"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>
        <Link component={RouterLink} to="/workplaces">
          <ListItemButton
            selected={location.pathname.startsWith('/workplaces')}
          >
            <ListItemIcon>
              <WorkplaceIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Workplaces"
                  id="+GqvXO"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>
        <Link component={RouterLink} to="/billing">
          <ListItemButton selected={location.pathname.startsWith('/billing')}>
            <ListItemIcon>
              <BillingIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Billing"
                  id="xtiLOC"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>
        {isSubscribed && (
          <Link component={RouterLink} to="/referrals">
            <ListItemButton
              selected={location.pathname.startsWith('/referrals')}
            >
              <ListItemIcon>
                <ReferralIcon />
                <Typography
                  variant="caption"
                  style={{ position: 'absolute', bottom: 2, right: 2 }}
                >
                  new
                </Typography>
              </ListItemIcon>
              <ListItemText primary={'推廣獎勵'} />
            </ListItemButton>
          </Link>
        )}
        <Link component={RouterLink} to="/settings">
          <ListItemButton selected={location.pathname.startsWith('/settings')}>
            <ListItemIcon>
              <PreferencesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Settings"
                  id="d0x48A"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>
      </List>
    </>
  )
}

export default BusinessDrawer
