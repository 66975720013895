import * as Types from './types';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const GetHolidaysDocument = gql`
    query GetHolidays($countryCode: String!, $startDate: String!, $endDate: String!) {
  holidays(countryCode: $countryCode, startDate: $startDate, endDate: $endDate) {
    id
    name
    date
  }
}
    `;

export function useGetHolidaysQuery(options: Omit<Urql.UseQueryArgs<Types.GetHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetHolidaysQuery, Types.GetHolidaysQueryVariables>({ query: GetHolidaysDocument, ...options });
};
export const CreateBusinessAdminDocument = gql`
    mutation CreateBusinessAdmin($input: CreateBusinessAdminInput!) {
  createBusinessAdmin(input: $input) {
    businessAdmin {
      businessId
    }
  }
}
    `;

export function useCreateBusinessAdminMutation() {
  return Urql.useMutation<Types.CreateBusinessAdminMutation, Types.CreateBusinessAdminMutationVariables>(CreateBusinessAdminDocument);
};
export const DeleteBusinessAdminDocument = gql`
    mutation DeleteBusinessAdmin($input: DeleteBusinessAdminInput!) {
  deleteBusinessAdmin(input: $input) {
    businessId
  }
}
    `;

export function useDeleteBusinessAdminMutation() {
  return Urql.useMutation<Types.DeleteBusinessAdminMutation, Types.DeleteBusinessAdminMutationVariables>(DeleteBusinessAdminDocument);
};
export const GetBusinessAdminsDocument = gql`
    query GetBusinessAdmins($businessId: ID!) {
  businessAdmins(businessId: $businessId) {
    admin {
      id
      name
      user {
        id
        name
        avatar
      }
    }
  }
}
    `;

export function useGetBusinessAdminsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessAdminsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessAdminsQuery, Types.GetBusinessAdminsQueryVariables>({ query: GetBusinessAdminsDocument, ...options });
};
export const UpdateBillingInfoDocument = gql`
    mutation UpdateBillingInfo($input: SetBillingInfoInput!) {
  setBillingInfo(input: $input) {
    billingInfo {
      name
      taxId
      country
      city
      district
      addressLine
      addressLine2
      postalCode
      phone
      email
    }
  }
}
    `;

export function useUpdateBillingInfoMutation() {
  return Urql.useMutation<Types.UpdateBillingInfoMutation, Types.UpdateBillingInfoMutationVariables>(UpdateBillingInfoDocument);
};
export const GetBusinessBillingInfoDocument = gql`
    query GetBusinessBillingInfo($businessId: ID!) {
  businessBillingInfo(businessId: $businessId) {
    name
    taxId
    country
    city
    district
    addressLine
    addressLine2
    postalCode
    phone
    email
  }
}
    `;

export function useGetBusinessBillingInfoQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessBillingInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessBillingInfoQuery, Types.GetBusinessBillingInfoQueryVariables>({ query: GetBusinessBillingInfoDocument, ...options });
};
export const GetBusinessDailyReportSummariesDocument = gql`
    query GetBusinessDailyReportSummaries($businessId: ID!) {
  businessDailyReportSummaries(businessId: $businessId) {
    date
    revenue
    margin
  }
}
    `;

export function useGetBusinessDailyReportSummariesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessDailyReportSummariesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessDailyReportSummariesQuery, Types.GetBusinessDailyReportSummariesQueryVariables>({ query: GetBusinessDailyReportSummariesDocument, ...options });
};
export const CreateEmployeeAdvancedAnnualLeaveMinuteDocument = gql`
    mutation CreateEmployeeAdvancedAnnualLeaveMinute($input: CreateEmployeeAdvancedAnnualLeaveMinuteInput!) {
  createEmployeeAdvancedAnnualLeaveMinute(input: $input) {
    employeeAdvancedAnnualLeaveMinute {
      id
      minutes
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useCreateEmployeeAdvancedAnnualLeaveMinuteMutation() {
  return Urql.useMutation<Types.CreateEmployeeAdvancedAnnualLeaveMinuteMutation, Types.CreateEmployeeAdvancedAnnualLeaveMinuteMutationVariables>(CreateEmployeeAdvancedAnnualLeaveMinuteDocument);
};
export const DeleteEmployeeAdvancedAnnualLeaveMinuteDocument = gql`
    mutation DeleteEmployeeAdvancedAnnualLeaveMinute($input: DeleteEmployeeAdvancedAnnualLeaveMinuteInput!) {
  deleteEmployeeAdvancedAnnualLeaveMinute(input: $input) {
    employeeAdvancedAnnualLeaveMinuteId
  }
}
    `;

export function useDeleteEmployeeAdvancedAnnualLeaveMinuteMutation() {
  return Urql.useMutation<Types.DeleteEmployeeAdvancedAnnualLeaveMinuteMutation, Types.DeleteEmployeeAdvancedAnnualLeaveMinuteMutationVariables>(DeleteEmployeeAdvancedAnnualLeaveMinuteDocument);
};
export const UpdateEmployeeAdvancedAnnualLeaveMinuteDocument = gql`
    mutation UpdateEmployeeAdvancedAnnualLeaveMinute($input: UpdateEmployeeAdvancedAnnualLeaveMinuteInput!) {
  updateEmployeeAdvancedAnnualLeaveMinute(input: $input) {
    employeeAdvancedAnnualLeaveMinute {
      id
      minutes
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useUpdateEmployeeAdvancedAnnualLeaveMinuteMutation() {
  return Urql.useMutation<Types.UpdateEmployeeAdvancedAnnualLeaveMinuteMutation, Types.UpdateEmployeeAdvancedAnnualLeaveMinuteMutationVariables>(UpdateEmployeeAdvancedAnnualLeaveMinuteDocument);
};
export const GetBusinessEmployeeAdvancedAnnualLeaveMinutesDocument = gql`
    query GetBusinessEmployeeAdvancedAnnualLeaveMinutes($employeeId: ID!) {
  businessEmployeeAdvancedAnnualLeaveMinutes(employeeId: $employeeId) {
    id
    minutes
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useGetBusinessEmployeeAdvancedAnnualLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeAdvancedAnnualLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeAdvancedAnnualLeaveMinutesQuery, Types.GetBusinessEmployeeAdvancedAnnualLeaveMinutesQueryVariables>({ query: GetBusinessEmployeeAdvancedAnnualLeaveMinutesDocument, ...options });
};
export const CreateEmployeeAnnualLeaveFormulaDocument = gql`
    mutation CreateEmployeeAnnualLeaveFormula($input: CreateEmployeeAnnualLeaveFormulaInput!) {
  createEmployeeAnnualLeaveFormula(input: $input) {
    employeeAnnualLeaveFormula {
      id
      formula
      note
      insertedAt
      admin {
        id
        name
      }
      employee {
        id
      }
    }
  }
}
    `;

export function useCreateEmployeeAnnualLeaveFormulaMutation() {
  return Urql.useMutation<Types.CreateEmployeeAnnualLeaveFormulaMutation, Types.CreateEmployeeAnnualLeaveFormulaMutationVariables>(CreateEmployeeAnnualLeaveFormulaDocument);
};
export const DeleteEmployeeAnnualLeaveFormulaDocument = gql`
    mutation DeleteEmployeeAnnualLeaveFormula($input: DeleteEmployeeAnnualLeaveFormulaInput!) {
  deleteEmployeeAnnualLeaveFormula(input: $input) {
    employeeAnnualLeaveFormulaId
  }
}
    `;

export function useDeleteEmployeeAnnualLeaveFormulaMutation() {
  return Urql.useMutation<Types.DeleteEmployeeAnnualLeaveFormulaMutation, Types.DeleteEmployeeAnnualLeaveFormulaMutationVariables>(DeleteEmployeeAnnualLeaveFormulaDocument);
};
export const UpdateEmployeeAnnualLeaveFormulaDocument = gql`
    mutation UpdateEmployeeAnnualLeaveFormula($input: UpdateEmployeeAnnualLeaveFormulaInput!) {
  updateEmployeeAnnualLeaveFormula(input: $input) {
    employeeAnnualLeaveFormula {
      id
      formula
      note
      insertedAt
      admin {
        id
        name
      }
      employee {
        id
      }
    }
  }
}
    `;

export function useUpdateEmployeeAnnualLeaveFormulaMutation() {
  return Urql.useMutation<Types.UpdateEmployeeAnnualLeaveFormulaMutation, Types.UpdateEmployeeAnnualLeaveFormulaMutationVariables>(UpdateEmployeeAnnualLeaveFormulaDocument);
};
export const GetBusinessEmployeeAnnualLeaveFormulasDocument = gql`
    query GetBusinessEmployeeAnnualLeaveFormulas($employeeId: ID!) {
  businessEmployeeAnnualLeaveFormulas(employeeId: $employeeId) {
    id
    formula
    note
    insertedAt
    admin {
      id
      name
    }
    employee {
      id
      name
      user {
        id
        name
        avatar
      }
    }
  }
}
    `;

export function useGetBusinessEmployeeAnnualLeaveFormulasQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeAnnualLeaveFormulasQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeAnnualLeaveFormulasQuery, Types.GetBusinessEmployeeAnnualLeaveFormulasQueryVariables>({ query: GetBusinessEmployeeAnnualLeaveFormulasDocument, ...options });
};
export const GetEmployeeAnnualLeaveMinuteBalanceDocument = gql`
    query GetEmployeeAnnualLeaveMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeAnnualLeaveMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    annualLeaveMinuteBalance
  }
}
    `;

export function useGetEmployeeAnnualLeaveMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetEmployeeAnnualLeaveMinuteBalanceQuery, Types.GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>({ query: GetEmployeeAnnualLeaveMinuteBalanceDocument, ...options });
};
export const GetBusinessEmployeeApprovedLeaveRequestsDocument = gql`
    query GetBusinessEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useGetBusinessEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeApprovedLeaveRequestsQuery, Types.GetBusinessEmployeeApprovedLeaveRequestsQueryVariables>({ query: GetBusinessEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const UpdateAttendanceCompRestMinuteDocument = gql`
    mutation UpdateAttendanceCompRestMinute($input: UpdateAttendanceCompRestMinuteInput!) {
  updateAttendanceCompRestMinute(input: $input) {
    attendanceCompRestMinute {
      minutes
      status
      expireAt
      employee {
        id
      }
      attendance {
        id
      }
    }
  }
}
    `;

export function useUpdateAttendanceCompRestMinuteMutation() {
  return Urql.useMutation<Types.UpdateAttendanceCompRestMinuteMutation, Types.UpdateAttendanceCompRestMinuteMutationVariables>(UpdateAttendanceCompRestMinuteDocument);
};
export const GetEmployeeAttendanceCompRestMinutesDocument = gql`
    query GetEmployeeAttendanceCompRestMinutes($employeeId: ID!, $startDate: String, $endDate: String) {
  businessEmployeeAttendanceCompRestMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    minutes
    status
    expireAt
    isPaid
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
    usages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
  }
}
    `;

export function useGetEmployeeAttendanceCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.GetEmployeeAttendanceCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetEmployeeAttendanceCompRestMinutesQuery, Types.GetEmployeeAttendanceCompRestMinutesQueryVariables>({ query: GetEmployeeAttendanceCompRestMinutesDocument, ...options });
};
export const GetEmployeeCarriedAnnualLeaveMinutesDocument = gql`
    query GetEmployeeCarriedAnnualLeaveMinutes($employeeId: ID!, $date: String) {
  businessEmployeeCarriedAnnualLeaveMinutes(employeeId: $employeeId, date: $date) {
    id
    carriedAnnualLeaveMinutes
  }
}
    `;

export function useGetEmployeeCarriedAnnualLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.GetEmployeeCarriedAnnualLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetEmployeeCarriedAnnualLeaveMinutesQuery, Types.GetEmployeeCarriedAnnualLeaveMinutesQueryVariables>({ query: GetEmployeeCarriedAnnualLeaveMinutesDocument, ...options });
};
export const GetEmployeeCompRestMinuteBalanceDocument = gql`
    query GetEmployeeCompRestMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeCompRestMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    compRestMinuteBalance
  }
}
    `;

export function useGetEmployeeCompRestMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.GetEmployeeCompRestMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetEmployeeCompRestMinuteBalanceQuery, Types.GetEmployeeCompRestMinuteBalanceQueryVariables>({ query: GetEmployeeCompRestMinuteBalanceDocument, ...options });
};
export const GetEmployeeExpiredAnnualLeaveMinutesDocument = gql`
    query GetEmployeeExpiredAnnualLeaveMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeExpiredAnnualLeaveMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    expiredAnnualLeaveMinutes
  }
}
    `;

export function useGetEmployeeExpiredAnnualLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.GetEmployeeExpiredAnnualLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetEmployeeExpiredAnnualLeaveMinutesQuery, Types.GetEmployeeExpiredAnnualLeaveMinutesQueryVariables>({ query: GetEmployeeExpiredAnnualLeaveMinutesDocument, ...options });
};
export const CreateEmployeeHazardInsuranceDocument = gql`
    mutation CreateEmployeeHazardInsurance($input: CreateEmployeeHazardInsuranceInput!) {
  createEmployeeHazardInsurance(input: $input) {
    employeeHazardInsurance {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useCreateEmployeeHazardInsuranceMutation() {
  return Urql.useMutation<Types.CreateEmployeeHazardInsuranceMutation, Types.CreateEmployeeHazardInsuranceMutationVariables>(CreateEmployeeHazardInsuranceDocument);
};
export const DeleteEmployeeHazardInsuranceDocument = gql`
    mutation DeleteEmployeeHazardInsurance($input: DeleteEmployeeHazardInsuranceInput!) {
  deleteEmployeeHazardInsurance(input: $input) {
    employeeHazardInsuranceId
  }
}
    `;

export function useDeleteEmployeeHazardInsuranceMutation() {
  return Urql.useMutation<Types.DeleteEmployeeHazardInsuranceMutation, Types.DeleteEmployeeHazardInsuranceMutationVariables>(DeleteEmployeeHazardInsuranceDocument);
};
export const UpdateEmployeeHazardInsuranceDocument = gql`
    mutation UpdateEmployeeHazardInsurance($input: UpdateEmployeeHazardInsuranceInput!) {
  updateEmployeeHazardInsurance(input: $input) {
    employeeHazardInsurance {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useUpdateEmployeeHazardInsuranceMutation() {
  return Urql.useMutation<Types.UpdateEmployeeHazardInsuranceMutation, Types.UpdateEmployeeHazardInsuranceMutationVariables>(UpdateEmployeeHazardInsuranceDocument);
};
export const GetBusinessEmployeeHazardInsurancesDocument = gql`
    query GetBusinessEmployeeHazardInsurances($employeeId: ID!) {
  businessEmployeeHazardInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useGetBusinessEmployeeHazardInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeHazardInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeHazardInsurancesQuery, Types.GetBusinessEmployeeHazardInsurancesQueryVariables>({ query: GetBusinessEmployeeHazardInsurancesDocument, ...options });
};
export const CreateEmployeeHealthInsuranceDocument = gql`
    mutation CreateEmployeeHealthInsurance($input: CreateEmployeeHealthInsuranceInput!) {
  createEmployeeHealthInsurance(input: $input) {
    employeeHealthInsurance {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useCreateEmployeeHealthInsuranceMutation() {
  return Urql.useMutation<Types.CreateEmployeeHealthInsuranceMutation, Types.CreateEmployeeHealthInsuranceMutationVariables>(CreateEmployeeHealthInsuranceDocument);
};
export const DeleteEmployeeHealthInsuranceDocument = gql`
    mutation DeleteEmployeeHealthInsurance($input: DeleteEmployeeHealthInsuranceInput!) {
  deleteEmployeeHealthInsurance(input: $input) {
    employeeHealthInsuranceId
  }
}
    `;

export function useDeleteEmployeeHealthInsuranceMutation() {
  return Urql.useMutation<Types.DeleteEmployeeHealthInsuranceMutation, Types.DeleteEmployeeHealthInsuranceMutationVariables>(DeleteEmployeeHealthInsuranceDocument);
};
export const UpdateEmployeeHealthInsuranceDocument = gql`
    mutation UpdateEmployeeHealthInsurance($input: UpdateEmployeeHealthInsuranceInput!) {
  updateEmployeeHealthInsurance(input: $input) {
    employeeHealthInsurance {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useUpdateEmployeeHealthInsuranceMutation() {
  return Urql.useMutation<Types.UpdateEmployeeHealthInsuranceMutation, Types.UpdateEmployeeHealthInsuranceMutationVariables>(UpdateEmployeeHealthInsuranceDocument);
};
export const GetBusinessEmployeeHealthInsurancesDocument = gql`
    query GetBusinessEmployeeHealthInsurances($employeeId: ID!) {
  businessEmployeeHealthInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useGetBusinessEmployeeHealthInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeHealthInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeHealthInsurancesQuery, Types.GetBusinessEmployeeHealthInsurancesQueryVariables>({ query: GetBusinessEmployeeHealthInsurancesDocument, ...options });
};
export const CreateEmployeeLaborInsuranceDocument = gql`
    mutation CreateEmployeeLaborInsurance($input: CreateEmployeeLaborInsuranceInput!) {
  createEmployeeLaborInsurance(input: $input) {
    employeeLaborInsurance {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useCreateEmployeeLaborInsuranceMutation() {
  return Urql.useMutation<Types.CreateEmployeeLaborInsuranceMutation, Types.CreateEmployeeLaborInsuranceMutationVariables>(CreateEmployeeLaborInsuranceDocument);
};
export const DeleteEmployeeLaborInsuranceDocument = gql`
    mutation DeleteEmployeeLaborInsurance($input: DeleteEmployeeLaborInsuranceInput!) {
  deleteEmployeeLaborInsurance(input: $input) {
    employeeLaborInsuranceId
  }
}
    `;

export function useDeleteEmployeeLaborInsuranceMutation() {
  return Urql.useMutation<Types.DeleteEmployeeLaborInsuranceMutation, Types.DeleteEmployeeLaborInsuranceMutationVariables>(DeleteEmployeeLaborInsuranceDocument);
};
export const UpdateEmployeeLaborInsuranceDocument = gql`
    mutation UpdateEmployeeLaborInsurance($input: UpdateEmployeeLaborInsuranceInput!) {
  updateEmployeeLaborInsurance(input: $input) {
    employeeLaborInsurance {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useUpdateEmployeeLaborInsuranceMutation() {
  return Urql.useMutation<Types.UpdateEmployeeLaborInsuranceMutation, Types.UpdateEmployeeLaborInsuranceMutationVariables>(UpdateEmployeeLaborInsuranceDocument);
};
export const GetBusinessEmployeeLaborInsurancesDocument = gql`
    query GetBusinessEmployeeLaborInsurances($employeeId: ID!) {
  businessEmployeeLaborInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    updatedAt
    admin {
      id
      name
    }
  }
}
    `;

export function useGetBusinessEmployeeLaborInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeLaborInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeLaborInsurancesQuery, Types.GetBusinessEmployeeLaborInsurancesQueryVariables>({ query: GetBusinessEmployeeLaborInsurancesDocument, ...options });
};
export const CreateEmployeeLaborPensionDocument = gql`
    mutation CreateEmployeeLaborPension($input: CreateEmployeeLaborPensionInput!) {
  createEmployeeLaborPension(input: $input) {
    employeeLaborPension {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useCreateEmployeeLaborPensionMutation() {
  return Urql.useMutation<Types.CreateEmployeeLaborPensionMutation, Types.CreateEmployeeLaborPensionMutationVariables>(CreateEmployeeLaborPensionDocument);
};
export const DeleteEmployeeLaborPensionDocument = gql`
    mutation DeleteEmployeeLaborPension($input: DeleteEmployeeLaborPensionInput!) {
  deleteEmployeeLaborPension(input: $input) {
    employeeLaborPensionId
  }
}
    `;

export function useDeleteEmployeeLaborPensionMutation() {
  return Urql.useMutation<Types.DeleteEmployeeLaborPensionMutation, Types.DeleteEmployeeLaborPensionMutationVariables>(DeleteEmployeeLaborPensionDocument);
};
export const UpdateEmployeeLaborPensionDocument = gql`
    mutation UpdateEmployeeLaborPension($input: UpdateEmployeeLaborPensionInput!) {
  updateEmployeeLaborPension(input: $input) {
    employeeLaborPension {
      id
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useUpdateEmployeeLaborPensionMutation() {
  return Urql.useMutation<Types.UpdateEmployeeLaborPensionMutation, Types.UpdateEmployeeLaborPensionMutationVariables>(UpdateEmployeeLaborPensionDocument);
};
export const GetBusinessEmployeeLaborPensionsDocument = gql`
    query GetBusinessEmployeeLaborPensions($employeeId: ID!) {
  businessEmployeeLaborPensions(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useGetBusinessEmployeeLaborPensionsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeLaborPensionsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeLaborPensionsQuery, Types.GetBusinessEmployeeLaborPensionsQueryVariables>({ query: GetBusinessEmployeeLaborPensionsDocument, ...options });
};
export const CreateEmployeePayRateDocument = gql`
    mutation CreateEmployeePayRate($input: CreateEmployeePayRateInput!) {
  createEmployeePayRate(input: $input) {
    employeePayRate {
      id
      type
      rate
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useCreateEmployeePayRateMutation() {
  return Urql.useMutation<Types.CreateEmployeePayRateMutation, Types.CreateEmployeePayRateMutationVariables>(CreateEmployeePayRateDocument);
};
export const DeleteEmployeePayRateDocument = gql`
    mutation DeleteEmployeePayRate($input: DeleteEmployeePayRateInput!) {
  deleteEmployeePayRate(input: $input) {
    employeePayRateId
  }
}
    `;

export function useDeleteEmployeePayRateMutation() {
  return Urql.useMutation<Types.DeleteEmployeePayRateMutation, Types.DeleteEmployeePayRateMutationVariables>(DeleteEmployeePayRateDocument);
};
export const UpdateEmployeePayRateDocument = gql`
    mutation UpdateEmployeePayRate($input: UpdateEmployeePayRateInput!) {
  updateEmployeePayRate(input: $input) {
    employeePayRate {
      id
      type
      rate
      startDate
      metaJson
      note
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useUpdateEmployeePayRateMutation() {
  return Urql.useMutation<Types.UpdateEmployeePayRateMutation, Types.UpdateEmployeePayRateMutationVariables>(UpdateEmployeePayRateDocument);
};
export const GetBusinessEmployeePayRatesDocument = gql`
    query GetBusinessEmployeePayRates($employeeId: ID!) {
  businessEmployeePayRates(employeeId: $employeeId) {
    id
    type
    rate
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useGetBusinessEmployeePayRatesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeePayRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeePayRatesQuery, Types.GetBusinessEmployeePayRatesQueryVariables>({ query: GetBusinessEmployeePayRatesDocument, ...options });
};
export const CreateEmployeePrimitiveCompRestMinuteDocument = gql`
    mutation CreateEmployeePrimitiveCompRestMinute($input: CreateEmployeePrimitiveCompRestMinuteInput!) {
  createEmployeePrimitiveCompRestMinute(input: $input) {
    employeePrimitiveCompRestMinute {
      id
      minutes
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useCreateEmployeePrimitiveCompRestMinuteMutation() {
  return Urql.useMutation<Types.CreateEmployeePrimitiveCompRestMinuteMutation, Types.CreateEmployeePrimitiveCompRestMinuteMutationVariables>(CreateEmployeePrimitiveCompRestMinuteDocument);
};
export const DeleteEmployeePrimitiveCompRestMinuteDocument = gql`
    mutation DeleteEmployeePrimitiveCompRestMinute($input: DeleteEmployeePrimitiveCompRestMinuteInput!) {
  deleteEmployeePrimitiveCompRestMinute(input: $input) {
    employeePrimitiveCompRestMinuteId
  }
}
    `;

export function useDeleteEmployeePrimitiveCompRestMinuteMutation() {
  return Urql.useMutation<Types.DeleteEmployeePrimitiveCompRestMinuteMutation, Types.DeleteEmployeePrimitiveCompRestMinuteMutationVariables>(DeleteEmployeePrimitiveCompRestMinuteDocument);
};
export const UpdateEmployeePrimitiveCompRestMinuteDocument = gql`
    mutation UpdateEmployeePrimitiveCompRestMinute($input: UpdateEmployeePrimitiveCompRestMinuteInput!) {
  updateEmployeePrimitiveCompRestMinute(input: $input) {
    employeePrimitiveCompRestMinute {
      id
      minutes
      admin {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

export function useUpdateEmployeePrimitiveCompRestMinuteMutation() {
  return Urql.useMutation<Types.UpdateEmployeePrimitiveCompRestMinuteMutation, Types.UpdateEmployeePrimitiveCompRestMinuteMutationVariables>(UpdateEmployeePrimitiveCompRestMinuteDocument);
};
export const GetBusinessEmployeePrimitiveCompRestMinutesDocument = gql`
    query GetBusinessEmployeePrimitiveCompRestMinutes($employeeId: ID!) {
  businessEmployeePrimitiveCompRestMinutes(employeeId: $employeeId) {
    id
    minutes
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useGetBusinessEmployeePrimitiveCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeePrimitiveCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeePrimitiveCompRestMinutesQuery, Types.GetBusinessEmployeePrimitiveCompRestMinutesQueryVariables>({ query: GetBusinessEmployeePrimitiveCompRestMinutesDocument, ...options });
};
export const CreateEmployeeProfileDocument = gql`
    mutation CreateEmployeeProfile($input: CreateEmployeeProfileInput!) {
  createEmployeeProfile(input: $input) {
    employeeProfile {
      id
      profileJson
    }
  }
}
    `;

export function useCreateEmployeeProfileMutation() {
  return Urql.useMutation<Types.CreateEmployeeProfileMutation, Types.CreateEmployeeProfileMutationVariables>(CreateEmployeeProfileDocument);
};
export const GetBusinessEmployeeProfilesDocument = gql`
    query GetBusinessEmployeeProfiles($employeeId: ID!) {
  businessEmployeeProfiles(employeeId: $employeeId) {
    id
    profileJson
  }
}
    `;

export function useGetBusinessEmployeeProfilesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeeProfilesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeeProfilesQuery, Types.GetBusinessEmployeeProfilesQueryVariables>({ query: GetBusinessEmployeeProfilesDocument, ...options });
};
export const ChangeEmployeeWorkplaceDocument = gql`
    mutation ChangeEmployeeWorkplace($input: ChangeEmployeeWorkplaceInput!) {
  changeEmployeeWorkplace(input: $input) {
    employee {
      id
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      suspended
      user {
        id
        name
        avatar
        locale
      }
      positions {
        id
        name
        color
      }
      workplace {
        id
        name
      }
    }
  }
}
    `;

export function useChangeEmployeeWorkplaceMutation() {
  return Urql.useMutation<Types.ChangeEmployeeWorkplaceMutation, Types.ChangeEmployeeWorkplaceMutationVariables>(ChangeEmployeeWorkplaceDocument);
};
export const GetBusinessEmployeesDocument = gql`
    query GetBusinessEmployees($businessId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployees(businessId: $businessId) {
    id
    active
    index
    ssoSvUserId
    employeeNumber
    type
    name
    tags
    partTime
    mobilePhone
    joiningDate
    advancedAnnualLeaveMinutes
    carriedAnnualLeaveMinutesYears
    annualLeaveMinutes
    primitiveCompRestMinuteUsages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
    tenure
    insertedAt
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    workplace {
      id
      name
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useGetBusinessEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessEmployeesQuery, Types.GetBusinessEmployeesQueryVariables>({ query: GetBusinessEmployeesDocument, ...options });
};
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($input: CreateInvoiceInput!) {
  createInvoice(input: $input) {
    invoice {
      id
    }
  }
}
    `;

export function useCreateInvoiceMutation() {
  return Urql.useMutation<Types.CreateInvoiceMutation, Types.CreateInvoiceMutationVariables>(CreateInvoiceDocument);
};
export const GetBusinessInvoicesDocument = gql`
    query GetBusinessInvoices($businessId: ID!) {
  businessInvoices(businessId: $businessId) {
    id
    issueDate
    dueDate
    detailsJson
    status
  }
}
    `;

export function useGetBusinessInvoicesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessInvoicesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessInvoicesQuery, Types.GetBusinessInvoicesQueryVariables>({ query: GetBusinessInvoicesDocument, ...options });
};
export const GetBusinessSubscriptionPlansDocument = gql`
    query GetBusinessSubscriptionPlans($businessId: ID!) {
  businessSubscriptionPlans(businessId: $businessId) {
    id
    startDate
    endDate
    seats
    invoice {
      id
      issueDate
      dueDate
      detailsJson
      status
      receipts {
        id
        receiptNumber
        receiptUrl
      }
    }
  }
}
    `;

export function useGetBusinessSubscriptionPlansQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessSubscriptionPlansQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessSubscriptionPlansQuery, Types.GetBusinessSubscriptionPlansQueryVariables>({ query: GetBusinessSubscriptionPlansDocument, ...options });
};
export const CreateWorkplaceDocument = gql`
    mutation CreateWorkplace($input: CreateWorkplaceInput!) {
  createWorkplace(input: $input) {
    workplace {
      id
      name
      privileges
      employeeCount
      gmapPlace {
        gmapPlaceId
        address
      }
      geofence {
        lat
        lng
        distance
      }
    }
  }
}
    `;

export function useCreateWorkplaceMutation() {
  return Urql.useMutation<Types.CreateWorkplaceMutation, Types.CreateWorkplaceMutationVariables>(CreateWorkplaceDocument);
};
export const DeleteWorkplaceDocument = gql`
    mutation DeleteWorkplace($input: DeleteWorkplaceInput!) {
  deleteWorkplace(input: $input) {
    workplaceId
  }
}
    `;

export function useDeleteWorkplaceMutation() {
  return Urql.useMutation<Types.DeleteWorkplaceMutation, Types.DeleteWorkplaceMutationVariables>(DeleteWorkplaceDocument);
};
export const SetSchedulerPreferenceDocument = gql`
    mutation SetSchedulerPreference($input: SetSchedulerPreferenceInput!) {
  setSchedulerPreference(input: $input) {
    schedulerPreference {
      scheduleDays
      startWeekday
    }
  }
}
    `;

export function useSetSchedulerPreferenceMutation() {
  return Urql.useMutation<Types.SetSchedulerPreferenceMutation, Types.SetSchedulerPreferenceMutationVariables>(SetSchedulerPreferenceDocument);
};
export const SessionBusinessWorkplaces_UpdateWorkplaceDocument = gql`
    mutation SessionBusinessWorkplaces_UpdateWorkplace($input: UpdateWorkplaceInput!) {
  updateWorkplace(input: $input) {
    workplace {
      id
      name
      privileges
      attendanceMode
      attendanceRestTimestampsEnabled
      employeeCount
      gmapPlace {
        gmapPlaceId
        address
      }
      geofence {
        lat
        lng
        distance
      }
    }
  }
}
    `;

export function useSessionBusinessWorkplaces_UpdateWorkplaceMutation() {
  return Urql.useMutation<Types.SessionBusinessWorkplaces_UpdateWorkplaceMutation, Types.SessionBusinessWorkplaces_UpdateWorkplaceMutationVariables>(SessionBusinessWorkplaces_UpdateWorkplaceDocument);
};
export const GetBusinessWorkplacesDocument = gql`
    query GetBusinessWorkplaces($businessId: ID!) {
  businessWorkplaces(businessId: $businessId) {
    id
    index
    active
    ssoSvStoreId
    ssoSvAccountUsersJson
    name
    privileges
    activeEmployeeCount
    employeeCount
    attendanceMode
    attendanceRestTimestampsEnabled
    gmapPlace {
      gmapPlaceId
      address
    }
    geofence {
      lat
      lng
      distance
    }
    currentLaborRule {
      id
      startDate
      ruleType
      ruleJson
    }
    currentTargetRevenueSetting {
      id
      startDate
      targetRevenueSettingJson
    }
    privilegedWorkingAreas {
      id
      workplaceId
      archived
      deleted
      updatedAt
      name
      positions {
        id
        archived
        deleted
        updatedAt
        name
        color
        workingAreaId
      }
    }
    schedulerPreference {
      scheduleDays
      startWeekday
    }
  }
}
    `;

export function useGetBusinessWorkplacesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplacesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplacesQuery, Types.GetBusinessWorkplacesQueryVariables>({ query: GetBusinessWorkplacesDocument, ...options });
};
export const UpdateBusinessDocument = gql`
    mutation UpdateBusiness($input: UpdateBusinessInput!) {
  updateBusiness(input: $input) {
    business {
      id
      name
      type
      enableEmployeeNumber
    }
  }
}
    `;

export function useUpdateBusinessMutation() {
  return Urql.useMutation<Types.UpdateBusinessMutation, Types.UpdateBusinessMutationVariables>(UpdateBusinessDocument);
};
export const GetBusinessDocument = gql`
    query GetBusiness($businessId: ID!) {
  node(id: $businessId) {
    ... on Business {
      id
      name
      type
      enableEmployeeNumber
      trialEndDate
      subscriptionPlanEndDate
      subscriptionPlanSeats
      betaSchedulePageV1Enabled
      isOwner
      isAdmin
      myPrivilegedWorkplaces {
        id
        name
      }
      owner {
        id
        name
        email
        mobileCountryCode
        mobilePhoneNumber
      }
      activeEmployeeCount
      employeeCount
    }
  }
}
    `;

export function useGetBusinessQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessQuery, Types.GetBusinessQueryVariables>({ query: GetBusinessDocument, ...options });
};
export const CreateWorkplaceAdminDocument = gql`
    mutation CreateWorkplaceAdmin($input: CreateWorkplaceAdminInput!) {
  createWorkplaceAdmin(input: $input) {
    businessWorkplaceAdmin {
      workplaceId
    }
  }
}
    `;

export function useCreateWorkplaceAdminMutation() {
  return Urql.useMutation<Types.CreateWorkplaceAdminMutation, Types.CreateWorkplaceAdminMutationVariables>(CreateWorkplaceAdminDocument);
};
export const DeleteWorkplaceAdminDocument = gql`
    mutation DeleteWorkplaceAdmin($input: DeleteWorkplaceAdminInput!) {
  deleteWorkplaceAdmin(input: $input) {
    workplaceId
  }
}
    `;

export function useDeleteWorkplaceAdminMutation() {
  return Urql.useMutation<Types.DeleteWorkplaceAdminMutation, Types.DeleteWorkplaceAdminMutationVariables>(DeleteWorkplaceAdminDocument);
};
export const UpdateWorkplaceAdminDocument = gql`
    mutation UpdateWorkplaceAdmin($input: UpdateWorkplaceAdminInput!) {
  updateWorkplaceAdmin(input: $input) {
    businessWorkplaceAdmin {
      workplaceId
    }
  }
}
    `;

export function useUpdateWorkplaceAdminMutation() {
  return Urql.useMutation<Types.UpdateWorkplaceAdminMutation, Types.UpdateWorkplaceAdminMutationVariables>(UpdateWorkplaceAdminDocument);
};
export const GetBusinessWorkplaceAdminsDocument = gql`
    query GetBusinessWorkplaceAdmins($workplaceId: ID!) {
  businessWorkplaceAdmins(workplaceId: $workplaceId) {
    admin {
      id
      name
      user {
        id
        name
        avatar
      }
    }
    workingAreas {
      id
      name
      archived
    }
    privileges
  }
}
    `;

export function useGetBusinessWorkplaceAdminsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceAdminsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceAdminsQuery, Types.GetBusinessWorkplaceAdminsQueryVariables>({ query: GetBusinessWorkplaceAdminsDocument, ...options });
};
export const CreateWorkplaceAnnualLeaveFormulaDocument = gql`
    mutation CreateWorkplaceAnnualLeaveFormula($input: CreateWorkplaceAnnualLeaveFormulaInput!) {
  createWorkplaceAnnualLeaveFormula(input: $input) {
    workplaceAnnualLeaveFormula {
      id
      formula
      note
      insertedAt
      admin {
        id
        name
      }
    }
  }
}
    `;

export function useCreateWorkplaceAnnualLeaveFormulaMutation() {
  return Urql.useMutation<Types.CreateWorkplaceAnnualLeaveFormulaMutation, Types.CreateWorkplaceAnnualLeaveFormulaMutationVariables>(CreateWorkplaceAnnualLeaveFormulaDocument);
};
export const DeleteWorkplaceAnnualLeaveFormulaDocument = gql`
    mutation DeleteWorkplaceAnnualLeaveFormula($input: DeleteWorkplaceAnnualLeaveFormulaInput!) {
  deleteWorkplaceAnnualLeaveFormula(input: $input) {
    workplaceAnnualLeaveFormulaId
  }
}
    `;

export function useDeleteWorkplaceAnnualLeaveFormulaMutation() {
  return Urql.useMutation<Types.DeleteWorkplaceAnnualLeaveFormulaMutation, Types.DeleteWorkplaceAnnualLeaveFormulaMutationVariables>(DeleteWorkplaceAnnualLeaveFormulaDocument);
};
export const UpdateWorkplaceAnnualLeaveFormulaDocument = gql`
    mutation UpdateWorkplaceAnnualLeaveFormula($input: UpdateWorkplaceAnnualLeaveFormulaInput!) {
  updateWorkplaceAnnualLeaveFormula(input: $input) {
    workplaceAnnualLeaveFormula {
      id
      formula
      note
      insertedAt
      admin {
        id
        name
      }
    }
  }
}
    `;

export function useUpdateWorkplaceAnnualLeaveFormulaMutation() {
  return Urql.useMutation<Types.UpdateWorkplaceAnnualLeaveFormulaMutation, Types.UpdateWorkplaceAnnualLeaveFormulaMutationVariables>(UpdateWorkplaceAnnualLeaveFormulaDocument);
};
export const GetBusinessWorkplaceAnnualLeaveFormulasDocument = gql`
    query GetBusinessWorkplaceAnnualLeaveFormulas($workplaceId: ID!) {
  businessWorkplaceAnnualLeaveFormulas(workplaceId: $workplaceId) {
    id
    formula
    note
    insertedAt
    admin {
      id
      name
    }
  }
}
    `;

export function useGetBusinessWorkplaceAnnualLeaveFormulasQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceAnnualLeaveFormulasQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceAnnualLeaveFormulasQuery, Types.GetBusinessWorkplaceAnnualLeaveFormulasQueryVariables>({ query: GetBusinessWorkplaceAnnualLeaveFormulasDocument, ...options });
};
export const CreateAttendanceCompRestMinuteDocument = gql`
    mutation CreateAttendanceCompRestMinute($input: CreateAttendanceCompRestMinuteInput!) {
  createAttendanceCompRestMinute(input: $input) {
    attendanceCompRestMinute {
      minutes
      status
      expireAt
      employee {
        id
      }
      attendance {
        id
      }
    }
  }
}
    `;

export function useCreateAttendanceCompRestMinuteMutation() {
  return Urql.useMutation<Types.CreateAttendanceCompRestMinuteMutation, Types.CreateAttendanceCompRestMinuteMutationVariables>(CreateAttendanceCompRestMinuteDocument);
};
export const DeleteAttendanceCompRestMinuteDocument = gql`
    mutation DeleteAttendanceCompRestMinute($input: DeleteAttendanceCompRestMinuteInput!) {
  deleteAttendanceCompRestMinute(input: $input) {
    attendanceId
  }
}
    `;

export function useDeleteAttendanceCompRestMinuteMutation() {
  return Urql.useMutation<Types.DeleteAttendanceCompRestMinuteMutation, Types.DeleteAttendanceCompRestMinuteMutationVariables>(DeleteAttendanceCompRestMinuteDocument);
};
export const GetBusinessWorkplaceAttendanceCompRestMinutesDocument = gql`
    query GetBusinessWorkplaceAttendanceCompRestMinutes($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceAttendanceCompRestMinutes(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    minutes
    status
    isPaid
    expireAt
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
  }
}
    `;

export function useGetBusinessWorkplaceAttendanceCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceAttendanceCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceAttendanceCompRestMinutesQuery, Types.GetBusinessWorkplaceAttendanceCompRestMinutesQueryVariables>({ query: GetBusinessWorkplaceAttendanceCompRestMinutesDocument, ...options });
};
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    employee {
      id
      active
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      user {
        id
        name
        avatar
        locale
      }
    }
  }
}
    `;

export function useCreateEmployeeMutation() {
  return Urql.useMutation<Types.CreateEmployeeMutation, Types.CreateEmployeeMutationVariables>(CreateEmployeeDocument);
};
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($input: DeleteEmployeeInput!) {
  deleteEmployee(input: $input) {
    employeeId
  }
}
    `;

export function useDeleteEmployeeMutation() {
  return Urql.useMutation<Types.DeleteEmployeeMutation, Types.DeleteEmployeeMutationVariables>(DeleteEmployeeDocument);
};
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    employee {
      id
      active
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      suspended
      user {
        id
        name
        avatar
        locale
      }
    }
  }
}
    `;

export function useUpdateEmployeeMutation() {
  return Urql.useMutation<Types.UpdateEmployeeMutation, Types.UpdateEmployeeMutationVariables>(UpdateEmployeeDocument);
};
export const GetSessionWorkplaceEmployeesDocument = gql`
    query GetSessionWorkplaceEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceEmployees(workplaceId: $workplaceId, active: false) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    mobilePhone
    joiningDate
    insertedAt
    advancedAnnualLeaveMinutes
    carriedAnnualLeaveMinutesYears
    annualLeaveMinutes
    primitiveCompRestMinuteUsages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
    tenure
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    payRates {
      id
      type
      rate
      startDate
      metaJson
    }
    laborInsurances {
      id
      startDate
      metaJson
    }
    healthInsurances {
      id
      startDate
      metaJson
    }
    laborPensions {
      id
      startDate
      metaJson
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useGetSessionWorkplaceEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.GetSessionWorkplaceEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetSessionWorkplaceEmployeesQuery, Types.GetSessionWorkplaceEmployeesQueryVariables>({ query: GetSessionWorkplaceEmployeesDocument, ...options });
};
export const GetBusinessWorkplaceLaborCostMarginAlertSettingsDocument = gql`
    query GetBusinessWorkplaceLaborCostMarginAlertSettings($workplaceId: ID!) {
  businessWorkplaceLaborCostMarginAlertSettings(workplaceId: $workplaceId) {
    id
    startDate
    laborCostMarginAlertSettingJson
  }
}
    `;

export function useGetBusinessWorkplaceLaborCostMarginAlertSettingsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceLaborCostMarginAlertSettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceLaborCostMarginAlertSettingsQuery, Types.GetBusinessWorkplaceLaborCostMarginAlertSettingsQueryVariables>({ query: GetBusinessWorkplaceLaborCostMarginAlertSettingsDocument, ...options });
};
export const CreateLaborRuleDocument = gql`
    mutation CreateLaborRule($input: CreateLaborRuleInput!) {
  createLaborRule(input: $input) {
    laborRule {
      id
    }
  }
}
    `;

export function useCreateLaborRuleMutation() {
  return Urql.useMutation<Types.CreateLaborRuleMutation, Types.CreateLaborRuleMutationVariables>(CreateLaborRuleDocument);
};
export const DeleteLaborRuleDocument = gql`
    mutation DeleteLaborRule($input: DeleteLaborRuleInput!) {
  deleteLaborRule(input: $input) {
    laborRuleId
  }
}
    `;

export function useDeleteLaborRuleMutation() {
  return Urql.useMutation<Types.DeleteLaborRuleMutation, Types.DeleteLaborRuleMutationVariables>(DeleteLaborRuleDocument);
};
export const UpdateLaborRuleDocument = gql`
    mutation UpdateLaborRule($input: UpdateLaborRuleInput!) {
  updateLaborRule(input: $input) {
    laborRule {
      id
    }
  }
}
    `;

export function useUpdateLaborRuleMutation() {
  return Urql.useMutation<Types.UpdateLaborRuleMutation, Types.UpdateLaborRuleMutationVariables>(UpdateLaborRuleDocument);
};
export const GetBusinessWorkplaceLaborRulesDocument = gql`
    query GetBusinessWorkplaceLaborRules($workplaceId: ID!) {
  businessWorkplaceLaborRules(workplaceId: $workplaceId) {
    id
    startDate
    ruleType
    ruleJson
  }
}
    `;

export function useGetBusinessWorkplaceLaborRulesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceLaborRulesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceLaborRulesQuery, Types.GetBusinessWorkplaceLaborRulesQueryVariables>({ query: GetBusinessWorkplaceLaborRulesDocument, ...options });
};
export const CreateMasterShiftDocument = gql`
    mutation CreateMasterShift($input: CreateMasterShiftInput!) {
  createMasterShift(input: $input) {
    masterShift {
      id
      deleted
      updatedAt
      startTime
      endTime
      restStartTime
      restMins
      amount
      note
      weekday
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useCreateMasterShiftMutation() {
  return Urql.useMutation<Types.CreateMasterShiftMutation, Types.CreateMasterShiftMutationVariables>(CreateMasterShiftDocument);
};
export const DeleteMasterShiftDocument = gql`
    mutation DeleteMasterShift($input: DeleteMasterShiftInput!) {
  deleteMasterShift(input: $input) {
    masterShiftId
  }
}
    `;

export function useDeleteMasterShiftMutation() {
  return Urql.useMutation<Types.DeleteMasterShiftMutation, Types.DeleteMasterShiftMutationVariables>(DeleteMasterShiftDocument);
};
export const UpdateMasterShiftDocument = gql`
    mutation UpdateMasterShift($input: UpdateMasterShiftInput!) {
  updateMasterShift(input: $input) {
    masterShift {
      id
      deleted
      updatedAt
      startTime
      endTime
      restStartTime
      restMins
      amount
      note
      weekday
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useUpdateMasterShiftMutation() {
  return Urql.useMutation<Types.UpdateMasterShiftMutation, Types.UpdateMasterShiftMutationVariables>(UpdateMasterShiftDocument);
};
export const GetBusinessWorkplaceMasterShiftsDocument = gql`
    query GetBusinessWorkplaceMasterShifts($workplaceId: ID!) {
  businessWorkplaceMasterShifts(workplaceId: $workplaceId) {
    id
    deleted
    updatedAt
    startTime
    endTime
    restStartTime
    restMins
    amount
    note
    weekday
    position {
      id
      name
      color
    }
  }
}
    `;

export function useGetBusinessWorkplaceMasterShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceMasterShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceMasterShiftsQuery, Types.GetBusinessWorkplaceMasterShiftsQueryVariables>({ query: GetBusinessWorkplaceMasterShiftsDocument, ...options });
};
export const CreateOrUpdatePayrollDocument = gql`
    mutation CreateOrUpdatePayroll($input: CreateOrUpdatePayrollInput!) {
  createOrUpdatePayroll(input: $input) {
    payroll {
      employeeId
      payrollSettlementId
      employee {
        id
        name
        user {
          id
          name
          avatar
        }
      }
      payrollJson
      audited
      paid
    }
  }
}
    `;

export function useCreateOrUpdatePayrollMutation() {
  return Urql.useMutation<Types.CreateOrUpdatePayrollMutation, Types.CreateOrUpdatePayrollMutationVariables>(CreateOrUpdatePayrollDocument);
};
export const DeletePayrollDocument = gql`
    mutation DeletePayroll($input: DeletePayrollInput!) {
  deletePayroll(input: $input) {
    payroll {
      employee {
        id
      }
    }
  }
}
    `;

export function useDeletePayrollMutation() {
  return Urql.useMutation<Types.DeletePayrollMutation, Types.DeletePayrollMutationVariables>(DeletePayrollDocument);
};
export const GetBusinessWorkplacePayrollSettlementPayrollsDocument = gql`
    query GetBusinessWorkplacePayrollSettlementPayrolls($payrollSettlementId: ID!) {
  businessWorkplacePayrollSettlementPayrolls(
    payrollSettlementId: $payrollSettlementId
  ) {
    employeeId
    payrollSettlementId
    employee {
      id
      index
      name
      user {
        id
        name
        avatar
      }
    }
    payrollJson
    audited
    paid
  }
}
    `;

export function useGetBusinessWorkplacePayrollSettlementPayrollsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplacePayrollSettlementPayrollsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplacePayrollSettlementPayrollsQuery, Types.GetBusinessWorkplacePayrollSettlementPayrollsQueryVariables>({ query: GetBusinessWorkplacePayrollSettlementPayrollsDocument, ...options });
};
export const CreatePayrollSettlementDocument = gql`
    mutation CreatePayrollSettlement($input: CreatePayrollSettlementInput!) {
  createPayrollSettlement(input: $input) {
    payrollSettlement {
      id
      startDate
      endDate
      archived
    }
  }
}
    `;

export function useCreatePayrollSettlementMutation() {
  return Urql.useMutation<Types.CreatePayrollSettlementMutation, Types.CreatePayrollSettlementMutationVariables>(CreatePayrollSettlementDocument);
};
export const UpdatePayrollSettlementDocument = gql`
    mutation UpdatePayrollSettlement($input: UpdatePayrollSettlementInput!) {
  updatePayrollSettlement(input: $input) {
    payrollSettlement {
      id
      startDate
      endDate
      archived
    }
  }
}
    `;

export function useUpdatePayrollSettlementMutation() {
  return Urql.useMutation<Types.UpdatePayrollSettlementMutation, Types.UpdatePayrollSettlementMutationVariables>(UpdatePayrollSettlementDocument);
};
export const GetBusinessWorkplacePayrollSettlementsDocument = gql`
    query GetBusinessWorkplacePayrollSettlements($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplacePayrollSettlements(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    startDate
    endDate
    archived
  }
}
    `;

export function useGetBusinessWorkplacePayrollSettlementsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplacePayrollSettlementsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplacePayrollSettlementsQuery, Types.GetBusinessWorkplacePayrollSettlementsQueryVariables>({ query: GetBusinessWorkplacePayrollSettlementsDocument, ...options });
};
export const CreateOrUpdatePayrollTemplateDocument = gql`
    mutation CreateOrUpdatePayrollTemplate($input: CreateOrUpdatePayrollTemplateInput!) {
  createOrUpdatePayrollTemplate(input: $input) {
    payrollTemplate {
      workplaceId
      templateJson
    }
  }
}
    `;

export function useCreateOrUpdatePayrollTemplateMutation() {
  return Urql.useMutation<Types.CreateOrUpdatePayrollTemplateMutation, Types.CreateOrUpdatePayrollTemplateMutationVariables>(CreateOrUpdatePayrollTemplateDocument);
};
export const GetBusinessWorkplacePayrollTemplateDocument = gql`
    query GetBusinessWorkplacePayrollTemplate($workplaceId: ID!) {
  businessWorkplacePayrollTemplate(workplaceId: $workplaceId) {
    workplaceId
    templateJson
  }
}
    `;

export function useGetBusinessWorkplacePayrollTemplateQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplacePayrollTemplateQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplacePayrollTemplateQuery, Types.GetBusinessWorkplacePayrollTemplateQueryVariables>({ query: GetBusinessWorkplacePayrollTemplateDocument, ...options });
};
export const CreatePositionDocument = gql`
    mutation CreatePosition($input: CreatePositionInput!) {
  createPosition(input: $input) {
    position {
      id
      archived
      deleted
      updatedAt
      name
      color
    }
  }
}
    `;

export function useCreatePositionMutation() {
  return Urql.useMutation<Types.CreatePositionMutation, Types.CreatePositionMutationVariables>(CreatePositionDocument);
};
export const DeletePositionDocument = gql`
    mutation DeletePosition($input: DeletePositionInput!) {
  deletePosition(input: $input) {
    position {
      id
      archived
      deleted
      updatedAt
      name
      color
    }
  }
}
    `;

export function useDeletePositionMutation() {
  return Urql.useMutation<Types.DeletePositionMutation, Types.DeletePositionMutationVariables>(DeletePositionDocument);
};
export const UpdatePositionDocument = gql`
    mutation UpdatePosition($input: UpdatePositionInput!) {
  updatePosition(input: $input) {
    position {
      id
      archived
      deleted
      updatedAt
      name
      color
    }
  }
}
    `;

export function useUpdatePositionMutation() {
  return Urql.useMutation<Types.UpdatePositionMutation, Types.UpdatePositionMutationVariables>(UpdatePositionDocument);
};
export const GetBusinessWorkplacePositionsDocument = gql`
    query GetBusinessWorkplacePositions($workplaceId: ID!) {
  businessWorkplacePositions(workplaceId: $workplaceId, active: false) {
    id
    archived
    deleted
    updatedAt
    name
    color
    workingAreaId
    workingArea {
      id
      name
    }
  }
}
    `;

export function useGetBusinessWorkplacePositionsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplacePositionsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplacePositionsQuery, Types.GetBusinessWorkplacePositionsQueryVariables>({ query: GetBusinessWorkplacePositionsDocument, ...options });
};
export const GetBusinessWorkplacePrivilegedWorkingAreaIdsDocument = gql`
    query GetBusinessWorkplacePrivilegedWorkingAreaIds($workplaceId: ID!) {
  businessWorkplacePrivilegedWorkingAreaIds(workplaceId: $workplaceId)
}
    `;

export function useGetBusinessWorkplacePrivilegedWorkingAreaIdsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplacePrivilegedWorkingAreaIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplacePrivilegedWorkingAreaIdsQuery, Types.GetBusinessWorkplacePrivilegedWorkingAreaIdsQueryVariables>({ query: GetBusinessWorkplacePrivilegedWorkingAreaIdsDocument, ...options });
};
export const GetBusinessWorkplaceTargetRevenueSettingsDocument = gql`
    query GetBusinessWorkplaceTargetRevenueSettings($workplaceId: ID!) {
  businessWorkplaceTargetRevenueSettings(workplaceId: $workplaceId) {
    id
    startDate
    targetRevenueSettingJson
  }
}
    `;

export function useGetBusinessWorkplaceTargetRevenueSettingsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceTargetRevenueSettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceTargetRevenueSettingsQuery, Types.GetBusinessWorkplaceTargetRevenueSettingsQueryVariables>({ query: GetBusinessWorkplaceTargetRevenueSettingsDocument, ...options });
};
export const CreateWorkingAreaDocument = gql`
    mutation CreateWorkingArea($input: CreateWorkingAreaInput!) {
  createWorkingArea(input: $input) {
    workingArea {
      id
      archived
      deleted
      updatedAt
      name
    }
  }
}
    `;

export function useCreateWorkingAreaMutation() {
  return Urql.useMutation<Types.CreateWorkingAreaMutation, Types.CreateWorkingAreaMutationVariables>(CreateWorkingAreaDocument);
};
export const DeleteWorkingAreaDocument = gql`
    mutation DeleteWorkingArea($input: DeleteWorkingAreaInput!) {
  deleteWorkingArea(input: $input) {
    workingArea {
      id
      archived
      deleted
      updatedAt
      name
    }
  }
}
    `;

export function useDeleteWorkingAreaMutation() {
  return Urql.useMutation<Types.DeleteWorkingAreaMutation, Types.DeleteWorkingAreaMutationVariables>(DeleteWorkingAreaDocument);
};
export const UpdateWorkingAreaDocument = gql`
    mutation UpdateWorkingArea($input: UpdateWorkingAreaInput!) {
  updateWorkingArea(input: $input) {
    workingArea {
      id
      archived
      deleted
      updatedAt
      name
    }
  }
}
    `;

export function useUpdateWorkingAreaMutation() {
  return Urql.useMutation<Types.UpdateWorkingAreaMutation, Types.UpdateWorkingAreaMutationVariables>(UpdateWorkingAreaDocument);
};
export const GetBusinessWorkplaceWorkingAreasDocument = gql`
    query GetBusinessWorkplaceWorkingAreas($workplaceId: ID!) {
  businessWorkplaceWorkingAreas(workplaceId: $workplaceId, active: false) {
    id
    archived
    deleted
    updatedAt
    name
  }
}
    `;

export function useGetBusinessWorkplaceWorkingAreasQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceWorkingAreasQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceWorkingAreasQuery, Types.GetBusinessWorkplaceWorkingAreasQueryVariables>({ query: GetBusinessWorkplaceWorkingAreasDocument, ...options });
};
export const UpdateWorkplaceDocument = gql`
    mutation UpdateWorkplace($input: UpdateWorkplaceInput!) {
  updateWorkplace(input: $input) {
    workplace {
      id
      name
      privileges
      attendanceMode
      employeeCount
      gmapPlace {
        gmapPlaceId
        address
      }
      geofence {
        lat
        lng
        distance
      }
    }
  }
}
    `;

export function useUpdateWorkplaceMutation() {
  return Urql.useMutation<Types.UpdateWorkplaceMutation, Types.UpdateWorkplaceMutationVariables>(UpdateWorkplaceDocument);
};
export const GetWorkplaceDocument = gql`
    query GetWorkplace($workplaceId: ID!) {
  node(id: $workplaceId) {
    ... on BusinessWorkplace {
      id
      name
      privileges
      activeEmployeeCount
      employeeCount
      attendanceMode
      attendanceRestTimestampsEnabled
      gmapPlace {
        gmapPlaceId
        address
      }
      geofence {
        lat
        lng
        distance
      }
      currentLaborRule {
        id
        startDate
        ruleType
        ruleJson
      }
      currentTargetRevenueSetting {
        id
        startDate
        targetRevenueSettingJson
      }
      privilegedWorkingAreas {
        id
        archived
        deleted
        updatedAt
        name
        positions {
          id
          archived
          deleted
          updatedAt
          name
          color
          workingAreaId
        }
      }
      schedulerPreference {
        scheduleDays
        startWeekday
      }
      insertedAt
    }
  }
}
    `;

export function useGetWorkplaceQuery(options: Omit<Urql.UseQueryArgs<Types.GetWorkplaceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetWorkplaceQuery, Types.GetWorkplaceQueryVariables>({ query: GetWorkplaceDocument, ...options });
};
export const GetBusinessWorkplaceDayoffsDocument = gql`
    query GetBusinessWorkplaceDayoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDayoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useGetBusinessWorkplaceDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceDayoffsQuery, Types.GetBusinessWorkplaceDayoffsQueryVariables>({ query: GetBusinessWorkplaceDayoffsDocument, ...options });
};
export const GetBusinessWorkplaceEmployeesDocument = gql`
    query GetBusinessWorkplaceEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceEmployees(workplaceId: $workplaceId) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    mobilePhone
    joiningDate
    annualLeaveMinutes
    tenure
    insertedAt
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    payRates {
      id
      type
      rate
      startDate
      metaJson
    }
    laborInsurances {
      id
      startDate
      metaJson
    }
    healthInsurances {
      id
      startDate
      metaJson
    }
    laborPensions {
      id
      startDate
      metaJson
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useGetBusinessWorkplaceEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceEmployeesQuery, Types.GetBusinessWorkplaceEmployeesQueryVariables>({ query: GetBusinessWorkplaceEmployeesDocument, ...options });
};
export const GetBusinessWorkplaceLaborCostMarginAlertDocument = gql`
    query GetBusinessWorkplaceLaborCostMarginAlert($workplaceId: ID!) {
  businessWorkplaceLaborCostMarginAlert(workplaceId: $workplaceId) {
    average
    exceedLowerBoundDays
    exceedUpperBoundDays
  }
}
    `;

export function useGetBusinessWorkplaceLaborCostMarginAlertQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceLaborCostMarginAlertQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceLaborCostMarginAlertQuery, Types.GetBusinessWorkplaceLaborCostMarginAlertQueryVariables>({ query: GetBusinessWorkplaceLaborCostMarginAlertDocument, ...options });
};
export const CreateLaborCostMarginAlertSettingDocument = gql`
    mutation CreateLaborCostMarginAlertSetting($input: CreateLaborCostMarginAlertSettingInput!) {
  createLaborCostMarginAlertSetting(input: $input) {
    laborCostMarginAlertSetting {
      id
      startDate
      laborCostMarginAlertSettingJson
    }
  }
}
    `;

export function useCreateLaborCostMarginAlertSettingMutation() {
  return Urql.useMutation<Types.CreateLaborCostMarginAlertSettingMutation, Types.CreateLaborCostMarginAlertSettingMutationVariables>(CreateLaborCostMarginAlertSettingDocument);
};
export const DeleteLaborCostMarginAlertSettingDocument = gql`
    mutation DeleteLaborCostMarginAlertSetting($input: DeleteLaborCostMarginAlertSettingInput!) {
  deleteLaborCostMarginAlertSetting(input: $input) {
    laborCostMarginAlertSettingId
  }
}
    `;

export function useDeleteLaborCostMarginAlertSettingMutation() {
  return Urql.useMutation<Types.DeleteLaborCostMarginAlertSettingMutation, Types.DeleteLaborCostMarginAlertSettingMutationVariables>(DeleteLaborCostMarginAlertSettingDocument);
};
export const UpdateLaborCostMarginAlertSettingDocument = gql`
    mutation UpdateLaborCostMarginAlertSetting($input: UpdateLaborCostMarginAlertSettingInput!) {
  updateLaborCostMarginAlertSetting(input: $input) {
    laborCostMarginAlertSetting {
      id
      startDate
      laborCostMarginAlertSettingJson
    }
  }
}
    `;

export function useUpdateLaborCostMarginAlertSettingMutation() {
  return Urql.useMutation<Types.UpdateLaborCostMarginAlertSettingMutation, Types.UpdateLaborCostMarginAlertSettingMutationVariables>(UpdateLaborCostMarginAlertSettingDocument);
};
export const GetBusinessWorkplaceShiftsDocument = gql`
    query GetBusinessWorkplaceShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useGetBusinessWorkplaceShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessWorkplaceShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessWorkplaceShiftsQuery, Types.GetBusinessWorkplaceShiftsQueryVariables>({ query: GetBusinessWorkplaceShiftsDocument, ...options });
};
export const CreateTargetRevenueSettingDocument = gql`
    mutation CreateTargetRevenueSetting($input: CreateTargetRevenueSettingInput!) {
  createTargetRevenueSetting(input: $input) {
    targetRevenueSetting {
      id
      startDate
      targetRevenueSettingJson
    }
  }
}
    `;

export function useCreateTargetRevenueSettingMutation() {
  return Urql.useMutation<Types.CreateTargetRevenueSettingMutation, Types.CreateTargetRevenueSettingMutationVariables>(CreateTargetRevenueSettingDocument);
};
export const DeleteTargetRevenueSettingDocument = gql`
    mutation DeleteTargetRevenueSetting($input: DeleteTargetRevenueSettingInput!) {
  deleteTargetRevenueSetting(input: $input) {
    targetRevenueSettingId
  }
}
    `;

export function useDeleteTargetRevenueSettingMutation() {
  return Urql.useMutation<Types.DeleteTargetRevenueSettingMutation, Types.DeleteTargetRevenueSettingMutationVariables>(DeleteTargetRevenueSettingDocument);
};
export const UpdateTargetRevenueSettingDocument = gql`
    mutation UpdateTargetRevenueSetting($input: UpdateTargetRevenueSettingInput!) {
  updateTargetRevenueSetting(input: $input) {
    targetRevenueSetting {
      id
      startDate
      targetRevenueSettingJson
    }
  }
}
    `;

export function useUpdateTargetRevenueSettingMutation() {
  return Urql.useMutation<Types.UpdateTargetRevenueSettingMutation, Types.UpdateTargetRevenueSettingMutationVariables>(UpdateTargetRevenueSettingDocument);
};
export const AttendanceChartDialog_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query AttendanceChartDialog_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendanceChartDialog_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendanceChartDialog_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendanceChartDialog_GetEmployeeApprovedLeaveRequestsQuery, Types.AttendanceChartDialog_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: AttendanceChartDialog_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const AttendanceChartDialog_GetEmployeeAttendancesDocument = gql`
    query AttendanceChartDialog_GetEmployeeAttendances($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendances(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
    clockIn
    clockInOrigin
    clockOut
    clockOutOrigin
    absenceMins
    convertibleCompRestMins
    convertibleLeaveCompRestMins
    lateMins
    leaveEarlyMins
    overtimeMins
    restMins
    note
    assignee {
      id
      name
    }
    hasCompRestMinute
    shift {
      id
      startTime
      endTime
      restStartTime
      restMins
      date
      assignee {
        id
      }
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendanceChartDialog_GetEmployeeAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendanceChartDialog_GetEmployeeAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendanceChartDialog_GetEmployeeAttendancesQuery, Types.AttendanceChartDialog_GetEmployeeAttendancesQueryVariables>({ query: AttendanceChartDialog_GetEmployeeAttendancesDocument, ...options });
};
export const AttendanceChartDialog_GetEmployeeDayoffsDocument = gql`
    query AttendanceChartDialog_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useAttendanceChartDialog_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendanceChartDialog_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendanceChartDialog_GetEmployeeDayoffsQuery, Types.AttendanceChartDialog_GetEmployeeDayoffsQueryVariables>({ query: AttendanceChartDialog_GetEmployeeDayoffsDocument, ...options });
};
export const AttendanceChartDialog_GetEmployeeHolidaysDocument = gql`
    query AttendanceChartDialog_GetEmployeeHolidays($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeHolidays(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    holidayId
    name
    date
    public
    updatedAt
  }
}
    `;

export function useAttendanceChartDialog_GetEmployeeHolidaysQuery(options: Omit<Urql.UseQueryArgs<Types.AttendanceChartDialog_GetEmployeeHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendanceChartDialog_GetEmployeeHolidaysQuery, Types.AttendanceChartDialog_GetEmployeeHolidaysQueryVariables>({ query: AttendanceChartDialog_GetEmployeeHolidaysDocument, ...options });
};
export const AttendanceChartDialog_GetEmployeePayRatesDocument = gql`
    query AttendanceChartDialog_GetEmployeePayRates($employeeId: ID!) {
  businessEmployeePayRates(employeeId: $employeeId) {
    id
    type
    rate
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useAttendanceChartDialog_GetEmployeePayRatesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendanceChartDialog_GetEmployeePayRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendanceChartDialog_GetEmployeePayRatesQuery, Types.AttendanceChartDialog_GetEmployeePayRatesQueryVariables>({ query: AttendanceChartDialog_GetEmployeePayRatesDocument, ...options });
};
export const AttendanceChartDialog_GetEmployeeShiftsDocument = gql`
    query AttendanceChartDialog_GetEmployeeShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
    linkedAttendance {
      id
      status
      clockIn
      clockOut
      restMins
      overtimeMins
    }
  }
}
    `;

export function useAttendanceChartDialog_GetEmployeeShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendanceChartDialog_GetEmployeeShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendanceChartDialog_GetEmployeeShiftsQuery, Types.AttendanceChartDialog_GetEmployeeShiftsQueryVariables>({ query: AttendanceChartDialog_GetEmployeeShiftsDocument, ...options });
};
export const CreateAttendanceDocument = gql`
    mutation CreateAttendance($input: CreateAttendanceInput!) {
  createAttendance(input: $input) {
    attendance {
      id
    }
  }
}
    `;

export function useCreateAttendanceMutation() {
  return Urql.useMutation<Types.CreateAttendanceMutation, Types.CreateAttendanceMutationVariables>(CreateAttendanceDocument);
};
export const DeleteAttendanceDocument = gql`
    mutation DeleteAttendance($input: DeleteAttendanceInput!) {
  deleteAttendance(input: $input) {
    attendanceId
  }
}
    `;

export function useDeleteAttendanceMutation() {
  return Urql.useMutation<Types.DeleteAttendanceMutation, Types.DeleteAttendanceMutationVariables>(DeleteAttendanceDocument);
};
export const EditAttendanceDialog_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query EditAttendanceDialog_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
  }
}
    `;

export function useEditAttendanceDialog_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.EditAttendanceDialog_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditAttendanceDialog_GetEmployeeApprovedLeaveRequestsQuery, Types.EditAttendanceDialog_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: EditAttendanceDialog_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const EditAttendanceDialog_GetEmployeeDayoffsDocument = gql`
    query EditAttendanceDialog_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useEditAttendanceDialog_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.EditAttendanceDialog_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditAttendanceDialog_GetEmployeeDayoffsQuery, Types.EditAttendanceDialog_GetEmployeeDayoffsQueryVariables>({ query: EditAttendanceDialog_GetEmployeeDayoffsDocument, ...options });
};
export const EditAttendanceDialog_GetPayrollSettlementsDocument = gql`
    query EditAttendanceDialog_GetPayrollSettlements($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplacePayrollSettlements(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    startDate
    endDate
    archived
    payrolls {
      employeeId
      audited
    }
  }
}
    `;

export function useEditAttendanceDialog_GetPayrollSettlementsQuery(options: Omit<Urql.UseQueryArgs<Types.EditAttendanceDialog_GetPayrollSettlementsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditAttendanceDialog_GetPayrollSettlementsQuery, Types.EditAttendanceDialog_GetPayrollSettlementsQueryVariables>({ query: EditAttendanceDialog_GetPayrollSettlementsDocument, ...options });
};
export const UpdateAttendanceDocument = gql`
    mutation UpdateAttendance($input: UpdateAttendanceInput!) {
  updateAttendance(input: $input) {
    attendance {
      id
    }
  }
}
    `;

export function useUpdateAttendanceMutation() {
  return Urql.useMutation<Types.UpdateAttendanceMutation, Types.UpdateAttendanceMutationVariables>(UpdateAttendanceDocument);
};
export const GetAttendanceWithEventLogsDocument = gql`
    query GetAttendanceWithEventLogs($id: ID!) {
  node(id: $id) {
    ... on BusinessWorkplaceAttendance {
      id
      status
      clockIn
      clockOut
      restMins
      restTimestamps
      overtimeMins
      convertibleCompRestMins
      reasonForOvertime
      note
      assignee {
        id
      }
      shift {
        id
        startTime
        endTime
        restStartTime
        restMins
        date
        assignee {
          id
        }
        position {
          id
          name
          color
        }
      }
      eventLogs {
        type
        attendance {
          clockIn
          clockOut
          restMins
          status
        }
      }
    }
  }
}
    `;

export function useGetAttendanceWithEventLogsQuery(options: Omit<Urql.UseQueryArgs<Types.GetAttendanceWithEventLogsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetAttendanceWithEventLogsQuery, Types.GetAttendanceWithEventLogsQueryVariables>({ query: GetAttendanceWithEventLogsDocument, ...options });
};
export const ApproveExpenseDocument = gql`
    mutation ApproveExpense($input: ApproveExpenseInput!) {
  approveExpense(input: $input) {
    expense {
      id
    }
  }
}
    `;

export function useApproveExpenseMutation() {
  return Urql.useMutation<Types.ApproveExpenseMutation, Types.ApproveExpenseMutationVariables>(ApproveExpenseDocument);
};
export const DeclineExpenseDocument = gql`
    mutation DeclineExpense($input: DeclineExpenseInput!) {
  declineExpense(input: $input) {
    expense {
      id
    }
  }
}
    `;

export function useDeclineExpenseMutation() {
  return Urql.useMutation<Types.DeclineExpenseMutation, Types.DeclineExpenseMutationVariables>(DeclineExpenseDocument);
};
export const EditExpenseDialog_GetExpenseDocument = gql`
    query EditExpenseDialog_GetExpense($expenseId: ID!) {
  node(id: $expenseId) {
    ... on BusinessEmployeeExpense {
      id
      updatedAt
      status
      isPaid
      name
      amount
      date
      note
      images {
        id
        index
        file
      }
      employee {
        id
        name
        positions {
          id
          name
          color
        }
        user {
          id
          name
        }
      }
      eventLogs {
        insertedAt
        type
        employee {
          id
          name
          user {
            id
            name
          }
        }
      }
    }
  }
}
    `;

export function useEditExpenseDialog_GetExpenseQuery(options: Omit<Urql.UseQueryArgs<Types.EditExpenseDialog_GetExpenseQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditExpenseDialog_GetExpenseQuery, Types.EditExpenseDialog_GetExpenseQueryVariables>({ query: EditExpenseDialog_GetExpenseDocument, ...options });
};
export const ApproveLeaveRequestDocument = gql`
    mutation ApproveLeaveRequest($input: ApproveLeaveRequestInput!) {
  approveLeaveRequest(input: $input) {
    leaveRequest {
      id
    }
  }
}
    `;

export function useApproveLeaveRequestMutation() {
  return Urql.useMutation<Types.ApproveLeaveRequestMutation, Types.ApproveLeaveRequestMutationVariables>(ApproveLeaveRequestDocument);
};
export const DeclineLeaveRequestDocument = gql`
    mutation DeclineLeaveRequest($input: DeclineLeaveRequestInput!) {
  declineLeaveRequest(input: $input) {
    leaveRequest {
      id
    }
  }
}
    `;

export function useDeclineLeaveRequestMutation() {
  return Urql.useMutation<Types.DeclineLeaveRequestMutation, Types.DeclineLeaveRequestMutationVariables>(DeclineLeaveRequestDocument);
};
export const EditLeaveDialog_GetEmployeeAnnualLeaveMinuteBalanceDocument = gql`
    query EditLeaveDialog_GetEmployeeAnnualLeaveMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeAnnualLeaveMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    annualLeaveMinuteBalance
  }
}
    `;

export function useEditLeaveDialog_GetEmployeeAnnualLeaveMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.EditLeaveDialog_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditLeaveDialog_GetEmployeeAnnualLeaveMinuteBalanceQuery, Types.EditLeaveDialog_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>({ query: EditLeaveDialog_GetEmployeeAnnualLeaveMinuteBalanceDocument, ...options });
};
export const EditLeaveDialog_GetEmployeeCompRestMinuteBalanceDocument = gql`
    query EditLeaveDialog_GetEmployeeCompRestMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeCompRestMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    compRestMinuteBalance
  }
}
    `;

export function useEditLeaveDialog_GetEmployeeCompRestMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.EditLeaveDialog_GetEmployeeCompRestMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditLeaveDialog_GetEmployeeCompRestMinuteBalanceQuery, Types.EditLeaveDialog_GetEmployeeCompRestMinuteBalanceQueryVariables>({ query: EditLeaveDialog_GetEmployeeCompRestMinuteBalanceDocument, ...options });
};
export const EditLeaveDialog_GetLeaveRequestDocument = gql`
    query EditLeaveDialog_GetLeaveRequest($leaveRequestId: ID!) {
  node(id: $leaveRequestId) {
    ... on BusinessWorkplaceLeaveRequest {
      id
      updatedAt
      status
      type
      startDatetime
      endDatetime
      note
      employee {
        id
        name
        positions {
          id
          name
          color
        }
        user {
          id
          name
        }
      }
      linkedShifts {
        id
        date
        startTime
        endTime
        position {
          id
          name
          color
        }
      }
      eventLogs {
        insertedAt
        type
        employee {
          id
          name
          user {
            id
            name
          }
        }
      }
    }
  }
}
    `;

export function useEditLeaveDialog_GetLeaveRequestQuery(options: Omit<Urql.UseQueryArgs<Types.EditLeaveDialog_GetLeaveRequestQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditLeaveDialog_GetLeaveRequestQuery, Types.EditLeaveDialog_GetLeaveRequestQueryVariables>({ query: EditLeaveDialog_GetLeaveRequestDocument, ...options });
};
export const EditLeaveDialog_GetPayrollSettlementsDocument = gql`
    query EditLeaveDialog_GetPayrollSettlements($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplacePayrollSettlements(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    startDate
    endDate
    archived
    payrolls {
      employeeId
      audited
    }
  }
}
    `;

export function useEditLeaveDialog_GetPayrollSettlementsQuery(options: Omit<Urql.UseQueryArgs<Types.EditLeaveDialog_GetPayrollSettlementsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditLeaveDialog_GetPayrollSettlementsQuery, Types.EditLeaveDialog_GetPayrollSettlementsQueryVariables>({ query: EditLeaveDialog_GetPayrollSettlementsDocument, ...options });
};
export const EditLeaveDialog_GetShiftsDocument = gql`
    query EditLeaveDialog_GetShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useEditLeaveDialog_GetShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.EditLeaveDialog_GetShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EditLeaveDialog_GetShiftsQuery, Types.EditLeaveDialog_GetShiftsQueryVariables>({ query: EditLeaveDialog_GetShiftsDocument, ...options });
};
export const SessionsDocument = gql`
    query Sessions {
  currentUser {
    sessions {
      id
      businessName
      workplaceName
      mobileApp
      timeClockMode
      updatedAt
      userAgent {
        client {
          name
          version
          type
        }
        device {
          brand
          model
          type
        }
        os {
          name
          platform
          version
        }
      }
    }
  }
}
    `;

export function useSessionsQuery(options?: Omit<Urql.UseQueryArgs<Types.SessionsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SessionsQuery, Types.SessionsQueryVariables>({ query: SessionsDocument, ...options });
};
export const SetDefaultDomainDialog_GetBusinessWorkplacesDocument = gql`
    query SetDefaultDomainDialog_GetBusinessWorkplaces($businessId: ID!) {
  businessWorkplaces(businessId: $businessId) {
    id
    index
    active
    name
  }
}
    `;

export function useSetDefaultDomainDialog_GetBusinessWorkplacesQuery(options: Omit<Urql.UseQueryArgs<Types.SetDefaultDomainDialog_GetBusinessWorkplacesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SetDefaultDomainDialog_GetBusinessWorkplacesQuery, Types.SetDefaultDomainDialog_GetBusinessWorkplacesQueryVariables>({ query: SetDefaultDomainDialog_GetBusinessWorkplacesDocument, ...options });
};
export const AttendancePage_GetLeaveRequestsDocument = gql`
    query AttendancePage_GetLeaveRequests($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceLeaveRequests(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendancePage_GetLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetLeaveRequestsQuery, Types.AttendancePage_GetLeaveRequestsQueryVariables>({ query: AttendancePage_GetLeaveRequestsDocument, ...options });
};
export const AttendancePage_GetAttendancesDocument = gql`
    query AttendancePage_GetAttendances($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceAttendances(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
    clockIn
    clockOut
    restMins
    note
    assignee {
      id
      name
    }
    hasCompRestMinute
    shift {
      id
      startTime
      endTime
      restStartTime
      restMins
      date
      assignee {
        id
      }
      position {
        id
        name
        color
      }
      offsiteWorkplace {
        id
        name
      }
    }
  }
}
    `;

export function useAttendancePage_GetAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetAttendancesQuery, Types.AttendancePage_GetAttendancesQueryVariables>({ query: AttendancePage_GetAttendancesDocument, ...options });
};
export const AttendancePage_GetDayoffsDocument = gql`
    query AttendancePage_GetDayoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDayoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useAttendancePage_GetDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetDayoffsQuery, Types.AttendancePage_GetDayoffsQueryVariables>({ query: AttendancePage_GetDayoffsDocument, ...options });
};
export const AttendancePage_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query AttendancePage_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendancePage_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeeApprovedLeaveRequestsQuery, Types.AttendancePage_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: AttendancePage_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const AttendancePage_GetEmployeeAttendanceStatsDocument = gql`
    query AttendancePage_GetEmployeeAttendanceStats($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendanceStats(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    compRestHours
  }
}
    `;

export function useAttendancePage_GetEmployeeAttendanceStatsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeeAttendanceStatsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeeAttendanceStatsQuery, Types.AttendancePage_GetEmployeeAttendanceStatsQueryVariables>({ query: AttendancePage_GetEmployeeAttendanceStatsDocument, ...options });
};
export const AttendancePage_GetEmployeeAttendancesDocument = gql`
    query AttendancePage_GetEmployeeAttendances($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendances(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
    clockIn
    clockOut
    absenceMins
    lateMins
    leaveEarlyMins
    overtimeMins
    restMins
    convertibleCompRestMins
    note
    assignee {
      id
      name
    }
    hasCompRestMinute
    shift {
      id
      startTime
      endTime
      restStartTime
      restMins
      date
      assignee {
        id
      }
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendancePage_GetEmployeeAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeeAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeeAttendancesQuery, Types.AttendancePage_GetEmployeeAttendancesQueryVariables>({ query: AttendancePage_GetEmployeeAttendancesDocument, ...options });
};
export const AttendancePage_GetEmployeeDayoffsDocument = gql`
    query AttendancePage_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useAttendancePage_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeeDayoffsQuery, Types.AttendancePage_GetEmployeeDayoffsQueryVariables>({ query: AttendancePage_GetEmployeeDayoffsDocument, ...options });
};
export const AttendancePage_GetEmployeeHolidaysDocument = gql`
    query AttendancePage_GetEmployeeHolidays($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeHolidays(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    holidayId
    name
    date
    public
    updatedAt
  }
}
    `;

export function useAttendancePage_GetEmployeeHolidaysQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeeHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeeHolidaysQuery, Types.AttendancePage_GetEmployeeHolidaysQueryVariables>({ query: AttendancePage_GetEmployeeHolidaysDocument, ...options });
};
export const AttendancePage_GetEmployeePayRatesDocument = gql`
    query AttendancePage_GetEmployeePayRates($employeeId: ID!) {
  businessEmployeePayRates(employeeId: $employeeId) {
    id
    type
    rate
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useAttendancePage_GetEmployeePayRatesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeePayRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeePayRatesQuery, Types.AttendancePage_GetEmployeePayRatesQueryVariables>({ query: AttendancePage_GetEmployeePayRatesDocument, ...options });
};
export const AttendancePage_GetEmployeeShiftsDocument = gql`
    query AttendancePage_GetEmployeeShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useAttendancePage_GetEmployeeShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeeShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeeShiftsQuery, Types.AttendancePage_GetEmployeeShiftsQueryVariables>({ query: AttendancePage_GetEmployeeShiftsDocument, ...options });
};
export const AttendancePage_GetEmployeesDocument = gql`
    query AttendancePage_GetEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!, $incumbent: Boolean!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    privilegedWorkingAreasOnly: true
    incumbent: $incumbent
  ) {
    id
    active
    index
    employeeNumber
    type
    name
    joiningDate
    insertedAt
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useAttendancePage_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetEmployeesQuery, Types.AttendancePage_GetEmployeesQueryVariables>({ query: AttendancePage_GetEmployeesDocument, ...options });
};
export const AttendancePage_GetShiftsDocument = gql`
    query AttendancePage_GetShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useAttendancePage_GetShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePage_GetShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePage_GetShiftsQuery, Types.AttendancePage_GetShiftsQueryVariables>({ query: AttendancePage_GetShiftsDocument, ...options });
};
export const AttendancePageDownloadButton_GetLeaveRequestsDocument = gql`
    query AttendancePageDownloadButton_GetLeaveRequests($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceLeaveRequests(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendancePageDownloadButton_GetLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageDownloadButton_GetLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageDownloadButton_GetLeaveRequestsQuery, Types.AttendancePageDownloadButton_GetLeaveRequestsQueryVariables>({ query: AttendancePageDownloadButton_GetLeaveRequestsDocument, ...options });
};
export const AttendancePageDownloadButton_GetAttendancesDocument = gql`
    query AttendancePageDownloadButton_GetAttendances($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceAttendances(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
    clockIn
    clockInOrigin
    clockOut
    clockOutOrigin
    absenceMins
    lateMins
    leaveEarlyMins
    overtimeMins
    restMins
    note
    assignee {
      id
      name
      employeeHolidays(startDate: $startDate, endDate: $endDate) {
        employeeId
        holidayId
        name
        date
        public
      }
    }
    hasCompRestMinute
    shift {
      id
      startTime
      endTime
      restStartTime
      restMins
      date
      assignee {
        id
      }
      position {
        id
        name
        color
      }
      offsiteWorkplace {
        id
        name
      }
    }
  }
}
    `;

export function useAttendancePageDownloadButton_GetAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageDownloadButton_GetAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageDownloadButton_GetAttendancesQuery, Types.AttendancePageDownloadButton_GetAttendancesQueryVariables>({ query: AttendancePageDownloadButton_GetAttendancesDocument, ...options });
};
export const AttendancePageDownloadButton_GetDayoffsDocument = gql`
    query AttendancePageDownloadButton_GetDayoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDayoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useAttendancePageDownloadButton_GetDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageDownloadButton_GetDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageDownloadButton_GetDayoffsQuery, Types.AttendancePageDownloadButton_GetDayoffsQueryVariables>({ query: AttendancePageDownloadButton_GetDayoffsDocument, ...options });
};
export const AttendancePageDownloadButton_GetEmployeesDocument = gql`
    query AttendancePageDownloadButton_GetEmployees($businessId: ID!, $startDate: String!, $endDate: String!, $incumbent: Boolean!) {
  businessEmployees(businessId: $businessId, incumbent: $incumbent) {
    id
    active
    index
    employeeNumber
    type
    name
    joiningDate
    insertedAt
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
    workplace {
      id
      name
    }
  }
}
    `;

export function useAttendancePageDownloadButton_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageDownloadButton_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageDownloadButton_GetEmployeesQuery, Types.AttendancePageDownloadButton_GetEmployeesQueryVariables>({ query: AttendancePageDownloadButton_GetEmployeesDocument, ...options });
};
export const AttendancePageDownloadButton_GetShiftsDocument = gql`
    query AttendancePageDownloadButton_GetShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useAttendancePageDownloadButton_GetShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageDownloadButton_GetShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageDownloadButton_GetShiftsQuery, Types.AttendancePageDownloadButton_GetShiftsQueryVariables>({ query: AttendancePageDownloadButton_GetShiftsDocument, ...options });
};
export const AttendancePageV1_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query AttendancePageV1_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendancePageV1_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeeApprovedLeaveRequestsQuery, Types.AttendancePageV1_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: AttendancePageV1_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const AttendancePageV1_GetEmployeeAttendanceStatisticsDocument = gql`
    query AttendancePageV1_GetEmployeeAttendanceStatistics($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendanceStatistics(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    date
    regularMins
    overtimeMins
    dayoffOvertimeMins
    holidayOvertimeMins
    compRestMins
    leaveCompRestMins
    lateMins
    leaveEarlyMins
    absenceMins
    annualLeaveMins
    compRestLeaveMins
    funeralLeaveMins
    maternityLeaveMins
    menstruationLeaveMins
    officialLeaveMins
    personalLeaveMins
    sickLeaveMins
    typhoonLeaveMins
    weddingLeaveMins
    attendanceMins
    hasPendingAttendances
  }
}
    `;

export function useAttendancePageV1_GetEmployeeAttendanceStatisticsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeeAttendanceStatisticsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeeAttendanceStatisticsQuery, Types.AttendancePageV1_GetEmployeeAttendanceStatisticsQueryVariables>({ query: AttendancePageV1_GetEmployeeAttendanceStatisticsDocument, ...options });
};
export const AttendancePageV1_GetEmployeeAttendancesDocument = gql`
    query AttendancePageV1_GetEmployeeAttendances($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendances(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
    clockIn
    clockOut
    absenceMins
    lateMins
    leaveEarlyMins
    overtimeMins
    restMins
    convertibleCompRestMins
    note
    assignee {
      id
      name
    }
    hasCompRestMinute
    shift {
      id
      startTime
      endTime
      restStartTime
      restMins
      date
      assignee {
        id
      }
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useAttendancePageV1_GetEmployeeAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeeAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeeAttendancesQuery, Types.AttendancePageV1_GetEmployeeAttendancesQueryVariables>({ query: AttendancePageV1_GetEmployeeAttendancesDocument, ...options });
};
export const AttendancePageV1_GetEmployeeDayoffsDocument = gql`
    query AttendancePageV1_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useAttendancePageV1_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeeDayoffsQuery, Types.AttendancePageV1_GetEmployeeDayoffsQueryVariables>({ query: AttendancePageV1_GetEmployeeDayoffsDocument, ...options });
};
export const AttendancePageV1_GetEmployeeHolidaysDocument = gql`
    query AttendancePageV1_GetEmployeeHolidays($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeHolidays(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    holidayId
    name
    date
    public
    updatedAt
  }
}
    `;

export function useAttendancePageV1_GetEmployeeHolidaysQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeeHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeeHolidaysQuery, Types.AttendancePageV1_GetEmployeeHolidaysQueryVariables>({ query: AttendancePageV1_GetEmployeeHolidaysDocument, ...options });
};
export const AttendancePageV1_GetEmployeePayRatesDocument = gql`
    query AttendancePageV1_GetEmployeePayRates($employeeId: ID!) {
  businessEmployeePayRates(employeeId: $employeeId) {
    id
    type
    rate
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useAttendancePageV1_GetEmployeePayRatesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeePayRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeePayRatesQuery, Types.AttendancePageV1_GetEmployeePayRatesQueryVariables>({ query: AttendancePageV1_GetEmployeePayRatesDocument, ...options });
};
export const AttendancePageV1_GetEmployeeShiftsDocument = gql`
    query AttendancePageV1_GetEmployeeShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useAttendancePageV1_GetEmployeeShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeeShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeeShiftsQuery, Types.AttendancePageV1_GetEmployeeShiftsQueryVariables>({ query: AttendancePageV1_GetEmployeeShiftsDocument, ...options });
};
export const AttendancePageV1_GetEmployeesDocument = gql`
    query AttendancePageV1_GetEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!, $incumbent: Boolean!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    privilegedWorkingAreasOnly: true
    incumbent: $incumbent
  ) {
    id
    active
    index
    employeeNumber
    type
    name
    joiningDate
    insertedAt
    suspended
    activePayRate {
      partTime
    }
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useAttendancePageV1_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1_GetEmployeesQuery, Types.AttendancePageV1_GetEmployeesQueryVariables>({ query: AttendancePageV1_GetEmployeesDocument, ...options });
};
export const AttendancePageV1DailyHoursGraphButton_GetEmployeeAttendanceStatisticsDocument = gql`
    query AttendancePageV1DailyHoursGraphButton_GetEmployeeAttendanceStatistics($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendanceStatistics(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    employeeName
    date
    attendanceMins
  }
}
    `;

export function useAttendancePageV1DailyHoursGraphButton_GetEmployeeAttendanceStatisticsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1DailyHoursGraphButton_GetEmployeeAttendanceStatisticsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1DailyHoursGraphButton_GetEmployeeAttendanceStatisticsQuery, Types.AttendancePageV1DailyHoursGraphButton_GetEmployeeAttendanceStatisticsQueryVariables>({ query: AttendancePageV1DailyHoursGraphButton_GetEmployeeAttendanceStatisticsDocument, ...options });
};
export const AttendancePageDownloadButton_GetEmployeeAttendanceLogExportDocument = gql`
    query AttendancePageDownloadButton_GetEmployeeAttendanceLogExport($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendanceLogExport(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    employeeName
    employeeNumber
    attendances {
      id
      clockIn
      clockInOrigin
      clockOut
      clockOutOrigin
      absenceMins
      lateMins
      leaveEarlyMins
      overtimeMins
      restMins
      statistic {
        regularMins
        overtimeMins
        dayoffOvertimeMins
        holidayOvertimeMins
        compRestMins
        leaveCompRestMins
        attendanceMins
      }
      note
      dayoff {
        id
        type
      }
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      holiday {
        holidayId
        name
      }
      shift {
        id
        date
        startTime
        endTime
        position {
          id
          name
          color
        }
        offsiteWorkplace {
          id
          name
        }
      }
    }
    attendanceStatistics {
      employeeId
      regularMins
      overtimeMins
      dayoffOvertimeMins
      holidayOvertimeMins
      compRestMins
      leaveCompRestMins
      lateMins
      leaveEarlyMins
      absenceMins
      annualLeaveMins
      compRestLeaveMins
      funeralLeaveMins
      maternityLeaveMins
      menstruationLeaveMins
      officialLeaveMins
      personalLeaveMins
      sickLeaveMins
      typhoonLeaveMins
      weddingLeaveMins
      attendanceMins
    }
    note
  }
}
    `;

export function useAttendancePageDownloadButton_GetEmployeeAttendanceLogExportQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageDownloadButton_GetEmployeeAttendanceLogExportQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageDownloadButton_GetEmployeeAttendanceLogExportQuery, Types.AttendancePageDownloadButton_GetEmployeeAttendanceLogExportQueryVariables>({ query: AttendancePageDownloadButton_GetEmployeeAttendanceLogExportDocument, ...options });
};
export const AttendancePageV1AttendanceChartDialog_GetEmployeeAttendanceStatisticsDocument = gql`
    query AttendancePageV1AttendanceChartDialog_GetEmployeeAttendanceStatistics($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendanceStatistics(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    date
    regularMins
    regularMinsAcc
    overtimeMins
    dayoffOvertimeMins
    holidayOvertimeMins
    compRestMins
    leaveCompRestMins
    lateMins
    leaveEarlyMins
    absenceMins
    annualLeaveMins
    compRestLeaveMins
    funeralLeaveMins
    maternityLeaveMins
    menstruationLeaveMins
    officialLeaveMins
    personalLeaveMins
    sickLeaveMins
    typhoonLeaveMins
    weddingLeaveMins
    attendanceMins
    hasPendingAttendances
  }
}
    `;

export function useAttendancePageV1AttendanceChartDialog_GetEmployeeAttendanceStatisticsQuery(options: Omit<Urql.UseQueryArgs<Types.AttendancePageV1AttendanceChartDialog_GetEmployeeAttendanceStatisticsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AttendancePageV1AttendanceChartDialog_GetEmployeeAttendanceStatisticsQuery, Types.AttendancePageV1AttendanceChartDialog_GetEmployeeAttendanceStatisticsQueryVariables>({ query: AttendancePageV1AttendanceChartDialog_GetEmployeeAttendanceStatisticsDocument, ...options });
};
export const BillingPage_GetBusinessReferralCreditsDocument = gql`
    query BillingPage_GetBusinessReferralCredits($businessId: ID!) {
  node(id: $businessId) {
    ... on Business {
      id
      referralCredits {
        id
        insertedAt
        updatedAt
        issueInvoiceId
        redeemInvoiceId
        amount
        referredBusinessName
      }
    }
  }
}
    `;

export function useBillingPage_GetBusinessReferralCreditsQuery(options: Omit<Urql.UseQueryArgs<Types.BillingPage_GetBusinessReferralCreditsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.BillingPage_GetBusinessReferralCreditsQuery, Types.BillingPage_GetBusinessReferralCreditsQueryVariables>({ query: BillingPage_GetBusinessReferralCreditsDocument, ...options });
};
export const DashboardPage_GetBusinessWorkplacesDocument = gql`
    query DashboardPage_GetBusinessWorkplaces($businessId: ID!) {
  businessWorkplaces(businessId: $businessId) {
    id
    index
    active
    name
    activeEmployeeCount
    employeeCount
    admins {
      privileges
      admin {
        id
        name
        user {
          id
          name
          avatar
        }
      }
    }
    gmapPlace {
      gmapPlaceId
      address
    }
    geofence {
      lat
      lng
      distance
    }
  }
}
    `;

export function useDashboardPage_GetBusinessWorkplacesQuery(options: Omit<Urql.UseQueryArgs<Types.DashboardPage_GetBusinessWorkplacesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DashboardPage_GetBusinessWorkplacesQuery, Types.DashboardPage_GetBusinessWorkplacesQueryVariables>({ query: DashboardPage_GetBusinessWorkplacesDocument, ...options });
};
export const DashboardPage_GetPendingAttendancesDocument = gql`
    query DashboardPage_GetPendingAttendances($workplaceId: ID!) {
  businessWorkplacePendingAttendances(workplaceId: $workplaceId) {
    id
    status
    clockIn
    clockInOrigin
    clockOut
    clockOutOrigin
    lateMins
    leaveEarlyMins
    overtimeMins
    reasonForOvertime
    restMins
    assignee {
      id
      index
      name
      user {
        id
        name
        avatar
      }
    }
    leaveRequests {
      id
    }
    shift {
      id
      date
      startTime
      endTime
      restMins
      note
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useDashboardPage_GetPendingAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.DashboardPage_GetPendingAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DashboardPage_GetPendingAttendancesQuery, Types.DashboardPage_GetPendingAttendancesQueryVariables>({ query: DashboardPage_GetPendingAttendancesDocument, ...options });
};
export const DashboardPage_GetPendingExpensesDocument = gql`
    query DashboardPage_GetPendingExpenses($workplaceId: ID!) {
  businessWorkplacePendingExpenses(workplaceId: $workplaceId) {
    id
    updatedAt
    status
    amount
    date
    note
    images {
      id
      index
      file
    }
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
  }
}
    `;

export function useDashboardPage_GetPendingExpensesQuery(options: Omit<Urql.UseQueryArgs<Types.DashboardPage_GetPendingExpensesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DashboardPage_GetPendingExpensesQuery, Types.DashboardPage_GetPendingExpensesQueryVariables>({ query: DashboardPage_GetPendingExpensesDocument, ...options });
};
export const DashboardPage_GetPendingLeaveRequestsDocument = gql`
    query DashboardPage_GetPendingLeaveRequests($workplaceId: ID!) {
  businessWorkplacePendingLeaveRequests(workplaceId: $workplaceId) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
        avatar
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useDashboardPage_GetPendingLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.DashboardPage_GetPendingLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DashboardPage_GetPendingLeaveRequestsQuery, Types.DashboardPage_GetPendingLeaveRequestsQueryVariables>({ query: DashboardPage_GetPendingLeaveRequestsDocument, ...options });
};
export const GetScheduleOfTodayDocument = gql`
    query GetScheduleOfToday($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  node(id: $workplaceId) {
    ... on BusinessWorkplace {
      id
      shifts(startDate: $startDate, endDate: $endDate) {
        id
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        offsiteWorkplace {
          id
          name
        }
        position {
          id
          name
          color
        }
        assignee {
          id
          index
          name
          user {
            id
            name
            avatar
          }
        }
        linkedAttendance {
          id
          status
        }
      }
      offsiteShifts(startDate: $startDate, endDate: $endDate) {
        id
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        offsiteWorkplace {
          id
          name
        }
        position {
          id
          name
          color
        }
        assignee {
          id
          index
          name
          user {
            id
            name
            avatar
          }
        }
        linkedAttendance {
          id
          status
        }
      }
    }
  }
}
    `;

export function useGetScheduleOfTodayQuery(options: Omit<Urql.UseQueryArgs<Types.GetScheduleOfTodayQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetScheduleOfTodayQuery, Types.GetScheduleOfTodayQueryVariables>({ query: GetScheduleOfTodayDocument, ...options });
};
export const EmployeesPage_GetEmployeeAdvancedAnnualLeaveMinutesDocument = gql`
    query EmployeesPage_GetEmployeeAdvancedAnnualLeaveMinutes($employeeId: ID!) {
  businessEmployeeAdvancedAnnualLeaveMinutes(employeeId: $employeeId) {
    id
    minutes
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useEmployeesPage_GetEmployeeAdvancedAnnualLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeAdvancedAnnualLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeAdvancedAnnualLeaveMinutesQuery, Types.EmployeesPage_GetEmployeeAdvancedAnnualLeaveMinutesQueryVariables>({ query: EmployeesPage_GetEmployeeAdvancedAnnualLeaveMinutesDocument, ...options });
};
export const EmployeesPage_GetEmployeeAnnualLeaveMinuteBalanceDocument = gql`
    query EmployeesPage_GetEmployeeAnnualLeaveMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeAnnualLeaveMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    annualLeaveMinuteBalance
  }
}
    `;

export function useEmployeesPage_GetEmployeeAnnualLeaveMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeAnnualLeaveMinuteBalanceQuery, Types.EmployeesPage_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>({ query: EmployeesPage_GetEmployeeAnnualLeaveMinuteBalanceDocument, ...options });
};
export const EmployeesPage_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query EmployeesPage_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useEmployeesPage_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeApprovedLeaveRequestsQuery, Types.EmployeesPage_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: EmployeesPage_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const EmployeesPage_GetEmployeeCarriedAnnualLeaveMinutesDocument = gql`
    query EmployeesPage_GetEmployeeCarriedAnnualLeaveMinutes($employeeId: ID!, $date: String) {
  businessEmployeeCarriedAnnualLeaveMinutes(employeeId: $employeeId, date: $date) {
    id
    carriedAnnualLeaveMinutes
  }
}
    `;

export function useEmployeesPage_GetEmployeeCarriedAnnualLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeCarriedAnnualLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeCarriedAnnualLeaveMinutesQuery, Types.EmployeesPage_GetEmployeeCarriedAnnualLeaveMinutesQueryVariables>({ query: EmployeesPage_GetEmployeeCarriedAnnualLeaveMinutesDocument, ...options });
};
export const EmployeesPage_GetEmployeeCompRestMinuteBalanceDocument = gql`
    query EmployeesPage_GetEmployeeCompRestMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeCompRestMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    compRestMinuteBalance
  }
}
    `;

export function useEmployeesPage_GetEmployeeCompRestMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeCompRestMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeCompRestMinuteBalanceQuery, Types.EmployeesPage_GetEmployeeCompRestMinuteBalanceQueryVariables>({ query: EmployeesPage_GetEmployeeCompRestMinuteBalanceDocument, ...options });
};
export const EmployeesPage_GetEmployeeCompRestMinutesDocument = gql`
    query EmployeesPage_GetEmployeeCompRestMinutes($employeeId: ID!, $startDate: String, $endDate: String) {
  businessEmployeeAttendanceCompRestMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    minutes
    status
    expireAt
    isPaid
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
    usages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
  }
}
    `;

export function useEmployeesPage_GetEmployeeCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeCompRestMinutesQuery, Types.EmployeesPage_GetEmployeeCompRestMinutesQueryVariables>({ query: EmployeesPage_GetEmployeeCompRestMinutesDocument, ...options });
};
export const EmployeesPage_GetEmployeeDayoffsDocument = gql`
    query EmployeesPage_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useEmployeesPage_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeDayoffsQuery, Types.EmployeesPage_GetEmployeeDayoffsQueryVariables>({ query: EmployeesPage_GetEmployeeDayoffsDocument, ...options });
};
export const EmployeesPage_GetEmployeeHazardInsurancesDocument = gql`
    query EmployeesPage_GetEmployeeHazardInsurances($employeeId: ID!) {
  businessEmployeeHazardInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useEmployeesPage_GetEmployeeHazardInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeHazardInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeHazardInsurancesQuery, Types.EmployeesPage_GetEmployeeHazardInsurancesQueryVariables>({ query: EmployeesPage_GetEmployeeHazardInsurancesDocument, ...options });
};
export const EmployeesPage_GetEmployeeHealthInsurancesDocument = gql`
    query EmployeesPage_GetEmployeeHealthInsurances($employeeId: ID!) {
  businessEmployeeHealthInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useEmployeesPage_GetEmployeeHealthInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeHealthInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeHealthInsurancesQuery, Types.EmployeesPage_GetEmployeeHealthInsurancesQueryVariables>({ query: EmployeesPage_GetEmployeeHealthInsurancesDocument, ...options });
};
export const EmployeesPage_GetEmployeeLaborInsurancesDocument = gql`
    query EmployeesPage_GetEmployeeLaborInsurances($employeeId: ID!) {
  businessEmployeeLaborInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    updatedAt
    admin {
      id
      name
    }
  }
}
    `;

export function useEmployeesPage_GetEmployeeLaborInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeLaborInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeLaborInsurancesQuery, Types.EmployeesPage_GetEmployeeLaborInsurancesQueryVariables>({ query: EmployeesPage_GetEmployeeLaborInsurancesDocument, ...options });
};
export const EmployeesPage_GetEmployeeLaborPensionsDocument = gql`
    query EmployeesPage_GetEmployeeLaborPensions($employeeId: ID!) {
  businessEmployeeLaborPensions(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useEmployeesPage_GetEmployeeLaborPensionsQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeLaborPensionsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeLaborPensionsQuery, Types.EmployeesPage_GetEmployeeLaborPensionsQueryVariables>({ query: EmployeesPage_GetEmployeeLaborPensionsDocument, ...options });
};
export const EmployeesPage_GetBusinessEmployeeLastPayrollAuditDateDocument = gql`
    query EmployeesPage_GetBusinessEmployeeLastPayrollAuditDate($employeeId: ID!) {
  businessEmployeeLastPayrollAuditDate(employeeId: $employeeId)
}
    `;

export function useEmployeesPage_GetBusinessEmployeeLastPayrollAuditDateQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetBusinessEmployeeLastPayrollAuditDateQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetBusinessEmployeeLastPayrollAuditDateQuery, Types.EmployeesPage_GetBusinessEmployeeLastPayrollAuditDateQueryVariables>({ query: EmployeesPage_GetBusinessEmployeeLastPayrollAuditDateDocument, ...options });
};
export const UpdateAttendanceLeaveCompRestMinuteDocument = gql`
    mutation UpdateAttendanceLeaveCompRestMinute($input: UpdateAttendanceLeaveCompRestMinuteInput!) {
  updateAttendanceLeaveCompRestMinute(input: $input) {
    attendanceLeaveCompRestMinute {
      minutes
      status
      expireAt
      employee {
        id
      }
      attendance {
        id
      }
    }
  }
}
    `;

export function useUpdateAttendanceLeaveCompRestMinuteMutation() {
  return Urql.useMutation<Types.UpdateAttendanceLeaveCompRestMinuteMutation, Types.UpdateAttendanceLeaveCompRestMinuteMutationVariables>(UpdateAttendanceLeaveCompRestMinuteDocument);
};
export const EmployeesPage_GetEmployeeLeaveCompRestMinutesDocument = gql`
    query EmployeesPage_GetEmployeeLeaveCompRestMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendanceLeaveCompRestMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    minutes
    status
    expireAt
    isPaid
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      convertibleLeaveCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
    leaveRequest {
      id
      type
      startDatetime
      endDatetime
    }
    usages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
  }
}
    `;

export function useEmployeesPage_GetEmployeeLeaveCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeLeaveCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeLeaveCompRestMinutesQuery, Types.EmployeesPage_GetEmployeeLeaveCompRestMinutesQueryVariables>({ query: EmployeesPage_GetEmployeeLeaveCompRestMinutesDocument, ...options });
};
export const EmployeesPage_GetBusinessEmployeePayRatesDocument = gql`
    query EmployeesPage_GetBusinessEmployeePayRates($employeeId: ID!) {
  businessEmployeePayRates(employeeId: $employeeId) {
    id
    type
    partTime
    rate
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useEmployeesPage_GetBusinessEmployeePayRatesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetBusinessEmployeePayRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetBusinessEmployeePayRatesQuery, Types.EmployeesPage_GetBusinessEmployeePayRatesQueryVariables>({ query: EmployeesPage_GetBusinessEmployeePayRatesDocument, ...options });
};
export const EmployeesPage_GetEmployeePrimitiveCompRestMinutesDocument = gql`
    query EmployeesPage_GetEmployeePrimitiveCompRestMinutes($employeeId: ID!) {
  businessEmployeePrimitiveCompRestMinutes(employeeId: $employeeId) {
    id
    minutes
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useEmployeesPage_GetEmployeePrimitiveCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeePrimitiveCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeePrimitiveCompRestMinutesQuery, Types.EmployeesPage_GetEmployeePrimitiveCompRestMinutesQueryVariables>({ query: EmployeesPage_GetEmployeePrimitiveCompRestMinutesDocument, ...options });
};
export const EmployeesPage_GetEmployeeProfilesDocument = gql`
    query EmployeesPage_GetEmployeeProfiles($employeeId: ID!) {
  businessEmployeeProfiles(employeeId: $employeeId) {
    id
    profileJson
  }
}
    `;

export function useEmployeesPage_GetEmployeeProfilesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeProfilesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeProfilesQuery, Types.EmployeesPage_GetEmployeeProfilesQueryVariables>({ query: EmployeesPage_GetEmployeeProfilesDocument, ...options });
};
export const EmployeePage_CreateEmployeeDocument = gql`
    mutation EmployeePage_CreateEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    employee {
      id
      active
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      user {
        id
        name
        avatar
        locale
      }
      activePayRate {
        id
      }
    }
  }
}
    `;

export function useEmployeePage_CreateEmployeeMutation() {
  return Urql.useMutation<Types.EmployeePage_CreateEmployeeMutation, Types.EmployeePage_CreateEmployeeMutationVariables>(EmployeePage_CreateEmployeeDocument);
};
export const EmployeePage_DeleteEmployeeDocument = gql`
    mutation EmployeePage_DeleteEmployee($input: DeleteEmployeeInput!) {
  deleteEmployee(input: $input) {
    employeeId
  }
}
    `;

export function useEmployeePage_DeleteEmployeeMutation() {
  return Urql.useMutation<Types.EmployeePage_DeleteEmployeeMutation, Types.EmployeePage_DeleteEmployeeMutationVariables>(EmployeePage_DeleteEmployeeDocument);
};
export const EmployeePage_UpdateEmployeeDocument = gql`
    mutation EmployeePage_UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    employee {
      id
      active
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      suspended
      user {
        id
        name
        avatar
        locale
      }
    }
  }
}
    `;

export function useEmployeePage_UpdateEmployeeMutation() {
  return Urql.useMutation<Types.EmployeePage_UpdateEmployeeMutation, Types.EmployeePage_UpdateEmployeeMutationVariables>(EmployeePage_UpdateEmployeeDocument);
};
export const EmployeesPage_GetEmployeesDocument = gql`
    query EmployeesPage_GetEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceEmployees(workplaceId: $workplaceId, active: false) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    mobilePhone
    joiningDate
    insertedAt
    advancedAnnualLeaveMinutes
    carriedAnnualLeaveMinutesYears
    annualLeaveMinutes
    activePayRate {
      partTime
    }
    primitiveCompRestMinuteUsages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    payRates {
      id
      type
      rate
      startDate
      metaJson
    }
    laborInsurances {
      id
      startDate
      metaJson
    }
    healthInsurances {
      id
      startDate
      metaJson
    }
    laborPensions {
      id
      startDate
      metaJson
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useEmployeesPage_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeesQuery, Types.EmployeesPage_GetEmployeesQueryVariables>({ query: EmployeesPage_GetEmployeesDocument, ...options });
};
export const EmployeePage_ChangeEmployeeWorkplaceDocument = gql`
    mutation EmployeePage_ChangeEmployeeWorkplace($input: ChangeEmployeeWorkplaceInput!) {
  changeEmployeeWorkplace(input: $input) {
    employee {
      id
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      suspended
      user {
        id
        name
        avatar
        locale
      }
      positions {
        id
        name
        color
      }
      workplace {
        id
        name
      }
    }
  }
}
    `;

export function useEmployeePage_ChangeEmployeeWorkplaceMutation() {
  return Urql.useMutation<Types.EmployeePage_ChangeEmployeeWorkplaceMutation, Types.EmployeePage_ChangeEmployeeWorkplaceMutationVariables>(EmployeePage_ChangeEmployeeWorkplaceDocument);
};
export const EmployeesPage_GetBusinessEmployeesDocument = gql`
    query EmployeesPage_GetBusinessEmployees($businessId: ID!, $incumbent: Boolean!) {
  businessEmployees(businessId: $businessId, incumbent: $incumbent) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    mobilePhone
    insertedAt
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    workplace {
      id
      name
    }
  }
}
    `;

export function useEmployeesPage_GetBusinessEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetBusinessEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetBusinessEmployeesQuery, Types.EmployeesPage_GetBusinessEmployeesQueryVariables>({ query: EmployeesPage_GetBusinessEmployeesDocument, ...options });
};
export const EmployeesPage_GetBusinessEmployeeActiveAnnualLeaveFormulaDocument = gql`
    query EmployeesPage_GetBusinessEmployeeActiveAnnualLeaveFormula($employeeId: ID!) {
  node(id: $employeeId) {
    ... on BusinessEmployee {
      id
      activeAnnualLeaveFormula {
        id
        formula
        note
      }
    }
  }
}
    `;

export function useEmployeesPage_GetBusinessEmployeeActiveAnnualLeaveFormulaQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetBusinessEmployeeActiveAnnualLeaveFormulaQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetBusinessEmployeeActiveAnnualLeaveFormulaQuery, Types.EmployeesPage_GetBusinessEmployeeActiveAnnualLeaveFormulaQueryVariables>({ query: EmployeesPage_GetBusinessEmployeeActiveAnnualLeaveFormulaDocument, ...options });
};
export const EmployeesPage_GetBusinessEmployeeActiveIncumbencyDocument = gql`
    query EmployeesPage_GetBusinessEmployeeActiveIncumbency($employeeId: ID!) {
  node(id: $employeeId) {
    ... on BusinessEmployee {
      id
      activeIncumbency {
        id
        employeeId
        startDate
        endDate
        tenureDaysAdj
        carriedAnnualLeaveYears
      }
    }
  }
}
    `;

export function useEmployeesPage_GetBusinessEmployeeActiveIncumbencyQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetBusinessEmployeeActiveIncumbencyQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetBusinessEmployeeActiveIncumbencyQuery, Types.EmployeesPage_GetBusinessEmployeeActiveIncumbencyQueryVariables>({ query: EmployeesPage_GetBusinessEmployeeActiveIncumbencyDocument, ...options });
};
export const CreateEmployeeIncumbencyDocument = gql`
    mutation CreateEmployeeIncumbency($input: CreateEmployeeIncumbencyInput!) {
  createEmployeeIncumbency(input: $input) {
    employeeIncumbency {
      id
      startDate
      endDate
      tenureDaysAdj
      carriedAnnualLeaveYears
    }
  }
}
    `;

export function useCreateEmployeeIncumbencyMutation() {
  return Urql.useMutation<Types.CreateEmployeeIncumbencyMutation, Types.CreateEmployeeIncumbencyMutationVariables>(CreateEmployeeIncumbencyDocument);
};
export const DeleteEmployeeIncumbencyDocument = gql`
    mutation DeleteEmployeeIncumbency($input: DeleteEmployeeIncumbencyInput!) {
  deleteEmployeeIncumbency(input: $input) {
    employeeIncumbencyId
  }
}
    `;

export function useDeleteEmployeeIncumbencyMutation() {
  return Urql.useMutation<Types.DeleteEmployeeIncumbencyMutation, Types.DeleteEmployeeIncumbencyMutationVariables>(DeleteEmployeeIncumbencyDocument);
};
export const UpdateEmployeeIncumbencyDocument = gql`
    mutation UpdateEmployeeIncumbency($input: UpdateEmployeeIncumbencyInput!) {
  updateEmployeeIncumbency(input: $input) {
    employeeIncumbency {
      id
      startDate
      endDate
      tenureDaysAdj
      carriedAnnualLeaveYears
    }
  }
}
    `;

export function useUpdateEmployeeIncumbencyMutation() {
  return Urql.useMutation<Types.UpdateEmployeeIncumbencyMutation, Types.UpdateEmployeeIncumbencyMutationVariables>(UpdateEmployeeIncumbencyDocument);
};
export const EmployeesPage_GetBusinessEmployeeIncumbenciesDocument = gql`
    query EmployeesPage_GetBusinessEmployeeIncumbencies($employeeId: ID!) {
  businessEmployeeIncumbencies(employeeId: $employeeId) {
    id
    employeeId
    startDate
    endDate
    tenureDaysAdj
    carriedAnnualLeaveYears
  }
}
    `;

export function useEmployeesPage_GetBusinessEmployeeIncumbenciesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetBusinessEmployeeIncumbenciesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetBusinessEmployeeIncumbenciesQuery, Types.EmployeesPage_GetBusinessEmployeeIncumbenciesQueryVariables>({ query: EmployeesPage_GetBusinessEmployeeIncumbenciesDocument, ...options });
};
export const EmployeesPage_EditIncumbencyDialog_GetBusinessWorkplacePayrollSettlementsDocument = gql`
    query EmployeesPage_EditIncumbencyDialog_GetBusinessWorkplacePayrollSettlements($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplacePayrollSettlements(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    startDate
    endDate
    archived
    payrolls {
      employeeId
      payrollSettlementId
      employee {
        id
        index
        name
        user {
          id
          name
          avatar
        }
      }
      payrollJson
      audited
      paid
    }
  }
}
    `;

export function useEmployeesPage_EditIncumbencyDialog_GetBusinessWorkplacePayrollSettlementsQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_EditIncumbencyDialog_GetBusinessWorkplacePayrollSettlementsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_EditIncumbencyDialog_GetBusinessWorkplacePayrollSettlementsQuery, Types.EmployeesPage_EditIncumbencyDialog_GetBusinessWorkplacePayrollSettlementsQueryVariables>({ query: EmployeesPage_EditIncumbencyDialog_GetBusinessWorkplacePayrollSettlementsDocument, ...options });
};
export const EmployeesPage_GetEmployeeAnnualIncumbenciesDocument = gql`
    query EmployeesPage_GetEmployeeAnnualIncumbencies($employeeId: ID!) {
  businessEmployeeAnnualIncumbencies(employeeId: $employeeId) {
    startDate
    endDate
    annualLeaveMinutesBalance
    annualLeaveMinutes
    advancedAnnualLeaveMinutes
    carriedAnnualLeaveMinutes
    isCarried
    isExpired
    isDeparted
    isDepleted
    isPaid
    isFirstHalfYearTenureCase
    annualLeaves {
      id
      startDatetime
      endDatetime
    }
  }
}
    `;

export function useEmployeesPage_GetEmployeeAnnualIncumbenciesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_GetEmployeeAnnualIncumbenciesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_GetEmployeeAnnualIncumbenciesQuery, Types.EmployeesPage_GetEmployeeAnnualIncumbenciesQueryVariables>({ query: EmployeesPage_GetEmployeeAnnualIncumbenciesDocument, ...options });
};
export const EmployeesPage_Wallet_CompRestMinutes_CompRestMinute_CompRestMinuteDialog_GetEmployeeLastAuditedPayrollDocument = gql`
    query EmployeesPage_Wallet_CompRestMinutes_CompRestMinute_CompRestMinuteDialog_GetEmployeeLastAuditedPayroll($employeeId: ID!) {
  businessEmployeeLastAuditedPayroll(employeeId: $employeeId) {
    employeeId
    payrollSettlementId
    employee {
      id
      index
      name
      user {
        id
        name
        avatar
      }
    }
    payrollJson
    audited
    paid
    startDate
    endDate
  }
}
    `;

export function useEmployeesPage_Wallet_CompRestMinutes_CompRestMinute_CompRestMinuteDialog_GetEmployeeLastAuditedPayrollQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPage_Wallet_CompRestMinutes_CompRestMinute_CompRestMinuteDialog_GetEmployeeLastAuditedPayrollQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPage_Wallet_CompRestMinutes_CompRestMinute_CompRestMinuteDialog_GetEmployeeLastAuditedPayrollQuery, Types.EmployeesPage_Wallet_CompRestMinutes_CompRestMinute_CompRestMinuteDialog_GetEmployeeLastAuditedPayrollQueryVariables>({ query: EmployeesPage_Wallet_CompRestMinutes_CompRestMinute_CompRestMinuteDialog_GetEmployeeLastAuditedPayrollDocument, ...options });
};
export const Wallet_ApproveExpenseDocument = gql`
    mutation Wallet_ApproveExpense($input: ApproveExpenseInput!) {
  approveExpense(input: $input) {
    expense {
      id
    }
  }
}
    `;

export function useWallet_ApproveExpenseMutation() {
  return Urql.useMutation<Types.Wallet_ApproveExpenseMutation, Types.Wallet_ApproveExpenseMutationVariables>(Wallet_ApproveExpenseDocument);
};
export const Wallet_DeclineExpenseDocument = gql`
    mutation Wallet_DeclineExpense($input: DeclineExpenseInput!) {
  declineExpense(input: $input) {
    expense {
      id
    }
  }
}
    `;

export function useWallet_DeclineExpenseMutation() {
  return Urql.useMutation<Types.Wallet_DeclineExpenseMutation, Types.Wallet_DeclineExpenseMutationVariables>(Wallet_DeclineExpenseDocument);
};
export const Wallet_GetEmployeeExpensesDocument = gql`
    query Wallet_GetEmployeeExpenses($employeeId: ID!) {
  businessEmployeeExpenses(employeeId: $employeeId) {
    id
    updatedAt
    status
    isPaid
    amount
    date
    note
    images {
      id
      index
      file
    }
  }
}
    `;

export function useWallet_GetEmployeeExpensesQuery(options: Omit<Urql.UseQueryArgs<Types.Wallet_GetEmployeeExpensesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.Wallet_GetEmployeeExpensesQuery, Types.Wallet_GetEmployeeExpensesQueryVariables>({ query: Wallet_GetEmployeeExpensesDocument, ...options });
};
export const Wallet_GetEmployeeDayoffsDocument = gql`
    query Wallet_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useWallet_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.Wallet_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.Wallet_GetEmployeeDayoffsQuery, Types.Wallet_GetEmployeeDayoffsQueryVariables>({ query: Wallet_GetEmployeeDayoffsDocument, ...options });
};
export const SetEmployeeHolidayV0Document = gql`
    mutation SetEmployeeHolidayV0($input: SetEmployeeHolidayInput!) {
  setEmployeeHoliday(input: $input) {
    employeeHoliday {
      employeeId
      holidayId
      date
      public
    }
  }
}
    `;

export function useSetEmployeeHolidayV0Mutation() {
  return Urql.useMutation<Types.SetEmployeeHolidayV0Mutation, Types.SetEmployeeHolidayV0MutationVariables>(SetEmployeeHolidayV0Document);
};
export const SchedulePageV0_GetEmployeeHolidaysDocument = gql`
    query SchedulePageV0_GetEmployeeHolidays($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeHolidays(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    holidayId
    name
    date
    public
    updatedAt
  }
}
    `;

export function useSchedulePageV0_GetEmployeeHolidaysQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetEmployeeHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetEmployeeHolidaysQuery, Types.SchedulePageV0_GetEmployeeHolidaysQueryVariables>({ query: SchedulePageV0_GetEmployeeHolidaysDocument, ...options });
};
export const Wallet_GetHolidaysDocument = gql`
    query Wallet_GetHolidays($countryCode: String!, $startDate: String!, $endDate: String!) {
  holidays(countryCode: $countryCode, startDate: $startDate, endDate: $endDate) {
    id
    public
    name
    date
  }
}
    `;

export function useWallet_GetHolidaysQuery(options: Omit<Urql.UseQueryArgs<Types.Wallet_GetHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.Wallet_GetHolidaysQuery, Types.Wallet_GetHolidaysQueryVariables>({ query: Wallet_GetHolidaysDocument, ...options });
};
export const EmployeesPageWorkplaceEmployees_CreateEmployeeDocument = gql`
    mutation EmployeesPageWorkplaceEmployees_CreateEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    employee {
      id
      active
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      user {
        id
        name
        avatar
        locale
      }
    }
  }
}
    `;

export function useEmployeesPageWorkplaceEmployees_CreateEmployeeMutation() {
  return Urql.useMutation<Types.EmployeesPageWorkplaceEmployees_CreateEmployeeMutation, Types.EmployeesPageWorkplaceEmployees_CreateEmployeeMutationVariables>(EmployeesPageWorkplaceEmployees_CreateEmployeeDocument);
};
export const EmployeesPageWorkplaceEmployees_DeleteEmployeeDocument = gql`
    mutation EmployeesPageWorkplaceEmployees_DeleteEmployee($input: DeleteEmployeeInput!) {
  deleteEmployee(input: $input) {
    employeeId
  }
}
    `;

export function useEmployeesPageWorkplaceEmployees_DeleteEmployeeMutation() {
  return Urql.useMutation<Types.EmployeesPageWorkplaceEmployees_DeleteEmployeeMutation, Types.EmployeesPageWorkplaceEmployees_DeleteEmployeeMutationVariables>(EmployeesPageWorkplaceEmployees_DeleteEmployeeDocument);
};
export const EmployeesPageWorkplaceEmployees_UpdateEmployeeDocument = gql`
    mutation EmployeesPageWorkplaceEmployees_UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    employee {
      id
      active
      index
      employeeNumber
      type
      name
      tags
      mobilePhone
      joiningDate
      suspended
      user {
        id
        name
        avatar
        locale
      }
    }
  }
}
    `;

export function useEmployeesPageWorkplaceEmployees_UpdateEmployeeMutation() {
  return Urql.useMutation<Types.EmployeesPageWorkplaceEmployees_UpdateEmployeeMutation, Types.EmployeesPageWorkplaceEmployees_UpdateEmployeeMutationVariables>(EmployeesPageWorkplaceEmployees_UpdateEmployeeDocument);
};
export const EmployeesPageWorkplaceEmployees_GetEmployeesDocument = gql`
    query EmployeesPageWorkplaceEmployees_GetEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!, $incumbent: Boolean!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    active: false
    incumbent: $incumbent
  ) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    mobilePhone
    joiningDate
    insertedAt
    advancedAnnualLeaveMinutes
    carriedAnnualLeaveMinutesYears
    annualLeaveMinutes
    activePayRate {
      partTime
    }
    primitiveCompRestMinuteUsages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
      workingArea {
        workplaceId
      }
    }
    payRates {
      id
      type
      rate
      startDate
      metaJson
    }
    laborInsurances {
      id
      startDate
      metaJson
    }
    healthInsurances {
      id
      startDate
      metaJson
    }
    laborPensions {
      id
      startDate
      metaJson
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useEmployeesPageWorkplaceEmployees_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.EmployeesPageWorkplaceEmployees_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EmployeesPageWorkplaceEmployees_GetEmployeesQuery, Types.EmployeesPageWorkplaceEmployees_GetEmployeesQueryVariables>({ query: EmployeesPageWorkplaceEmployees_GetEmployeesDocument, ...options });
};
export const AddEmployeeWizard_GetEmployeesDocument = gql`
    query AddEmployeeWizard_GetEmployees($businessId: ID!) {
  businessEmployees(businessId: $businessId) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    mobilePhone
  }
}
    `;

export function useAddEmployeeWizard_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.AddEmployeeWizard_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AddEmployeeWizard_GetEmployeesQuery, Types.AddEmployeeWizard_GetEmployeesQueryVariables>({ query: AddEmployeeWizard_GetEmployeesDocument, ...options });
};
export const LeaveManagementPage_GetEmployeeAnnualLeaveMinuteBalanceDocument = gql`
    query LeaveManagementPage_GetEmployeeAnnualLeaveMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeAnnualLeaveMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    annualLeaveMinuteBalance
  }
}
    `;

export function useLeaveManagementPage_GetEmployeeAnnualLeaveMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.LeaveManagementPage_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LeaveManagementPage_GetEmployeeAnnualLeaveMinuteBalanceQuery, Types.LeaveManagementPage_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>({ query: LeaveManagementPage_GetEmployeeAnnualLeaveMinuteBalanceDocument, ...options });
};
export const LeaveManagementPage_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query LeaveManagementPage_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useLeaveManagementPage_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.LeaveManagementPage_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LeaveManagementPage_GetEmployeeApprovedLeaveRequestsQuery, Types.LeaveManagementPage_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: LeaveManagementPage_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const LeaveManagementPage_GetEmployeeCompRestMinuteBalanceDocument = gql`
    query LeaveManagementPage_GetEmployeeCompRestMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeCompRestMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    compRestMinuteBalance
  }
}
    `;

export function useLeaveManagementPage_GetEmployeeCompRestMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.LeaveManagementPage_GetEmployeeCompRestMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LeaveManagementPage_GetEmployeeCompRestMinuteBalanceQuery, Types.LeaveManagementPage_GetEmployeeCompRestMinuteBalanceQueryVariables>({ query: LeaveManagementPage_GetEmployeeCompRestMinuteBalanceDocument, ...options });
};
export const LeaveManagementPage_GetBusinessEmployeeIncumbenciesDocument = gql`
    query LeaveManagementPage_GetBusinessEmployeeIncumbencies($employeeId: ID!) {
  businessEmployeeIncumbencies(employeeId: $employeeId) {
    id
    startDate
    endDate
    tenureDaysAdj
    carriedAnnualLeaveYears
  }
}
    `;

export function useLeaveManagementPage_GetBusinessEmployeeIncumbenciesQuery(options: Omit<Urql.UseQueryArgs<Types.LeaveManagementPage_GetBusinessEmployeeIncumbenciesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LeaveManagementPage_GetBusinessEmployeeIncumbenciesQuery, Types.LeaveManagementPage_GetBusinessEmployeeIncumbenciesQueryVariables>({ query: LeaveManagementPage_GetBusinessEmployeeIncumbenciesDocument, ...options });
};
export const LeaveManagementPage_GetEmployeeLeaveMinutesDocument = gql`
    query LeaveManagementPage_GetEmployeeLeaveMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeLeaveMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    annualLeaveMins
    compLeaveMins
    funeralLeaveMins
    maternityLeaveMins
    menstruationLeaveMins
    officialLeaveMins
    personalLeaveMins
    sickLeaveMins
    typhoonLeaveMins
    weddingLeaveMins
  }
}
    `;

export function useLeaveManagementPage_GetEmployeeLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.LeaveManagementPage_GetEmployeeLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LeaveManagementPage_GetEmployeeLeaveMinutesQuery, Types.LeaveManagementPage_GetEmployeeLeaveMinutesQueryVariables>({ query: LeaveManagementPage_GetEmployeeLeaveMinutesDocument, ...options });
};
export const LeaveManagementPage_GetEmployeesDocument = gql`
    query LeaveManagementPage_GetEmployees($workplaceId: ID!, $incumbent: Boolean!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    privilegedWorkingAreasOnly: true
    incumbent: $incumbent
  ) {
    id
    active
    index
    type
    name
    joiningDate
    insertedAt
    advancedAnnualLeaveMinutes
    carriedAnnualLeaveMinutesYears
    annualLeaveMinutes
    suspended
    user {
      id
      name
      avatar
      locale
    }
  }
}
    `;

export function useLeaveManagementPage_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.LeaveManagementPage_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LeaveManagementPage_GetEmployeesQuery, Types.LeaveManagementPage_GetEmployeesQueryVariables>({ query: LeaveManagementPage_GetEmployeesDocument, ...options });
};
export const LeaveManagementPage_GetLeaveRequestsDocument = gql`
    query LeaveManagementPage_GetLeaveRequests($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceLeaveRequests(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useLeaveManagementPage_GetLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.LeaveManagementPage_GetLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LeaveManagementPage_GetLeaveRequestsQuery, Types.LeaveManagementPage_GetLeaveRequestsQueryVariables>({ query: LeaveManagementPage_GetLeaveRequestsDocument, ...options });
};
export const PayrollPage_GetAttendanceCompRestMinutesDocument = gql`
    query PayrollPage_GetAttendanceCompRestMinutes($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceAttendanceCompRestMinutes(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    minutes
    status
    isPaid
    expireAt
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
  }
}
    `;

export function usePayrollPage_GetAttendanceCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetAttendanceCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetAttendanceCompRestMinutesQuery, Types.PayrollPage_GetAttendanceCompRestMinutesQueryVariables>({ query: PayrollPage_GetAttendanceCompRestMinutesDocument, ...options });
};
export const PayrollPage_GetAttendancesDocument = gql`
    query PayrollPage_GetAttendances($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceAttendances(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
    clockIn
    clockOut
    absenceMins
    lateMins
    leaveEarlyMins
    overtimeMins
    restMins
    note
    assignee {
      id
      name
    }
    hasCompRestMinute
    shift {
      id
      startTime
      endTime
      restStartTime
      restMins
      date
      assignee {
        id
      }
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function usePayrollPage_GetAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetAttendancesQuery, Types.PayrollPage_GetAttendancesQueryVariables>({ query: PayrollPage_GetAttendancesDocument, ...options });
};
export const PayrollPage_GetDayoffsDocument = gql`
    query PayrollPage_GetDayoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDayoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function usePayrollPage_GetDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetDayoffsQuery, Types.PayrollPage_GetDayoffsQueryVariables>({ query: PayrollPage_GetDayoffsDocument, ...options });
};
export const PayrollPage_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query PayrollPage_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function usePayrollPage_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeApprovedLeaveRequestsQuery, Types.PayrollPage_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: PayrollPage_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const PayrollPage_GetEmployeeAttendanceCompRestMinutesDocument = gql`
    query PayrollPage_GetEmployeeAttendanceCompRestMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendanceCompRestMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    minutes
    status
    isPaid
    expireAt
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
  }
}
    `;

export function usePayrollPage_GetEmployeeAttendanceCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeAttendanceCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeAttendanceCompRestMinutesQuery, Types.PayrollPage_GetEmployeeAttendanceCompRestMinutesQueryVariables>({ query: PayrollPage_GetEmployeeAttendanceCompRestMinutesDocument, ...options });
};
export const PayrollPage_GetEmployeeAttendancesDocument = gql`
    query PayrollPage_GetEmployeeAttendances($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeAttendances(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
    clockIn
    clockOut
    absenceMins
    lateMins
    leaveEarlyMins
    overtimeMins
    restMins
    note
    assignee {
      id
      name
    }
    hasCompRestMinute
    convertibleCompRestMins
    shift {
      id
      startTime
      endTime
      restStartTime
      restMins
      date
      assignee {
        id
      }
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function usePayrollPage_GetEmployeeAttendancesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeAttendancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeAttendancesQuery, Types.PayrollPage_GetEmployeeAttendancesQueryVariables>({ query: PayrollPage_GetEmployeeAttendancesDocument, ...options });
};
export const PayrollPage_GetEmployeeDayoffsDocument = gql`
    query PayrollPage_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function usePayrollPage_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeDayoffsQuery, Types.PayrollPage_GetEmployeeDayoffsQueryVariables>({ query: PayrollPage_GetEmployeeDayoffsDocument, ...options });
};
export const PayrollPage_GetEmployeeExpiredCompRestMinutesDocument = gql`
    query PayrollPage_GetEmployeeExpiredCompRestMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeExpiredCompRestMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    attendanceId
    minutes
    shiftDate
    expireAt
    dayoffId
  }
}
    `;

export function usePayrollPage_GetEmployeeExpiredCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeExpiredCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeExpiredCompRestMinutesQuery, Types.PayrollPage_GetEmployeeExpiredCompRestMinutesQueryVariables>({ query: PayrollPage_GetEmployeeExpiredCompRestMinutesDocument, ...options });
};
export const PayrollPage_GetEmployeeExpiredLeaveCompRestMinutesDocument = gql`
    query PayrollPage_GetEmployeeExpiredLeaveCompRestMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeExpiredLeaveCompRestMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    attendanceId
    leaveRequestId
    minutes
    shiftDate
    expireAt
    dayoffId
  }
}
    `;

export function usePayrollPage_GetEmployeeExpiredLeaveCompRestMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeExpiredLeaveCompRestMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeExpiredLeaveCompRestMinutesQuery, Types.PayrollPage_GetEmployeeExpiredLeaveCompRestMinutesQueryVariables>({ query: PayrollPage_GetEmployeeExpiredLeaveCompRestMinutesDocument, ...options });
};
export const PayrollPage_GetEmployeeHazardInsurancesDocument = gql`
    query PayrollPage_GetEmployeeHazardInsurances($employeeId: ID!) {
  businessEmployeeHazardInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function usePayrollPage_GetEmployeeHazardInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeHazardInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeHazardInsurancesQuery, Types.PayrollPage_GetEmployeeHazardInsurancesQueryVariables>({ query: PayrollPage_GetEmployeeHazardInsurancesDocument, ...options });
};
export const PayrollPage_GetEmployeeHealthInsurancesDocument = gql`
    query PayrollPage_GetEmployeeHealthInsurances($employeeId: ID!) {
  businessEmployeeHealthInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function usePayrollPage_GetEmployeeHealthInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeHealthInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeHealthInsurancesQuery, Types.PayrollPage_GetEmployeeHealthInsurancesQueryVariables>({ query: PayrollPage_GetEmployeeHealthInsurancesDocument, ...options });
};
export const PayrollPage_GetBusinessEmployeeIncumbenciesDocument = gql`
    query PayrollPage_GetBusinessEmployeeIncumbencies($employeeId: ID!) {
  businessEmployeeIncumbencies(employeeId: $employeeId) {
    id
    startDate
    endDate
    tenureDaysAdj
    carriedAnnualLeaveYears
  }
}
    `;

export function usePayrollPage_GetBusinessEmployeeIncumbenciesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetBusinessEmployeeIncumbenciesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetBusinessEmployeeIncumbenciesQuery, Types.PayrollPage_GetBusinessEmployeeIncumbenciesQueryVariables>({ query: PayrollPage_GetBusinessEmployeeIncumbenciesDocument, ...options });
};
export const PayrollPage_GetEmployeeLaborInsurancesDocument = gql`
    query PayrollPage_GetEmployeeLaborInsurances($employeeId: ID!) {
  businessEmployeeLaborInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    updatedAt
    admin {
      id
      name
    }
  }
}
    `;

export function usePayrollPage_GetEmployeeLaborInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeLaborInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeLaborInsurancesQuery, Types.PayrollPage_GetEmployeeLaborInsurancesQueryVariables>({ query: PayrollPage_GetEmployeeLaborInsurancesDocument, ...options });
};
export const PayrollPage_GetEmployeeLaborPensionsDocument = gql`
    query PayrollPage_GetEmployeeLaborPensions($employeeId: ID!) {
  businessEmployeeLaborPensions(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function usePayrollPage_GetEmployeeLaborPensionsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeLaborPensionsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeLaborPensionsQuery, Types.PayrollPage_GetEmployeeLaborPensionsQueryVariables>({ query: PayrollPage_GetEmployeeLaborPensionsDocument, ...options });
};
export const PayrollPage_GetEmployeeLeaveMinutesDocument = gql`
    query PayrollPage_GetEmployeeLeaveMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeLeaveMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    annualLeaveMins
    compLeaveMins
    funeralLeaveMins
    maternityLeaveMins
    menstruationLeaveMins
    officialLeaveMins
    personalLeaveMins
    sickLeaveMins
    typhoonLeaveMins
    weddingLeaveMins
  }
}
    `;

export function usePayrollPage_GetEmployeeLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeLeaveMinutesQuery, Types.PayrollPage_GetEmployeeLeaveMinutesQueryVariables>({ query: PayrollPage_GetEmployeeLeaveMinutesDocument, ...options });
};
export const PayrollPage_GetEmployeePayRatesDocument = gql`
    query PayrollPage_GetEmployeePayRates($employeeId: ID!) {
  businessEmployeePayRates(employeeId: $employeeId) {
    id
    type
    rate
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function usePayrollPage_GetEmployeePayRatesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeePayRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeePayRatesQuery, Types.PayrollPage_GetEmployeePayRatesQueryVariables>({ query: PayrollPage_GetEmployeePayRatesDocument, ...options });
};
export const PayrollPage_GetEmployeePendingLeaveRequestsDocument = gql`
    query PayrollPage_GetEmployeePendingLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeePendingLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function usePayrollPage_GetEmployeePendingLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeePendingLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeePendingLeaveRequestsQuery, Types.PayrollPage_GetEmployeePendingLeaveRequestsQueryVariables>({ query: PayrollPage_GetEmployeePendingLeaveRequestsDocument, ...options });
};
export const PayrollPage_GetEmployeeShiftsDocument = gql`
    query PayrollPage_GetEmployeeShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
    linkedAttendance {
      id
      status
      clockIn
      clockOut
      restMins
      overtimeMins
    }
  }
}
    `;

export function usePayrollPage_GetEmployeeShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeShiftsQuery, Types.PayrollPage_GetEmployeeShiftsQueryVariables>({ query: PayrollPage_GetEmployeeShiftsDocument, ...options });
};
export const PayrollPage_GetEmployeesDocument = gql`
    query PayrollPage_GetEmployees($businessId: ID!) {
  businessEmployees(businessId: $businessId) {
    id
    index
    name
    positions {
      id
    }
    user {
      id
      name
      avatar
    }
  }
}
    `;

export function usePayrollPage_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeesQuery, Types.PayrollPage_GetEmployeesQueryVariables>({ query: PayrollPage_GetEmployeesDocument, ...options });
};
export const PayrollPage_GetEmployeeExpiredAnnualLeaveMinutesDocument = gql`
    query PayrollPage_GetEmployeeExpiredAnnualLeaveMinutes($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeExpiredAnnualLeaveMinutes(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    expiredAnnualLeaveMinutes
  }
}
    `;

export function usePayrollPage_GetEmployeeExpiredAnnualLeaveMinutesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetEmployeeExpiredAnnualLeaveMinutesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetEmployeeExpiredAnnualLeaveMinutesQuery, Types.PayrollPage_GetEmployeeExpiredAnnualLeaveMinutesQueryVariables>({ query: PayrollPage_GetEmployeeExpiredAnnualLeaveMinutesDocument, ...options });
};
export const PayrollPage_GetShiftsDocument = gql`
    query PayrollPage_GetShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function usePayrollPage_GetShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPage_GetShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPage_GetShiftsQuery, Types.PayrollPage_GetShiftsQueryVariables>({ query: PayrollPage_GetShiftsDocument, ...options });
};
export const AllowanceWizardDialog_GetWorkplaceEmployeesDocument = gql`
    query AllowanceWizardDialog_GetWorkplaceEmployees($workplaceId: ID!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    active: false
    incumbent: true
  ) {
    id
    index
    employeeNumber
    type
    name
  }
}
    `;

export function useAllowanceWizardDialog_GetWorkplaceEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.AllowanceWizardDialog_GetWorkplaceEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AllowanceWizardDialog_GetWorkplaceEmployeesQuery, Types.AllowanceWizardDialog_GetWorkplaceEmployeesQueryVariables>({ query: AllowanceWizardDialog_GetWorkplaceEmployeesDocument, ...options });
};
export const CreatePayrollAllowanceDocument = gql`
    mutation CreatePayrollAllowance($input: CreatePayrollAllowanceInput!) {
  createPayrollAllowance(input: $input) {
    payrollAllowance {
      id
    }
  }
}
    `;

export function useCreatePayrollAllowanceMutation() {
  return Urql.useMutation<Types.CreatePayrollAllowanceMutation, Types.CreatePayrollAllowanceMutationVariables>(CreatePayrollAllowanceDocument);
};
export const DeletePayrollAllowanceDocument = gql`
    mutation DeletePayrollAllowance($input: DeletePayrollAllowanceInput!) {
  deletePayrollAllowance(input: $input) {
    payrollAllowanceId
  }
}
    `;

export function useDeletePayrollAllowanceMutation() {
  return Urql.useMutation<Types.DeletePayrollAllowanceMutation, Types.DeletePayrollAllowanceMutationVariables>(DeletePayrollAllowanceDocument);
};
export const UpdatePayrollAllowanceDocument = gql`
    mutation UpdatePayrollAllowance($input: UpdatePayrollAllowanceInput!) {
  updatePayrollAllowance(input: $input) {
    payrollAllowance {
      id
      index
      name
      allowanceJson
      workingAreas {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useUpdatePayrollAllowanceMutation() {
  return Urql.useMutation<Types.UpdatePayrollAllowanceMutation, Types.UpdatePayrollAllowanceMutationVariables>(UpdatePayrollAllowanceDocument);
};
export const PayrollPageV1_GetPayrollAllowancesDocument = gql`
    query PayrollPageV1_GetPayrollAllowances($workplaceId: ID!) {
  businessWorkplacePayrollAllowances(workplaceId: $workplaceId) {
    id
    index
    name
    allowanceJson
    workingAreas {
      id
      name
    }
    positions {
      id
      name
      color
    }
  }
}
    `;

export function usePayrollPageV1_GetPayrollAllowancesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPageV1_GetPayrollAllowancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPageV1_GetPayrollAllowancesQuery, Types.PayrollPageV1_GetPayrollAllowancesQueryVariables>({ query: PayrollPageV1_GetPayrollAllowancesDocument, ...options });
};
export const PayrollPageV1_GetPaidEmployeePayrollsDocument = gql`
    query PayrollPageV1_GetPaidEmployeePayrolls($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplacePaidEmployeePayrolls(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    paid
    audited
    payrollJson
    employee {
      id
      index
      name
      type
      user {
        id
        avatar
        name
      }
    }
  }
}
    `;

export function usePayrollPageV1_GetPaidEmployeePayrollsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPageV1_GetPaidEmployeePayrollsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPageV1_GetPaidEmployeePayrollsQuery, Types.PayrollPageV1_GetPaidEmployeePayrollsQueryVariables>({ query: PayrollPageV1_GetPaidEmployeePayrollsDocument, ...options });
};
export const PayrollPageV1_GetEmployeesDocument = gql`
    query PayrollPageV1_GetEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    privilegedWorkingAreasOnly: true
  ) {
    id
    index
    name
    type
    positions {
      id
    }
    user {
      id
      avatar
    }
    isPayrollAudited(startDate: $startDate, endDate: $endDate)
    isPayrollPaid(startDate: $startDate, endDate: $endDate)
    hasShifts(startDate: $startDate, endDate: $endDate)
  }
}
    `;

export function usePayrollPageV1_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPageV1_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPageV1_GetEmployeesQuery, Types.PayrollPageV1_GetEmployeesQueryVariables>({ query: PayrollPageV1_GetEmployeesDocument, ...options });
};
export const PayrollPageV1_GetBusinessWorkplacePayrollTemplateDocument = gql`
    query PayrollPageV1_GetBusinessWorkplacePayrollTemplate($workplaceId: ID!) {
  businessWorkplacePayrollTemplate(workplaceId: $workplaceId) {
    workplaceId
    templateJson
  }
}
    `;

export function usePayrollPageV1_GetBusinessWorkplacePayrollTemplateQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPageV1_GetBusinessWorkplacePayrollTemplateQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPageV1_GetBusinessWorkplacePayrollTemplateQuery, Types.PayrollPageV1_GetBusinessWorkplacePayrollTemplateQueryVariables>({ query: PayrollPageV1_GetBusinessWorkplacePayrollTemplateDocument, ...options });
};
export const PayrollPageV1_GetAuditedEmployeePayrollsDocument = gql`
    query PayrollPageV1_GetAuditedEmployeePayrolls($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceAuditedEmployeePayrolls(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    paid
    audited
    payrollJson
    employee {
      id
      name
      type
      user {
        id
        avatar
        name
      }
    }
  }
}
    `;

export function usePayrollPageV1_GetAuditedEmployeePayrollsQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPageV1_GetAuditedEmployeePayrollsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPageV1_GetAuditedEmployeePayrollsQuery, Types.PayrollPageV1_GetAuditedEmployeePayrollsQueryVariables>({ query: PayrollPageV1_GetAuditedEmployeePayrollsDocument, ...options });
};
export const PayrollDetail_GetEmployeeExpenseDocument = gql`
    query PayrollDetail_GetEmployeeExpense($expenseId: ID!) {
  node(id: $expenseId) {
    ... on BusinessEmployeeExpense {
      id
      updatedAt
      status
      amount
      name
      date
      note
      images {
        id
        index
        file
      }
    }
  }
}
    `;

export function usePayrollDetail_GetEmployeeExpenseQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollDetail_GetEmployeeExpenseQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollDetail_GetEmployeeExpenseQuery, Types.PayrollDetail_GetEmployeeExpenseQueryVariables>({ query: PayrollDetail_GetEmployeeExpenseDocument, ...options });
};
export const PayrollPageField_GetEmployeeAnnualIncumbenciesDocument = gql`
    query PayrollPageField_GetEmployeeAnnualIncumbencies($employeeId: ID!) {
  businessEmployeeAnnualIncumbencies(employeeId: $employeeId) {
    startDate
    endDate
    isDeparted
  }
}
    `;

export function usePayrollPageField_GetEmployeeAnnualIncumbenciesQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPageField_GetEmployeeAnnualIncumbenciesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPageField_GetEmployeeAnnualIncumbenciesQuery, Types.PayrollPageField_GetEmployeeAnnualIncumbenciesQueryVariables>({ query: PayrollPageField_GetEmployeeAnnualIncumbenciesDocument, ...options });
};
export const ResetEmployeePayrollDocument = gql`
    mutation ResetEmployeePayroll($input: ResetEmployeePayrollInput!) {
  resetEmployeePayroll(input: $input) {
    employeePayroll {
      id
      paid
      audited
      payrollJson
      employee {
        id
        name
        user {
          id
          avatar
        }
      }
    }
  }
}
    `;

export function useResetEmployeePayrollMutation() {
  return Urql.useMutation<Types.ResetEmployeePayrollMutation, Types.ResetEmployeePayrollMutationVariables>(ResetEmployeePayrollDocument);
};
export const UpdateEmployeePayrollDocument = gql`
    mutation UpdateEmployeePayroll($input: UpdateEmployeePayrollInput!) {
  updateEmployeePayroll(input: $input) {
    employeePayroll {
      id
      paid
      audited
      payrollJson
      employee {
        id
        name
        user {
          id
          avatar
        }
      }
    }
  }
}
    `;

export function useUpdateEmployeePayrollMutation() {
  return Urql.useMutation<Types.UpdateEmployeePayrollMutation, Types.UpdateEmployeePayrollMutationVariables>(UpdateEmployeePayrollDocument);
};
export const UpdateEmployeePayrollDeriveValuesDocument = gql`
    mutation UpdateEmployeePayrollDeriveValues($input: UpdateEmployeePayrollDeriveValuesInput!) {
  updateEmployeePayrollDeriveValues(input: $input) {
    employeePayroll {
      id
    }
  }
}
    `;

export function useUpdateEmployeePayrollDeriveValuesMutation() {
  return Urql.useMutation<Types.UpdateEmployeePayrollDeriveValuesMutation, Types.UpdateEmployeePayrollDeriveValuesMutationVariables>(UpdateEmployeePayrollDeriveValuesDocument);
};
export const PayrollPageV1_GetEmployeePayrollDocument = gql`
    query PayrollPageV1_GetEmployeePayroll($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeePayroll(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    __typename
    ... on BusinessEmployeePayroll {
      id
      startDate
      endDate
      paid
      audited
      payrollJson
      employee {
        id
        name
        type
        user {
          id
          avatar
        }
      }
    }
    ... on BusinessEmployeePayrollError {
      message
      employee {
        id
        name
        type
        user {
          id
          avatar
        }
      }
    }
  }
}
    `;

export function usePayrollPageV1_GetEmployeePayrollQuery(options: Omit<Urql.UseQueryArgs<Types.PayrollPageV1_GetEmployeePayrollQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PayrollPageV1_GetEmployeePayrollQuery, Types.PayrollPageV1_GetEmployeePayrollQueryVariables>({ query: PayrollPageV1_GetEmployeePayrollDocument, ...options });
};
export const GetBusinessReferralCreditsDocument = gql`
    query GetBusinessReferralCredits($businessId: ID!) {
  node(id: $businessId) {
    ... on Business {
      id
      referralCredits {
        id
        insertedAt
        updatedAt
        issueInvoiceId
        redeemInvoiceId
        amount
        referredBusinessName
      }
    }
  }
}
    `;

export function useGetBusinessReferralCreditsQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessReferralCreditsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessReferralCreditsQuery, Types.GetBusinessReferralCreditsQueryVariables>({ query: GetBusinessReferralCreditsDocument, ...options });
};
export const GetBusinessReferralCodeDocument = gql`
    query GetBusinessReferralCode($businessId: ID!) {
  node(id: $businessId) {
    ... on Business {
      id
      referralCode
    }
  }
}
    `;

export function useGetBusinessReferralCodeQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessReferralCodeQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessReferralCodeQuery, Types.GetBusinessReferralCodeQueryVariables>({ query: GetBusinessReferralCodeDocument, ...options });
};
export const GetBusinessReferralCreditsBalanceDocument = gql`
    query GetBusinessReferralCreditsBalance($businessId: ID!) {
  node(id: $businessId) {
    ... on Business {
      id
      referralCreditsBalance
    }
  }
}
    `;

export function useGetBusinessReferralCreditsBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessReferralCreditsBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessReferralCreditsBalanceQuery, Types.GetBusinessReferralCreditsBalanceQueryVariables>({ query: GetBusinessReferralCreditsBalanceDocument, ...options });
};
export const GetBusinessReferralCreditsTotalDocument = gql`
    query GetBusinessReferralCreditsTotal($businessId: ID!) {
  node(id: $businessId) {
    ... on Business {
      id
      referralCreditsTotal
    }
  }
}
    `;

export function useGetBusinessReferralCreditsTotalQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessReferralCreditsTotalQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessReferralCreditsTotalQuery, Types.GetBusinessReferralCreditsTotalQueryVariables>({ query: GetBusinessReferralCreditsTotalDocument, ...options });
};
export const GetBusinessReferralCreditsUsedDocument = gql`
    query GetBusinessReferralCreditsUsed($businessId: ID!) {
  node(id: $businessId) {
    ... on Business {
      id
      referralCreditsUsed
    }
  }
}
    `;

export function useGetBusinessReferralCreditsUsedQuery(options: Omit<Urql.UseQueryArgs<Types.GetBusinessReferralCreditsUsedQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetBusinessReferralCreditsUsedQuery, Types.GetBusinessReferralCreditsUsedQueryVariables>({ query: GetBusinessReferralCreditsUsedDocument, ...options });
};
export const DailyReportsPage_GetBusinessWorkplacesDocument = gql`
    query DailyReportsPage_GetBusinessWorkplaces($businessId: ID!) {
  businessWorkplaces(businessId: $businessId) {
    id
    index
    active
    name
  }
}
    `;

export function useDailyReportsPage_GetBusinessWorkplacesQuery(options: Omit<Urql.UseQueryArgs<Types.DailyReportsPage_GetBusinessWorkplacesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DailyReportsPage_GetBusinessWorkplacesQuery, Types.DailyReportsPage_GetBusinessWorkplacesQueryVariables>({ query: DailyReportsPage_GetBusinessWorkplacesDocument, ...options });
};
export const DailyReportsPageHeader_LaborCostDocument = gql`
    query DailyReportsPageHeader_LaborCost($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceLaborCost(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

export function useDailyReportsPageHeader_LaborCostQuery(options: Omit<Urql.UseQueryArgs<Types.DailyReportsPageHeader_LaborCostQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DailyReportsPageHeader_LaborCostQuery, Types.DailyReportsPageHeader_LaborCostQueryVariables>({ query: DailyReportsPageHeader_LaborCostDocument, ...options });
};
export const DailyReportTemplatesPage_SetBusinessWorkplaceDailyReportTemplateDocument = gql`
    mutation DailyReportTemplatesPage_SetBusinessWorkplaceDailyReportTemplate($input: SetDailyReportTemplateInput!) {
  setDailyReportTemplate(input: $input) {
    dailyReportTemplate {
      templateJson
    }
  }
}
    `;

export function useDailyReportTemplatesPage_SetBusinessWorkplaceDailyReportTemplateMutation() {
  return Urql.useMutation<Types.DailyReportTemplatesPage_SetBusinessWorkplaceDailyReportTemplateMutation, Types.DailyReportTemplatesPage_SetBusinessWorkplaceDailyReportTemplateMutationVariables>(DailyReportTemplatesPage_SetBusinessWorkplaceDailyReportTemplateDocument);
};
export const DailyReportTemplatesPage_GetBusinessWorkplaceDailyReportTemplateDocument = gql`
    query DailyReportTemplatesPage_GetBusinessWorkplaceDailyReportTemplate($workplaceId: ID!) {
  businessWorkplaceDailyReportTemplate(workplaceId: $workplaceId) {
    templateJson
    images {
      id
      index
      file
    }
  }
}
    `;

export function useDailyReportTemplatesPage_GetBusinessWorkplaceDailyReportTemplateQuery(options: Omit<Urql.UseQueryArgs<Types.DailyReportTemplatesPage_GetBusinessWorkplaceDailyReportTemplateQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DailyReportTemplatesPage_GetBusinessWorkplaceDailyReportTemplateQuery, Types.DailyReportTemplatesPage_GetBusinessWorkplaceDailyReportTemplateQueryVariables>({ query: DailyReportTemplatesPage_GetBusinessWorkplaceDailyReportTemplateDocument, ...options });
};
export const DailyReportsPage_SetBusinessWorkplaceDailyReportDocument = gql`
    mutation DailyReportsPage_SetBusinessWorkplaceDailyReport($input: SetDailyReportInput!) {
  setDailyReport(input: $input) {
    dailyReport {
      date
      revenue
      customers
      metaJson
      note
      regularMinutes
      overtimeMinutes
      eventLogs {
        type
        employee {
          id
          name
          user {
            id
            avatar
          }
        }
        insertedAt
      }
    }
  }
}
    `;

export function useDailyReportsPage_SetBusinessWorkplaceDailyReportMutation() {
  return Urql.useMutation<Types.DailyReportsPage_SetBusinessWorkplaceDailyReportMutation, Types.DailyReportsPage_SetBusinessWorkplaceDailyReportMutationVariables>(DailyReportsPage_SetBusinessWorkplaceDailyReportDocument);
};
export const DailyReportsPage_GetBusinessWorkplaceDailyReportsDocument = gql`
    query DailyReportsPage_GetBusinessWorkplaceDailyReports($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDailyReports(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    date
    isRestDay
    revenue
    margin
    customers
    metaJson
    note
    starred
    actualHours
    overtimeHours
    images {
      id
      index
      file
    }
    eventLogs {
      type
      employee {
        id
        name
        user {
          id
          avatar
        }
      }
      insertedAt
    }
  }
}
    `;

export function useDailyReportsPage_GetBusinessWorkplaceDailyReportsQuery(options: Omit<Urql.UseQueryArgs<Types.DailyReportsPage_GetBusinessWorkplaceDailyReportsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.DailyReportsPage_GetBusinessWorkplaceDailyReportsQuery, Types.DailyReportsPage_GetBusinessWorkplaceDailyReportsQueryVariables>({ query: DailyReportsPage_GetBusinessWorkplaceDailyReportsDocument, ...options });
};
export const MonthlyReportsPage_MonthlyReportSummaryDocument = gql`
    query MonthlyReportsPage_MonthlyReportSummary($businessId: ID!, $startDate: String!, $endDate: String!) {
  businessMonthlyReportSummary(
    businessId: $businessId
    startDate: $startDate
    endDate: $endDate
  ) {
    startDate
    endDate
    shiftCount
    timeSaving
    costSaving
    auditPayrollCount
    clockInCount
    approvedLeaveCount
  }
}
    `;

export function useMonthlyReportsPage_MonthlyReportSummaryQuery(options: Omit<Urql.UseQueryArgs<Types.MonthlyReportsPage_MonthlyReportSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.MonthlyReportsPage_MonthlyReportSummaryQuery, Types.MonthlyReportsPage_MonthlyReportSummaryQueryVariables>({ query: MonthlyReportsPage_MonthlyReportSummaryDocument, ...options });
};
export const MonthlyReportsPage_MonthlyReportEmployeeLeaderboardsDocument = gql`
    query MonthlyReportsPage_MonthlyReportEmployeeLeaderboards($businessId: ID!, $startDate: String!, $endDate: String!) {
  businessMonthlyReportEmployeeLeaderboards(
    businessId: $businessId
    startDate: $startDate
    endDate: $endDate
  ) {
    attendanceHours {
      name
      value
    }
    overtimeHours {
      name
      value
    }
    leaveCounts {
      name
      value
    }
    missedClockInOrOutCounts {
      name
      value
    }
    lateCounts {
      name
      value
    }
  }
}
    `;

export function useMonthlyReportsPage_MonthlyReportEmployeeLeaderboardsQuery(options: Omit<Urql.UseQueryArgs<Types.MonthlyReportsPage_MonthlyReportEmployeeLeaderboardsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.MonthlyReportsPage_MonthlyReportEmployeeLeaderboardsQuery, Types.MonthlyReportsPage_MonthlyReportEmployeeLeaderboardsQueryVariables>({ query: MonthlyReportsPage_MonthlyReportEmployeeLeaderboardsDocument, ...options });
};
export const MonthlyReportsPage_MonthlyReportWorkplaceLeaderboardsDocument = gql`
    query MonthlyReportsPage_MonthlyReportWorkplaceLeaderboards($businessId: ID!, $startDate: String!, $endDate: String!) {
  businessMonthlyReportWorkplaceLeaderboards(
    businessId: $businessId
    startDate: $startDate
    endDate: $endDate
  ) {
    laborCosts {
      name
      value
    }
    attendanceOntimeRates {
      name
      value
    }
    leaveCounts {
      name
      value
    }
  }
}
    `;

export function useMonthlyReportsPage_MonthlyReportWorkplaceLeaderboardsQuery(options: Omit<Urql.UseQueryArgs<Types.MonthlyReportsPage_MonthlyReportWorkplaceLeaderboardsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.MonthlyReportsPage_MonthlyReportWorkplaceLeaderboardsQuery, Types.MonthlyReportsPage_MonthlyReportWorkplaceLeaderboardsQueryVariables>({ query: MonthlyReportsPage_MonthlyReportWorkplaceLeaderboardsDocument, ...options });
};
export const ResellerCheckoutPage_GetResellerInvoiceDocument = gql`
    query ResellerCheckoutPage_GetResellerInvoice($invoiceId: String!, $reseller: String!) {
  resellerInvoice(invoiceId: $invoiceId, reseller: $reseller) {
    id
    issueDate
    status
    detailsJson
    business {
      id
      name
      billingInfo {
        taxId
      }
    }
    reseller {
      id
      name
    }
  }
}
    `;

export function useResellerCheckoutPage_GetResellerInvoiceQuery(options: Omit<Urql.UseQueryArgs<Types.ResellerCheckoutPage_GetResellerInvoiceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.ResellerCheckoutPage_GetResellerInvoiceQuery, Types.ResellerCheckoutPage_GetResellerInvoiceQueryVariables>({ query: ResellerCheckoutPage_GetResellerInvoiceDocument, ...options });
};
export const CreateDayoffV0Document = gql`
    mutation CreateDayoffV0($input: CreateDayoffInput!) {
  createDayoff(input: $input) {
    dayoff {
      id
      deleted
      updatedAt
      type
      date
      assignee {
        id
        name
        user {
          id
          name
        }
      }
    }
  }
}
    `;

export function useCreateDayoffV0Mutation() {
  return Urql.useMutation<Types.CreateDayoffV0Mutation, Types.CreateDayoffV0MutationVariables>(CreateDayoffV0Document);
};
export const DeleteDayoffV0Document = gql`
    mutation DeleteDayoffV0($input: DeleteDayoffInput!) {
  deleteDayoff(input: $input) {
    dayoffId
  }
}
    `;

export function useDeleteDayoffV0Mutation() {
  return Urql.useMutation<Types.DeleteDayoffV0Mutation, Types.DeleteDayoffV0MutationVariables>(DeleteDayoffV0Document);
};
export const UpdateDayoffV0Document = gql`
    mutation UpdateDayoffV0($input: UpdateDayoffInput!) {
  updateDayoff(input: $input) {
    dayoff {
      id
      deleted
      updatedAt
      type
      date
      assignee {
        id
        name
        user {
          id
          name
        }
      }
    }
  }
}
    `;

export function useUpdateDayoffV0Mutation() {
  return Urql.useMutation<Types.UpdateDayoffV0Mutation, Types.UpdateDayoffV0MutationVariables>(UpdateDayoffV0Document);
};
export const SchedulePageV0_GetDayoffsDocument = gql`
    query SchedulePageV0_GetDayoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDayoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useSchedulePageV0_GetDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetDayoffsQuery, Types.SchedulePageV0_GetDayoffsQueryVariables>({ query: SchedulePageV0_GetDayoffsDocument, ...options });
};
export const CreateDraftShiftV0Document = gql`
    mutation CreateDraftShiftV0($input: CreateDraftShiftInput!) {
  createDraftShift(input: $input) {
    draftShift {
      id
      note
      shiftId
      startTime
      endTime
      restStartTime
      restMins
      date
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        name
      }
    }
  }
}
    `;

export function useCreateDraftShiftV0Mutation() {
  return Urql.useMutation<Types.CreateDraftShiftV0Mutation, Types.CreateDraftShiftV0MutationVariables>(CreateDraftShiftV0Document);
};
export const DeleteDraftShiftV0Document = gql`
    mutation DeleteDraftShiftV0($input: DeleteDraftShiftInput!) {
  deleteDraftShift(input: $input) {
    draftShiftId
  }
}
    `;

export function useDeleteDraftShiftV0Mutation() {
  return Urql.useMutation<Types.DeleteDraftShiftV0Mutation, Types.DeleteDraftShiftV0MutationVariables>(DeleteDraftShiftV0Document);
};
export const PublishDraftShiftsV0Document = gql`
    mutation PublishDraftShiftsV0($input: PublishDraftShiftsInput!) {
  publishDraftShifts(input: $input) {
    shifts {
      id
      note
      startTime
      endTime
      restStartTime
      restMins
      date
      opened
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        name
        user {
          id
          name
        }
        positions {
          id
          name
          color
        }
        payRates {
          id
          type
          rate
          startDate
        }
      }
      auditLogs {
        dmlType
        dmlTimestamp
        oldShift {
          date
          startTime
          endTime
          position {
            id
            name
            color
          }
        }
        newShift {
          date
          startTime
          endTime
          position {
            id
            name
            color
          }
        }
      }
      eventLogs {
        type
        employee {
          id
          name
        }
        insertedAt
      }
    }
  }
}
    `;

export function usePublishDraftShiftsV0Mutation() {
  return Urql.useMutation<Types.PublishDraftShiftsV0Mutation, Types.PublishDraftShiftsV0MutationVariables>(PublishDraftShiftsV0Document);
};
export const UpdateDraftShiftV0Document = gql`
    mutation UpdateDraftShiftV0($input: UpdateDraftShiftInput!) {
  updateDraftShift(input: $input) {
    draftShift {
      id
      note
      shiftId
      startTime
      endTime
      restStartTime
      restMins
      date
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        name
      }
    }
  }
}
    `;

export function useUpdateDraftShiftV0Mutation() {
  return Urql.useMutation<Types.UpdateDraftShiftV0Mutation, Types.UpdateDraftShiftV0MutationVariables>(UpdateDraftShiftV0Document);
};
export const SchedulePageV0_GetDraftShiftsDocument = gql`
    query SchedulePageV0_GetDraftShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDraftShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    shiftId
    startTime
    endTime
    restStartTime
    restMins
    date
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePageV0_GetDraftShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetDraftShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetDraftShiftsQuery, Types.SchedulePageV0_GetDraftShiftsQueryVariables>({ query: SchedulePageV0_GetDraftShiftsDocument, ...options });
};
export const SchedulePageV0_GetEmployeeAnnualLeaveMinuteBalanceDocument = gql`
    query SchedulePageV0_GetEmployeeAnnualLeaveMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeAnnualLeaveMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    annualLeaveMinuteBalance
  }
}
    `;

export function useSchedulePageV0_GetEmployeeAnnualLeaveMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetEmployeeAnnualLeaveMinuteBalanceQuery, Types.SchedulePageV0_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>({ query: SchedulePageV0_GetEmployeeAnnualLeaveMinuteBalanceDocument, ...options });
};
export const SchedulePageV0_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query SchedulePageV0_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePageV0_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetEmployeeApprovedLeaveRequestsQuery, Types.SchedulePageV0_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: SchedulePageV0_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const SchedulePageV0_GetEmployeeCompRestMinuteBalanceDocument = gql`
    query SchedulePageV0_GetEmployeeCompRestMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeCompRestMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    compRestMinuteBalance
  }
}
    `;

export function useSchedulePageV0_GetEmployeeCompRestMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetEmployeeCompRestMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetEmployeeCompRestMinuteBalanceQuery, Types.SchedulePageV0_GetEmployeeCompRestMinuteBalanceQueryVariables>({ query: SchedulePageV0_GetEmployeeCompRestMinuteBalanceDocument, ...options });
};
export const SchedulePageV0_GetEmployeesDocument = gql`
    query SchedulePageV0_GetEmployees($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    active: true
    privilegedWorkingAreasOnly: true
  ) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    mobilePhone
    insertedAt
    activePayRate {
      partTime
    }
    advancedAnnualLeaveMinutes
    carriedAnnualLeaveMinutesYears
    annualLeaveMinutes
    primitiveCompRestMinuteUsages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
    suspended
    user {
      id
      name
      avatar
      locale
    }
    positions {
      id
      name
      color
    }
    payRates {
      id
      type
      rate
      startDate
      metaJson
    }
    laborInsurances {
      id
      startDate
      metaJson
    }
    healthInsurances {
      id
      startDate
      metaJson
    }
    laborPensions {
      id
      startDate
      metaJson
    }
    employeeHolidays(startDate: $startDate, endDate: $endDate) {
      employeeId
      holidayId
      name
      date
      public
    }
  }
}
    `;

export function useSchedulePageV0_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetEmployeesQuery, Types.SchedulePageV0_GetEmployeesQueryVariables>({ query: SchedulePageV0_GetEmployeesDocument, ...options });
};
export const SchedulePageV0_GetLeaveRequestsDocument = gql`
    query SchedulePageV0_GetLeaveRequests($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceLeaveRequests(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
  }
}
    `;

export function useSchedulePageV0_GetLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetLeaveRequestsQuery, Types.SchedulePageV0_GetLeaveRequestsQueryVariables>({ query: SchedulePageV0_GetLeaveRequestsDocument, ...options });
};
export const SchedulePageV0_GetOffsiteDraftShiftsDocument = gql`
    query SchedulePageV0_GetOffsiteDraftShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceOffsiteDraftShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      type
      name
      workplace {
        id
        name
      }
      user {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePageV0_GetOffsiteDraftShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetOffsiteDraftShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetOffsiteDraftShiftsQuery, Types.SchedulePageV0_GetOffsiteDraftShiftsQueryVariables>({ query: SchedulePageV0_GetOffsiteDraftShiftsDocument, ...options });
};
export const SchedulePageV0_GetOffsiteShiftsDocument = gql`
    query SchedulePageV0_GetOffsiteShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceOffsiteShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      type
      name
      workplace {
        id
        name
      }
      user {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePageV0_GetOffsiteShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetOffsiteShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetOffsiteShiftsQuery, Types.SchedulePageV0_GetOffsiteShiftsQueryVariables>({ query: SchedulePageV0_GetOffsiteShiftsDocument, ...options });
};
export const SetScheduleNoteV0Document = gql`
    mutation SetScheduleNoteV0($input: SetScheduleNoteInput!) {
  setScheduleNote(input: $input) {
    scheduleNote {
      date
      note
    }
  }
}
    `;

export function useSetScheduleNoteV0Mutation() {
  return Urql.useMutation<Types.SetScheduleNoteV0Mutation, Types.SetScheduleNoteV0MutationVariables>(SetScheduleNoteV0Document);
};
export const SchedulePageV0_GetScheduleNotesDocument = gql`
    query SchedulePageV0_GetScheduleNotes($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceScheduleNotes(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    date
    note
  }
}
    `;

export function useSchedulePageV0_GetScheduleNotesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetScheduleNotesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetScheduleNotesQuery, Types.SchedulePageV0_GetScheduleNotesQueryVariables>({ query: SchedulePageV0_GetScheduleNotesDocument, ...options });
};
export const SchedulePageV0_GetScheduleTimeoffsDocument = gql`
    query SchedulePageV0_GetScheduleTimeoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceScheduleTimeoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    index
    deleted
    updatedAt
    note
    startTime
    endTime
    date
    employee {
      id
      index
      name
      user {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePageV0_GetScheduleTimeoffsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetScheduleTimeoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetScheduleTimeoffsQuery, Types.SchedulePageV0_GetScheduleTimeoffsQueryVariables>({ query: SchedulePageV0_GetScheduleTimeoffsDocument, ...options });
};
export const DeleteShiftV0Document = gql`
    mutation DeleteShiftV0($input: DeleteShiftInput!) {
  deleteShift(input: $input) {
    shiftId
  }
}
    `;

export function useDeleteShiftV0Mutation() {
  return Urql.useMutation<Types.DeleteShiftV0Mutation, Types.DeleteShiftV0MutationVariables>(DeleteShiftV0Document);
};
export const SchedulePageV0_GetShiftsDocument = gql`
    query SchedulePageV0_GetShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceShiftsWithActiveAssignee(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePageV0_GetShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageV0_GetShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageV0_GetShiftsQuery, Types.SchedulePageV0_GetShiftsQueryVariables>({ query: SchedulePageV0_GetShiftsDocument, ...options });
};
export const SchedulePageCreateShiftDialog_GetBusinessWorkplacesDocument = gql`
    query SchedulePageCreateShiftDialog_GetBusinessWorkplaces($businessId: ID!) {
  businessWorkplaces(businessId: $businessId) {
    id
    index
    active
    name
    workingAreas {
      id
      workplaceId
      archived
      deleted
      updatedAt
      name
      positions {
        id
        archived
        deleted
        updatedAt
        name
        color
        workingAreaId
      }
    }
  }
}
    `;

export function useSchedulePageCreateShiftDialog_GetBusinessWorkplacesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageCreateShiftDialog_GetBusinessWorkplacesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageCreateShiftDialog_GetBusinessWorkplacesQuery, Types.SchedulePageCreateShiftDialog_GetBusinessWorkplacesQueryVariables>({ query: SchedulePageCreateShiftDialog_GetBusinessWorkplacesDocument, ...options });
};
export const SchedulePageEditShiftDialog_GetBusinessWorkplacesDocument = gql`
    query SchedulePageEditShiftDialog_GetBusinessWorkplaces($businessId: ID!) {
  businessWorkplaces(businessId: $businessId) {
    id
    index
    active
    name
    workingAreas {
      id
      workplaceId
      archived
      deleted
      updatedAt
      name
      positions {
        id
        archived
        deleted
        updatedAt
        name
        color
        workingAreaId
      }
    }
  }
}
    `;

export function useSchedulePageEditShiftDialog_GetBusinessWorkplacesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePageEditShiftDialog_GetBusinessWorkplacesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePageEditShiftDialog_GetBusinessWorkplacesQuery, Types.SchedulePageEditShiftDialog_GetBusinessWorkplacesQueryVariables>({ query: SchedulePageEditShiftDialog_GetBusinessWorkplacesDocument, ...options });
};
export const CreateDayoffDocument = gql`
    mutation CreateDayoff($input: CreateDayoffInput!) {
  createDayoff(input: $input) {
    dayoff {
      id
      deleted
      updatedAt
      type
      date
      assignee {
        id
        name
        user {
          id
          name
        }
      }
    }
  }
}
    `;

export function useCreateDayoffMutation() {
  return Urql.useMutation<Types.CreateDayoffMutation, Types.CreateDayoffMutationVariables>(CreateDayoffDocument);
};
export const DeleteDayoffDocument = gql`
    mutation DeleteDayoff($input: DeleteDayoffInput!) {
  deleteDayoff(input: $input) {
    dayoffId
  }
}
    `;

export function useDeleteDayoffMutation() {
  return Urql.useMutation<Types.DeleteDayoffMutation, Types.DeleteDayoffMutationVariables>(DeleteDayoffDocument);
};
export const UpdateDayoffDocument = gql`
    mutation UpdateDayoff($input: UpdateDayoffInput!) {
  updateDayoff(input: $input) {
    dayoff {
      id
      deleted
      updatedAt
      type
      date
      assignee {
        id
        name
        user {
          id
          name
        }
      }
    }
  }
}
    `;

export function useUpdateDayoffMutation() {
  return Urql.useMutation<Types.UpdateDayoffMutation, Types.UpdateDayoffMutationVariables>(UpdateDayoffDocument);
};
export const SchedulePage_GetDayoffsDocument = gql`
    query SchedulePage_GetDayoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDayoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useSchedulePage_GetDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetDayoffsQuery, Types.SchedulePage_GetDayoffsQueryVariables>({ query: SchedulePage_GetDayoffsDocument, ...options });
};
export const CreateDraftShiftDocument = gql`
    mutation CreateDraftShift($input: CreateDraftShiftInput!) {
  createDraftShift(input: $input) {
    draftShift {
      id
      note
      shiftId
      startTime
      endTime
      restStartTime
      restMins
      isQualified
      date
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        name
      }
    }
  }
}
    `;

export function useCreateDraftShiftMutation() {
  return Urql.useMutation<Types.CreateDraftShiftMutation, Types.CreateDraftShiftMutationVariables>(CreateDraftShiftDocument);
};
export const DeleteDraftShiftDocument = gql`
    mutation DeleteDraftShift($input: DeleteDraftShiftInput!) {
  deleteDraftShift(input: $input) {
    draftShiftId
  }
}
    `;

export function useDeleteDraftShiftMutation() {
  return Urql.useMutation<Types.DeleteDraftShiftMutation, Types.DeleteDraftShiftMutationVariables>(DeleteDraftShiftDocument);
};
export const PublishDraftShiftsDocument = gql`
    mutation PublishDraftShifts($input: PublishDraftShiftsInput!) {
  publishDraftShifts(input: $input) {
    shifts {
      id
      note
      startTime
      endTime
      restStartTime
      restMins
      date
      opened
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        name
        user {
          id
          name
        }
        positions {
          id
          name
          color
        }
      }
    }
  }
}
    `;

export function usePublishDraftShiftsMutation() {
  return Urql.useMutation<Types.PublishDraftShiftsMutation, Types.PublishDraftShiftsMutationVariables>(PublishDraftShiftsDocument);
};
export const UpdateDraftShiftDocument = gql`
    mutation UpdateDraftShift($input: UpdateDraftShiftInput!) {
  updateDraftShift(input: $input) {
    draftShift {
      id
      note
      shiftId
      startTime
      endTime
      restStartTime
      restMins
      date
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        name
      }
    }
  }
}
    `;

export function useUpdateDraftShiftMutation() {
  return Urql.useMutation<Types.UpdateDraftShiftMutation, Types.UpdateDraftShiftMutationVariables>(UpdateDraftShiftDocument);
};
export const SchedulePage_GetDraftShiftsDocument = gql`
    query SchedulePage_GetDraftShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceDraftShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    shiftId
    startTime
    endTime
    restStartTime
    restMins
    date
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePage_GetDraftShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetDraftShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetDraftShiftsQuery, Types.SchedulePage_GetDraftShiftsQueryVariables>({ query: SchedulePage_GetDraftShiftsDocument, ...options });
};
export const SchedulePage_GetEmployeeDocument = gql`
    query SchedulePage_GetEmployee($id: ID!) {
  node(id: $id) {
    ... on BusinessEmployee {
      id
      employeeNumber
      type
      name
      tags
      user {
        id
        name
        avatar
        locale
      }
      positions {
        id
        name
        color
      }
      workplace {
        id
        name
      }
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeQuery, Types.SchedulePage_GetEmployeeQueryVariables>({ query: SchedulePage_GetEmployeeDocument, ...options });
};
export const SchedulePage_GetEmployeeAnnualLeaveMinuteBalanceDocument = gql`
    query SchedulePage_GetEmployeeAnnualLeaveMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeAnnualLeaveMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    annualLeaveMinuteBalance
  }
}
    `;

export function useSchedulePage_GetEmployeeAnnualLeaveMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeAnnualLeaveMinuteBalanceQuery, Types.SchedulePage_GetEmployeeAnnualLeaveMinuteBalanceQueryVariables>({ query: SchedulePage_GetEmployeeAnnualLeaveMinuteBalanceDocument, ...options });
};
export const SchedulePage_GetEmployeeApprovedLeaveRequestsDocument = gql`
    query SchedulePage_GetEmployeeApprovedLeaveRequests($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeApprovedLeaveRequests(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
    linkedShifts {
      id
      date
      startTime
      endTime
      position {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeApprovedLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeApprovedLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeApprovedLeaveRequestsQuery, Types.SchedulePage_GetEmployeeApprovedLeaveRequestsQueryVariables>({ query: SchedulePage_GetEmployeeApprovedLeaveRequestsDocument, ...options });
};
export const SchedulePage_GetEmployeeCompRestMinuteBalanceDocument = gql`
    query SchedulePage_GetEmployeeCompRestMinuteBalance($employeeId: ID!, $date: String) {
  businessEmployeeCompRestMinuteBalance(employeeId: $employeeId, date: $date) {
    id
    compRestMinuteBalance
  }
}
    `;

export function useSchedulePage_GetEmployeeCompRestMinuteBalanceQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeCompRestMinuteBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeCompRestMinuteBalanceQuery, Types.SchedulePage_GetEmployeeCompRestMinuteBalanceQueryVariables>({ query: SchedulePage_GetEmployeeCompRestMinuteBalanceDocument, ...options });
};
export const SchedulePage_GetEmployeeDayoffsDocument = gql`
    query SchedulePage_GetEmployeeDayoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDayoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    type
    date
    assignee {
      id
      name
      user {
        id
        name
      }
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeDayoffsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeDayoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeDayoffsQuery, Types.SchedulePage_GetEmployeeDayoffsQueryVariables>({ query: SchedulePage_GetEmployeeDayoffsDocument, ...options });
};
export const SchedulePage_GetEmployeeDraftShiftsDocument = gql`
    query SchedulePage_GetEmployeeDraftShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeDraftShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    shiftId
    startTime
    endTime
    restStartTime
    restMins
    date
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeDraftShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeDraftShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeDraftShiftsQuery, Types.SchedulePage_GetEmployeeDraftShiftsQueryVariables>({ query: SchedulePage_GetEmployeeDraftShiftsDocument, ...options });
};
export const SchedulePage_GetEmployeeHazardInsurancesDocument = gql`
    query SchedulePage_GetEmployeeHazardInsurances($employeeId: ID!) {
  businessEmployeeHazardInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useSchedulePage_GetEmployeeHazardInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeHazardInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeHazardInsurancesQuery, Types.SchedulePage_GetEmployeeHazardInsurancesQueryVariables>({ query: SchedulePage_GetEmployeeHazardInsurancesDocument, ...options });
};
export const SchedulePage_GetEmployeeHealthInsurancesDocument = gql`
    query SchedulePage_GetEmployeeHealthInsurances($employeeId: ID!) {
  businessEmployeeHealthInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useSchedulePage_GetEmployeeHealthInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeHealthInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeHealthInsurancesQuery, Types.SchedulePage_GetEmployeeHealthInsurancesQueryVariables>({ query: SchedulePage_GetEmployeeHealthInsurancesDocument, ...options });
};
export const SetEmployeeHolidayDocument = gql`
    mutation SetEmployeeHoliday($input: SetEmployeeHolidayInput!) {
  setEmployeeHoliday(input: $input) {
    employeeHoliday {
      employeeId
      holidayId
      date
      public
    }
  }
}
    `;

export function useSetEmployeeHolidayMutation() {
  return Urql.useMutation<Types.SetEmployeeHolidayMutation, Types.SetEmployeeHolidayMutationVariables>(SetEmployeeHolidayDocument);
};
export const SchedulePage_GetEmployeeHolidaysDocument = gql`
    query SchedulePage_GetEmployeeHolidays($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeHolidays(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    holidayId
    name
    date
    public
    updatedAt
  }
}
    `;

export function useSchedulePage_GetEmployeeHolidaysQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeHolidaysQuery, Types.SchedulePage_GetEmployeeHolidaysQueryVariables>({ query: SchedulePage_GetEmployeeHolidaysDocument, ...options });
};
export const SchedulePage_GetEmployeeLaborInsurancesDocument = gql`
    query SchedulePage_GetEmployeeLaborInsurances($employeeId: ID!) {
  businessEmployeeLaborInsurances(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    updatedAt
    admin {
      id
      name
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeLaborInsurancesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeLaborInsurancesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeLaborInsurancesQuery, Types.SchedulePage_GetEmployeeLaborInsurancesQueryVariables>({ query: SchedulePage_GetEmployeeLaborInsurancesDocument, ...options });
};
export const SchedulePage_GetEmployeeLaborPensionsDocument = gql`
    query SchedulePage_GetEmployeeLaborPensions($employeeId: ID!) {
  businessEmployeeLaborPensions(employeeId: $employeeId) {
    id
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useSchedulePage_GetEmployeeLaborPensionsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeLaborPensionsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeLaborPensionsQuery, Types.SchedulePage_GetEmployeeLaborPensionsQueryVariables>({ query: SchedulePage_GetEmployeeLaborPensionsDocument, ...options });
};
export const SchedulePage_GetEmployeeMonthlyScheduleStatsDocument = gql`
    query SchedulePage_GetEmployeeMonthlyScheduleStats($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeScheduleStats(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    cycleStartDate
    cycleEndDate
    regularHours
    overtimeHours
    laborLawViolations {
      type
      description
      startDate
      endDate
      count
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeMonthlyScheduleStatsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeMonthlyScheduleStatsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeMonthlyScheduleStatsQuery, Types.SchedulePage_GetEmployeeMonthlyScheduleStatsQueryVariables>({ query: SchedulePage_GetEmployeeMonthlyScheduleStatsDocument, ...options });
};
export const SchedulePage_GetEmployeeOffsiteDraftShiftsDocument = gql`
    query SchedulePage_GetEmployeeOffsiteDraftShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeOffsiteDraftShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    shiftId
    startTime
    endTime
    restStartTime
    restMins
    date
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeOffsiteDraftShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeOffsiteDraftShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeOffsiteDraftShiftsQuery, Types.SchedulePage_GetEmployeeOffsiteDraftShiftsQueryVariables>({ query: SchedulePage_GetEmployeeOffsiteDraftShiftsDocument, ...options });
};
export const SchedulePage_GetEmployeeOffsiteShiftsDocument = gql`
    query SchedulePage_GetEmployeeOffsiteShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeOffsiteShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeOffsiteShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeOffsiteShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeOffsiteShiftsQuery, Types.SchedulePage_GetEmployeeOffsiteShiftsQueryVariables>({ query: SchedulePage_GetEmployeeOffsiteShiftsDocument, ...options });
};
export const SchedulePage_GetEmployeePayRatesDocument = gql`
    query SchedulePage_GetEmployeePayRates($employeeId: ID!) {
  businessEmployeePayRates(employeeId: $employeeId) {
    id
    type
    rate
    startDate
    metaJson
    note
    admin {
      id
      name
    }
    updatedAt
  }
}
    `;

export function useSchedulePage_GetEmployeePayRatesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeePayRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeePayRatesQuery, Types.SchedulePage_GetEmployeePayRatesQueryVariables>({ query: SchedulePage_GetEmployeePayRatesDocument, ...options });
};
export const SchedulePage_GetEmployeeScheduleStatsDocument = gql`
    query SchedulePage_GetEmployeeScheduleStats($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeScheduleStats(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    cycleStartDate
    cycleEndDate
    regularHours
    overtimeHours
    laborLawViolations {
      type
      description
      startDate
      endDate
      count
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeScheduleStatsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeScheduleStatsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeScheduleStatsQuery, Types.SchedulePage_GetEmployeeScheduleStatsQueryVariables>({ query: SchedulePage_GetEmployeeScheduleStatsDocument, ...options });
};
export const SchedulePage_GetEmployeeScheduleTimeoffsDocument = gql`
    query SchedulePage_GetEmployeeScheduleTimeoffs($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeScheduleTimeoffs(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    date
    employee {
      id
      index
      name
      user {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeScheduleTimeoffsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeScheduleTimeoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeScheduleTimeoffsQuery, Types.SchedulePage_GetEmployeeScheduleTimeoffsQueryVariables>({ query: SchedulePage_GetEmployeeScheduleTimeoffsDocument, ...options });
};
export const SchedulePage_GetEmployeeSchedulesDocument = gql`
    query SchedulePage_GetEmployeeSchedules($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeSchedules(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    employeeId
    date
    dayoff {
      id
      deleted
      type
      date
      assignee {
        id
        name
        user {
          id
          name
        }
      }
    }
    draftShifts {
      id
      deleted
      note
      shiftId
      startTime
      endTime
      restStartTime
      restMins
      isQualified
      date
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        index
        name
      }
    }
    holiday {
      id
      name
      date
      public
    }
    employeeHoliday {
      holidayId
      date
      name
    }
    leaveRequests {
      id
      startDatetime
      endDatetime
      note
      type
    }
    shifts {
      id
      deleted
      note
      startTime
      endTime
      restStartTime
      restMins
      date
      opened
      isQualified
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        index
        name
      }
    }
    scheduleTimeoff {
      id
      date
      startTime
      endTime
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeSchedulesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeSchedulesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeSchedulesQuery, Types.SchedulePage_GetEmployeeSchedulesQueryVariables>({ query: SchedulePage_GetEmployeeSchedulesDocument, ...options });
};
export const SchedulePage_GetEmployeeShiftsDocument = gql`
    query SchedulePage_GetEmployeeShifts($employeeId: ID!, $startDate: String!, $endDate: String!) {
  businessEmployeeShifts(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePage_GetEmployeeShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeeShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeeShiftsQuery, Types.SchedulePage_GetEmployeeShiftsQueryVariables>({ query: SchedulePage_GetEmployeeShiftsDocument, ...options });
};
export const SchedulePage_GetEmployeesDocument = gql`
    query SchedulePage_GetEmployees($workplaceId: ID!) {
  businessWorkplaceEmployees(
    workplaceId: $workplaceId
    active: true
    privilegedWorkingAreasOnly: true
  ) {
    id
    active
    index
    employeeNumber
    type
    name
    tags
    insertedAt
    user {
      id
      name
      avatar
    }
    positions {
      id
      name
      color
    }
  }
}
    `;

export function useSchedulePage_GetEmployeesQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetEmployeesQuery, Types.SchedulePage_GetEmployeesQueryVariables>({ query: SchedulePage_GetEmployeesDocument, ...options });
};
export const SchedulePage_GetLeaveRequestsDocument = gql`
    query SchedulePage_GetLeaveRequests($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceLeaveRequests(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    updatedAt
    status
    type
    startDatetime
    endDatetime
    note
    employee {
      id
      name
      positions {
        id
        name
        color
      }
      user {
        id
        name
      }
    }
  }
}
    `;

export function useSchedulePage_GetLeaveRequestsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetLeaveRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetLeaveRequestsQuery, Types.SchedulePage_GetLeaveRequestsQueryVariables>({ query: SchedulePage_GetLeaveRequestsDocument, ...options });
};
export const SchedulePage_GetMasterShiftsToFullFillDocument = gql`
    query SchedulePage_GetMasterShiftsToFullFill($workplaceId: ID!, $date: String!) {
  businessWorkplaceMasterShiftsToFullfill(workplaceId: $workplaceId, date: $date) {
    masterShiftId
    amountToFullfill
  }
}
    `;

export function useSchedulePage_GetMasterShiftsToFullFillQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetMasterShiftsToFullFillQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetMasterShiftsToFullFillQuery, Types.SchedulePage_GetMasterShiftsToFullFillQueryVariables>({ query: SchedulePage_GetMasterShiftsToFullFillDocument, ...options });
};
export const SchedulePage_GetOffsiteDraftShiftsDocument = gql`
    query SchedulePage_GetOffsiteDraftShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceOffsiteDraftShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    position {
      id
      name
      color
    }
    assignee {
      id
      type
      name
      workplace {
        id
        name
      }
      user {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePage_GetOffsiteDraftShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetOffsiteDraftShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetOffsiteDraftShiftsQuery, Types.SchedulePage_GetOffsiteDraftShiftsQueryVariables>({ query: SchedulePage_GetOffsiteDraftShiftsDocument, ...options });
};
export const SchedulePage_GetOffsiteShiftsDocument = gql`
    query SchedulePage_GetOffsiteShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceOffsiteShifts(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    position {
      id
      name
      color
    }
    assignee {
      id
      type
      name
      workplace {
        id
        name
      }
      user {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePage_GetOffsiteShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetOffsiteShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetOffsiteShiftsQuery, Types.SchedulePage_GetOffsiteShiftsQueryVariables>({ query: SchedulePage_GetOffsiteShiftsDocument, ...options });
};
export const SchedulePage_GetScheduleTimeoffsDocument = gql`
    query SchedulePage_GetScheduleTimeoffs($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceScheduleTimeoffs(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    date
    employee {
      id
      index
      name
      user {
        id
        name
      }
      positions {
        id
        name
        color
      }
    }
  }
}
    `;

export function useSchedulePage_GetScheduleTimeoffsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetScheduleTimeoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetScheduleTimeoffsQuery, Types.SchedulePage_GetScheduleTimeoffsQueryVariables>({ query: SchedulePage_GetScheduleTimeoffsDocument, ...options });
};
export const DeleteShiftDocument = gql`
    mutation DeleteShift($input: DeleteShiftInput!) {
  deleteShift(input: $input) {
    shiftId
  }
}
    `;

export function useDeleteShiftMutation() {
  return Urql.useMutation<Types.DeleteShiftMutation, Types.DeleteShiftMutationVariables>(DeleteShiftDocument);
};
export const SchedulePage_GetShiftsDocument = gql`
    query SchedulePage_GetShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceShiftsWithActiveAssignee(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    deleted
    updatedAt
    note
    startTime
    endTime
    restStartTime
    restMins
    date
    opened
    offsiteWorkplace {
      id
      name
    }
    position {
      id
      name
      color
    }
    assignee {
      id
      index
      name
    }
  }
}
    `;

export function useSchedulePage_GetShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetShiftsQuery, Types.SchedulePage_GetShiftsQueryVariables>({ query: SchedulePage_GetShiftsDocument, ...options });
};
export const SchedulePage_GetWorkplaceScheduleStatsDocument = gql`
    query SchedulePage_GetWorkplaceScheduleStats($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  businessWorkplaceScheduleStats(
    workplaceId: $workplaceId
    startDate: $startDate
    endDate: $endDate
  ) {
    workplaceId
    startDate
    endDate
    regularHours {
      date
      hours
    }
    overtimeHours {
      date
      hours
    }
    holidayHours {
      date
      hours
    }
    regularCosts {
      date
      costs
    }
    overtimeCosts {
      date
      costs
    }
    holidayCosts {
      date
      costs
    }
    laborCostToTargetRevenueRatios {
      date
      alert
      ratio
      laborCosts
      regularRatio
      overtimeRatio
      regularLaborCosts
      overtimeLaborCosts
      targetRevenue
    }
  }
}
    `;

export function useSchedulePage_GetWorkplaceScheduleStatsQuery(options: Omit<Urql.UseQueryArgs<Types.SchedulePage_GetWorkplaceScheduleStatsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SchedulePage_GetWorkplaceScheduleStatsQuery, Types.SchedulePage_GetWorkplaceScheduleStatsQueryVariables>({ query: SchedulePage_GetWorkplaceScheduleStatsDocument, ...options });
};
export const SettingsPage_SetGeofenceDocument = gql`
    mutation SettingsPage_SetGeofence($input: SetGeofenceInput!) {
  setGeofence(input: $input) {
    geofence {
      lat
      lng
      distance
    }
  }
}
    `;

export function useSettingsPage_SetGeofenceMutation() {
  return Urql.useMutation<Types.SettingsPage_SetGeofenceMutation, Types.SettingsPage_SetGeofenceMutationVariables>(SettingsPage_SetGeofenceDocument);
};
export const SettingsPage_SetGmapPlaceDocument = gql`
    mutation SettingsPage_SetGmapPlace($input: SetGmapPlaceInput!) {
  setGmapPlace(input: $input) {
    gmapPlace {
      gmapPlaceId
    }
  }
}
    `;

export function useSettingsPage_SetGmapPlaceMutation() {
  return Urql.useMutation<Types.SettingsPage_SetGmapPlaceMutation, Types.SettingsPage_SetGmapPlaceMutationVariables>(SettingsPage_SetGmapPlaceDocument);
};
export const SettingsPage_UpdateWorkplaceDocument = gql`
    mutation SettingsPage_UpdateWorkplace($input: UpdateWorkplaceInput!) {
  updateWorkplace(input: $input) {
    workplace {
      id
      attendanceMode
      attendanceRestTimestampsEnabled
    }
  }
}
    `;

export function useSettingsPage_UpdateWorkplaceMutation() {
  return Urql.useMutation<Types.SettingsPage_UpdateWorkplaceMutation, Types.SettingsPage_UpdateWorkplaceMutationVariables>(SettingsPage_UpdateWorkplaceDocument);
};
export const SettingsPage_GetWorkplaceDocument = gql`
    query SettingsPage_GetWorkplace($workplaceId: ID!) {
  node(id: $workplaceId) {
    ... on BusinessWorkplace {
      id
      name
      attendanceMode
      attendanceRestTimestampsEnabled
      gmapPlace {
        gmapPlaceId
        address
      }
      geofence {
        lat
        lng
        distance
      }
    }
  }
}
    `;

export function useSettingsPage_GetWorkplaceQuery(options: Omit<Urql.UseQueryArgs<Types.SettingsPage_GetWorkplaceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SettingsPage_GetWorkplaceQuery, Types.SettingsPage_GetWorkplaceQueryVariables>({ query: SettingsPage_GetWorkplaceDocument, ...options });
};
export const SetAttendancePreferenceDocument = gql`
    mutation SetAttendancePreference($input: SetAttendancePreferenceInput!) {
  setAttendancePreference(input: $input) {
    attendancePreference {
      autoClockOut
      defaultCompRestLeaveApplyOnLeaveEarly
      maxClockInEarlyMins
      minLateMins
      minLeaveEarlyMins
      minOvertimeMins
      minRestMins
      lateStepMins
      lateToleranceMins
      leaveEarlyStepMins
      leaveEarlyToleranceMins
      overtimeStepMins
      overtimeToleranceMins
      restStepMins
      restToleranceMins
    }
  }
}
    `;

export function useSetAttendancePreferenceMutation() {
  return Urql.useMutation<Types.SetAttendancePreferenceMutation, Types.SetAttendancePreferenceMutationVariables>(SetAttendancePreferenceDocument);
};
export const WorkplaceSettingsAttendance_GetBusinessWorkplaceAttendancePreferenceDocument = gql`
    query WorkplaceSettingsAttendance_GetBusinessWorkplaceAttendancePreference($workplaceId: ID!) {
  businessWorkplaceAttendancePreference(workplaceId: $workplaceId) {
    autoClockOut
    defaultCompRestLeaveApplyOnLeaveEarly
    maxClockInEarlyMins
    minLateMins
    minLeaveEarlyMins
    minOvertimeMins
    minRestMins
    lateStepMins
    lateToleranceMins
    leaveEarlyStepMins
    leaveEarlyToleranceMins
    overtimeStepMins
    overtimeToleranceMins
    restStepMins
    restToleranceMins
  }
}
    `;

export function useWorkplaceSettingsAttendance_GetBusinessWorkplaceAttendancePreferenceQuery(options: Omit<Urql.UseQueryArgs<Types.WorkplaceSettingsAttendance_GetBusinessWorkplaceAttendancePreferenceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.WorkplaceSettingsAttendance_GetBusinessWorkplaceAttendancePreferenceQuery, Types.WorkplaceSettingsAttendance_GetBusinessWorkplaceAttendancePreferenceQueryVariables>({ query: WorkplaceSettingsAttendance_GetBusinessWorkplaceAttendancePreferenceDocument, ...options });
};
export const TimeClockPage_GetShiftsDocument = gql`
    query TimeClockPage_GetShifts($workplaceId: ID!, $startDate: String!, $endDate: String!) {
  node(id: $workplaceId) {
    ... on BusinessWorkplace {
      id
      shifts(startDate: $startDate, endDate: $endDate) {
        id
        note
        startTime
        endTime
        date
        restMins
        insertedAt
        updatedAt
        offsiteWorkplace {
          id
          name
        }
        position {
          id
          name
          color
        }
        assignee {
          id
          index
          name
          user {
            id
            name
            avatar
          }
          positions {
            id
            name
            archived
            color
          }
        }
        approvedLeaveRequests {
          id
          status
          type
          note
          startDatetime
          endDatetime
        }
        linkedAttendance {
          id
          clockIn
          clockInOrigin
          clockOut
          clockOutOrigin
          lateMins
          leaveEarlyMins
          overtimeMins
          status
        }
      }
    }
  }
}
    `;

export function useTimeClockPage_GetShiftsQuery(options: Omit<Urql.UseQueryArgs<Types.TimeClockPage_GetShiftsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.TimeClockPage_GetShiftsQuery, Types.TimeClockPage_GetShiftsQueryVariables>({ query: TimeClockPage_GetShiftsDocument, ...options });
};
export const Impersonator_GetActiveBusinessesDocument = gql`
    query Impersonator_GetActiveBusinesses {
  activeBusinesses {
    id
    name
    owner {
      id
      name
    }
  }
}
    `;

export function useImpersonator_GetActiveBusinessesQuery(options?: Omit<Urql.UseQueryArgs<Types.Impersonator_GetActiveBusinessesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.Impersonator_GetActiveBusinessesQuery, Types.Impersonator_GetActiveBusinessesQueryVariables>({ query: Impersonator_GetActiveBusinessesDocument, ...options });
};
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    name
    email
    avatar
    defaultBusinessId
    defaultWorkplaceId
    isImpersonator
  }
  impersonator {
    id
    name
    avatar
  }
  defaultBusiness {
    id
    name
    isAdmin
    isOwner
  }
  defaultBusinessEmployee {
    id
    checkAttendance
    privilegedWorkplaces {
      id
      name
    }
    workplace {
      id
    }
  }
}
    `;

export function useGetCurrentUserQuery(options?: Omit<Urql.UseQueryArgs<Types.GetCurrentUserQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetCurrentUserQuery, Types.GetCurrentUserQueryVariables>({ query: GetCurrentUserDocument, ...options });
};
export const GetAttendanceDocument = gql`
    query GetAttendance($id: ID!) {
  node(id: $id) {
    ... on BusinessWorkplaceAttendance {
      id
      status
      clockIn
      clockInOrigin
      clockOut
      clockOutOrigin
      absenceMins
      lateMins
      leaveEarlyMins
      overtimeMins
      restMins
      restTimestamps
      convertibleCompRestMins
      convertibleLeaveCompRestMins
      reasonForOvertime
      note
      hasCompRestMinute
      assignee {
        id
        index
        name
      }
      shift {
        id
        startTime
        endTime
        restStartTime
        restMins
        date
        assignee {
          id
          index
          name
        }
        position {
          id
          name
          color
        }
        linkedAttendance {
          id
          status
          clockIn
          clockOut
          restMins
          overtimeMins
        }
      }
      eventLogs {
        type
        insertedAt
        attendance {
          id
          clockIn
          clockOut
          restMins
          status
        }
        employee {
          id
          name
          user {
            id
            name
            avatar
          }
        }
      }
    }
  }
}
    `;

export function useGetAttendanceQuery(options: Omit<Urql.UseQueryArgs<Types.GetAttendanceQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetAttendanceQuery, Types.GetAttendanceQueryVariables>({ query: GetAttendanceDocument, ...options });
};
export const GetAttendanceCompRestMinuteDocument = gql`
    query GetAttendanceCompRestMinute($attendanceId: ID!) {
  businessWorkplaceAttendanceCompRestMinute(attendanceId: $attendanceId) {
    minutes
    status
    expireAt
    isPaid
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
    usages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
  }
}
    `;

export function useGetAttendanceCompRestMinuteQuery(options: Omit<Urql.UseQueryArgs<Types.GetAttendanceCompRestMinuteQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetAttendanceCompRestMinuteQuery, Types.GetAttendanceCompRestMinuteQueryVariables>({ query: GetAttendanceCompRestMinuteDocument, ...options });
};
export const GetAttendanceLeaveCompRestMinuteDocument = gql`
    query GetAttendanceLeaveCompRestMinute($attendanceId: ID!) {
  businessWorkplaceAttendanceLeaveCompRestMinute(attendanceId: $attendanceId) {
    minutes
    status
    expireAt
    isPaid
    employee {
      id
    }
    attendance {
      id
      convertibleCompRestMins
      convertibleLeaveCompRestMins
      assignee {
        id
      }
      shift {
        id
        deleted
        updatedAt
        note
        startTime
        endTime
        restStartTime
        restMins
        date
        opened
        position {
          id
          name
          color
        }
      }
    }
    leaveRequest {
      id
      type
      startDatetime
      endDatetime
    }
    usages {
      minutes
      leaveRequest {
        id
        type
        startDatetime
        endDatetime
      }
      insertedAt
    }
  }
}
    `;

export function useGetAttendanceLeaveCompRestMinuteQuery(options: Omit<Urql.UseQueryArgs<Types.GetAttendanceLeaveCompRestMinuteQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetAttendanceLeaveCompRestMinuteQuery, Types.GetAttendanceLeaveCompRestMinuteQueryVariables>({ query: GetAttendanceLeaveCompRestMinuteDocument, ...options });
};
export const GetDayoffDocument = gql`
    query GetDayoff($id: ID!) {
  node(id: $id) {
    ... on BusinessWorkplaceDayoff {
      id
      type
      date
    }
  }
}
    `;

export function useGetDayoffQuery(options: Omit<Urql.UseQueryArgs<Types.GetDayoffQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetDayoffQuery, Types.GetDayoffQueryVariables>({ query: GetDayoffDocument, ...options });
};
export const GetDraftShiftDocument = gql`
    query GetDraftShift($id: ID!) {
  node(id: $id) {
    ... on BusinessWorkplaceDraftShift {
      id
      deleted
      updatedAt
      note
      shiftId
      startTime
      endTime
      restStartTime
      restMins
      date
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        name
      }
    }
  }
}
    `;

export function useGetDraftShiftQuery(options: Omit<Urql.UseQueryArgs<Types.GetDraftShiftQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetDraftShiftQuery, Types.GetDraftShiftQueryVariables>({ query: GetDraftShiftDocument, ...options });
};
export const GetEmployeeDocument = gql`
    query GetEmployee($id: ID!, $holidaysStartDate: String!, $holidaysEndDate: String!, $settlementStartDate: String!, $settlementEndDate: String!) {
  node(id: $id) {
    ... on BusinessEmployee {
      id
      active
      index
      ssoSvUserId
      employeeNumber
      type
      name
      tags
      partTime
      mobilePhone
      forgotToClockInCounts(
        startDate: $settlementStartDate
        endDate: $settlementEndDate
      )
      joiningDate
      advancedAnnualLeaveMinutes
      carriedAnnualLeaveMinutesYears
      annualLeaveMinutes
      lastPaidPayroll {
        startDate
        endDate
      }
      primitiveCompRestMinuteUsages {
        minutes
        leaveRequest {
          id
          type
          startDatetime
          endDatetime
        }
        insertedAt
      }
      tenureDays
      tenureYears
      insertedAt
      suspended
      user {
        id
        name
        avatar
        locale
      }
      positions {
        id
        name
        color
      }
      workplace {
        id
        name
      }
      employeeHolidays(startDate: $holidaysStartDate, endDate: $holidaysEndDate) {
        employeeId
        holidayId
        name
        date
        public
      }
    }
  }
}
    `;

export function useGetEmployeeQuery(options: Omit<Urql.UseQueryArgs<Types.GetEmployeeQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetEmployeeQuery, Types.GetEmployeeQueryVariables>({ query: GetEmployeeDocument, ...options });
};
export const GetLeaveRequestDocument = gql`
    query GetLeaveRequest($id: ID!) {
  node(id: $id) {
    ... on BusinessWorkplaceLeaveRequest {
      id
      type
      status
      startDatetime
      endDatetime
    }
  }
}
    `;

export function useGetLeaveRequestQuery(options: Omit<Urql.UseQueryArgs<Types.GetLeaveRequestQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetLeaveRequestQuery, Types.GetLeaveRequestQueryVariables>({ query: GetLeaveRequestDocument, ...options });
};
export const GetShiftDocument = gql`
    query GetShift($id: ID!) {
  node(id: $id) {
    ... on BusinessWorkplaceShift {
      id
      note
      startTime
      endTime
      restStartTime
      restMins
      date
      opened
      offsiteWorkplace {
        id
        name
      }
      position {
        id
        name
        color
      }
      assignee {
        id
        index
        name
      }
      linkedAttendance {
        id
        status
      }
    }
  }
}
    `;

export function useGetShiftQuery(options: Omit<Urql.UseQueryArgs<Types.GetShiftQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.GetShiftQuery, Types.GetShiftQueryVariables>({ query: GetShiftDocument, ...options });
};