import { gqlClientAtom } from 'gql'
import { Invoice } from 'gql/types'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { ResellerCheckoutPage_GetResellerInvoice } from './_gql'

const queryResultAtom = atomFamily((input: string) =>
  atom<Invoice | null>(null)
)

export const resellerInvoiceAtom = atomFamily((input: string) =>
  atom(
    get => get(queryResultAtom(input)),
    (get, set) => {
      const queryData = async () => {
        const client = get(gqlClientAtom)
        if (!client) return
        const [invoiceId, reseller] = input.split(':')
        const result = await client
          .query(ResellerCheckoutPage_GetResellerInvoice, {
            invoiceId,
            reseller,
          })
          .toPromise()
        console.log(result)
        if (result.data) {
          set(queryResultAtom(input), parse(result))
        }
      }
      queryData()
    }
  )
)

const parse = (result: any): Invoice | null => result?.data?.resellerInvoice
