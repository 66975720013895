import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { sessionAtom, sessionReducer } from 'atoms/Session'
import axios from 'axios'
import { getEnv } from 'env'
import { Business } from 'gql/types'
import useImmerReducer from 'helpers/useImmerReducer'
import { useNavigate } from 'react-router-dom'
import sleep from 'utils/sleep'
import useActiveBusinesses from './hooks/useActiveBusinesses'

const url = getEnv().authApiUrl

interface Props {
  open: boolean
  onClose: () => void
}

function ImpersonateDialog({ open, onClose }: Props) {
  const navigate = useNavigate()
  const [, dispatch] = useImmerReducer(sessionAtom, sessionReducer)

  const activeBusinesses = useActiveBusinesses()

  const handleOnClick = (business: Business) => async () => {
    const { data } = await axios.post(
      `${url}/impersonate`,
      {
        userId: business.owner.id,
      },
      {
        withCredentials: true,
      }
    )

    dispatch({
      type: 'update',
      session: {
        userId: data.userId,
        businessId: data.businessId,
        employeeId: data.employeeId,
        workplaceId: data.workplaceId,
      },
    })

    await sleep(1000)

    navigate('/', { replace: true })
    window.location.reload()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <List>
          {activeBusinesses?.map(business => (
            <ListItemButton onClick={handleOnClick(business)}>
              <ListItemText primary={business.name} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default ImpersonateDialog
