import DesktopIcon from '@mui/icons-material/Computer'
import MobileIcon from '@mui/icons-material/PhoneIphone'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import { zhTW } from 'date-fns/locale'
import { UserSession } from 'gql/types'
import parseDate from 'helpers/parseDate'
import useSessions from '../../_hooks/useSessions'
import Cookies from 'js-cookie'
import axios from 'axios'
import { getEnv } from 'env'
import { useState } from 'react'

interface Props {
  open: boolean
  onClose: () => void
}

function SessionsDialog({ open, onClose }: Props) {
  const sessions = useSessions()
  const [deletedSessionIds, setDeletedSessionIds] = useState<string[]>([])

  const currentSessionId = Cookies.get('sessionId')

  const getElaspseTime = (session: UserSession) =>
    formatDistanceStrict(parseDate(session.updatedAt!), new Date(), {
      addSuffix: true,
      locale: zhTW,
    })

  const handleLogoutSession = (sessionId: string) => async () => {
    const { status } = await axios.post(
      `${getEnv().authApiUrl}/logout_session`,
      { sessionId },
      {
        withCredentials: true,
      }
    )

    if (status === 200) {
      setDeletedSessionIds(ids => [...ids, sessionId])
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>帳號使用裝置管理</DialogTitle>
      <DialogContent dividers style={{ width: 600 }}>
        <List>
          {sessions
            ?.filter(session => !deletedSessionIds.includes(session.id))
            .map(session => (
              <ListItem key={session.id}>
                <ListItemIcon>
                  {session.userAgent.device.type === 'desktop' ? (
                    <DesktopIcon />
                  ) : (
                    <MobileIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={session.businessName}
                  secondary={session.workplaceName}
                />

                {/* {session.mobileApp ? (
                  <Typography>App</Typography>
                ) : (
                  <Typography>網頁</Typography>
                )} */}

                {session.timeClockMode && (
                  <Chip style={{ marginLeft: 16 }} label="打卡鐘模式" />
                )}

                <div style={{ flex: 1 }} />

                <Typography style={{ marginRight: 16 }}>
                  {getElaspseTime(session)}
                </Typography>

                <div
                  style={{
                    width: 120,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {currentSessionId === session.id && (
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        color: 'green',
                        textAlign: 'end',
                      }}
                    >
                      目前裝置
                    </Typography>
                  )}
                  {currentSessionId !== session.id && (
                    <Button
                      variant="outlined"
                      onClick={handleLogoutSession(session.id)}
                    >
                      登出此裝置
                    </Button>
                  )}
                </div>
              </ListItem>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default SessionsDialog
