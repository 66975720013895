import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import { businessModeAtom } from 'atoms/Session'
import { useAtomValue } from 'jotai'
import { DrawerHeader } from '..'
import BusinessDrawer from './_components/BusinessDrawer'
import WorkplaceDrawer from './_components/WorkplaceDrawer'
import { getEnv } from 'env'

interface Props {
  open: boolean
  onClose: () => void
}

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

function DrawerComponent({ open, onClose }: Props) {
  const businessMode = useAtomValue(businessModeAtom)
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {businessMode ? <BusinessDrawer /> : <WorkplaceDrawer />}
      <div style={{ position: 'absolute', bottom: 0, left: 8, color: 'grey' }}>
        {`v${getEnv().version}`}
      </div>
    </Drawer>
  )
}

export default DrawerComponent
