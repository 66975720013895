import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    primary: {
      light: '#ff8439',
      main: '#d34500',
      dark: '#9b2300',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fffffb',
      main: '#1d1311',
      dark: '#a69b97',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
  },
})

export default theme
