import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import { useEffect, useMemo, useState } from 'react'
import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import useCustomFields from '../../../../_hooks/useCustomFields'
import DraggableListItem from './_components/DraggableListItem'
import sortByIndex from 'helpers/sortByIndex'

function ReorderableList() {
  const customFields = useCustomFields()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => setIsMounted(true), [])

  const sortedCustomFields = useMemo(
    () => customFields.sort(sortByIndex),
    [customFields]
  )

  const handleOnDragEnd = async (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index
    if (
      !customFields ||
      (!sourceIndex && sourceIndex !== 0) ||
      (!destinationIndex && destinationIndex !== 0) ||
      sourceIndex === destinationIndex
    )
      return
  }

  return (
    <Paper style={{ overflowY: 'auto' }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {isMounted && (
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <List
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {sortedCustomFields.map((customField, index) => (
                  <DraggableListItem customField={customField} index={index} />
                ))}
                {droppableProvided.placeholder}
              </List>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </Paper>
  )
}

export default ReorderableList
