import SessionsIcon from '@mui/icons-material/Computer'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import HelpIcon from '@mui/icons-material/Help'
import MenuIcon from '@mui/icons-material/Menu'
import MoreIcon from '@mui/icons-material/MoreVert'
import { CircularProgress, Tooltip } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { ReactComponent as Aibou } from 'assets/aibou.svg'
import { ReactComponent as Crew } from 'assets/crew.svg'
import { isStaging } from 'const/isStaging'
import { gqlClientAtom } from 'gql'
import isLowRes from 'helpers/isLowRes'
import logout from 'helpers/logout'
import useIsSessionExpired from 'hooks/Auth/useIsSessionExpired'
import { useAtomValue, useSetAtom } from 'jotai'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import CurrentUserName from './_components/CurrentUserName'
import DomainButton from './_components/DomainButton'
import TimeClockButton from './_components/TimeClockButton'
import { businessAtom } from 'atoms/Session/Business'
import SessionsDialog from './_components/SessionsDialog'
import useSessions from './_hooks/useSessions'

const drawerWidth = 240

interface Props {
  open: boolean
  onOpen: () => void
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default function AppBarComponent({ open, onOpen }: Props) {
  const business = useAtomValue(businessAtom)
  const sessions = useSessions()
  const sessionExpired = useIsSessionExpired()
  const resetGqlClient = useSetAtom(gqlClientAtom)
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

  const [showSessionsDialog, setShowSessionsDialog] = useState(false)
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const handleLogout = async () => {
    setIsLoggingOut(true)
    await logout()
    popupState.close()
    resetGqlClient()
  }

  const handleHelpOnClick = () => {
    window.open('https://aibou-crew.super.site/')?.focus()
  }

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onOpen}
          >
            <MenuIcon />
          </IconButton>

          <Aibou
            style={{
              width: isLowRes ? 56 : 70,
              height: isLowRes ? 24 : 30,
              marginLeft: 20,
              marginRight: isLowRes ? 6 : 8,
            }}
          />

          <Crew
            fill="white"
            style={{
              width: isLowRes ? 56 : 70,
              height: isLowRes ? 24 : 30,
              marginRight: 16,
            }}
          />
          {business && (
            <>
              <Typography
                style={{
                  fontWeight: 600,
                  marginRight: 16,
                }}
              >
                x
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 600,
                }}
              >
                {business.name}
              </Typography>
            </>
          )}
          {isStaging && (
            <Typography
              variant="h5"
              style={{
                fontWeight: 600,
                border: '1px dashed white',
                padding: '10px 16px',
                marginLeft: 16,
              }}
            >
              STAGING
            </Typography>
          )}
          <div style={{ flexGrow: 1 }} />

          {!sessionExpired && (
            <>
              <Tooltip arrow title={'支援中心'}>
                <IconButton
                  style={{ color: 'white', marginRight: 8 }}
                  onClick={handleHelpOnClick}
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>

              <TimeClockButton />

              <DomainButton />

              <CurrentUserName />

              <IconButton
                style={{ color: 'white' }}
                {...bindTrigger(popupState)}
              >
                <MoreIcon />
              </IconButton>

              <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {sessions && sessions.length > 1 && (
                  <MenuItem onClick={() => setShowSessionsDialog(true)}>
                    <SessionsIcon style={{ marginRight: 8 }} />
                    帳號使用裝置管理
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  <LogoutIcon style={{ marginRight: 8 }} />
                  登出
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Dialog open={isLoggingOut}>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ marginRight: 12 }}>
              <FormattedMessage
                defaultMessage="Logging out..."
                id="KoVpgB"
                description="logout prompt"
              />
            </Typography>
            <CircularProgress size={20} />
          </div>
        </DialogContent>
      </Dialog>
      <SessionsDialog
        open={showSessionsDialog}
        onClose={() => setShowSessionsDialog(false)}
      />
    </>
  )
}
