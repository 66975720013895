import LeaveIcon from '@mui/icons-material/AssistantPhoto'
import PayrollIcon from '@mui/icons-material/AttachMoney'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PositionsIcon from '@mui/icons-material/EmojiPeople'
import SchedulerIcon from '@mui/icons-material/Event'
import EmployeesIcon from '@mui/icons-material/Group'
import AttendanceIcon from '@mui/icons-material/Schedule'
import PreferencesIcon from '@mui/icons-material/Settings'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ReactComponent as EventIcon } from 'assets/event.svg'
import { authAtom } from 'atoms/Auth'
import { useAtomValue } from 'jotai'
import { FormattedMessage } from 'react-intl'
import { Link as RouterLink, useLocation } from 'react-router-dom'

function WorkplaceDrawer() {
  const location = useLocation()
  // const betaSchedulePageV1Enabled = useBetaSchedulePageV1Enabled()
  const auth = useAtomValue(authAtom)

  return (
    <>
      <List>
        <Link component={RouterLink} to="/">
          <ListItemButton selected={location.pathname === '/'}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  defaultMessage="Dashboard"
                  id="SApozu"
                  description="drawer link"
                />
              }
            />
          </ListItemButton>
        </Link>
        {auth?.hasSchedulingPrivilege && (
          <Link component={RouterLink} to="/schedule">
            <ListItemButton
              selected={location.pathname.startsWith('/schedule')}
            >
              <ListItemIcon>
                <SchedulerIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Schedule"
                    id="bJ6cFs"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
        {/* {auth?.hasSchedulingPrivilege && betaSchedulePageV1Enabled && (
          <Link component={RouterLink} to="/scheduleV1">
            <ListItemButton
              selected={location.pathname.startsWith('/scheduleV1')}
            >
              <ListItemIcon>
                <SchedulerIcon />
              </ListItemIcon>
              <ListItemText primary={'Schedule V1'} />
            </ListItemButton>
          </Link>
        )} */}
        {auth?.hasAttendancePrivilege && (
          <Link component={RouterLink} to="/attendance">
            <ListItemButton
              selected={location.pathname.startsWith('/attendance/')}
            >
              <ListItemIcon>
                <AttendanceIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Attendance"
                    id="/ddWuD"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
        {/* {auth?.hasAttendancePrivilege && (
          <Link component={RouterLink} to="/attendanceV1">
            <ListItemButton
              selected={location.pathname.startsWith('/attendanceV1')}
            >
              <ListItemIcon>
                <AttendanceIcon />
                <Typography variant="caption">V1</Typography>
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Attendance"
                    id="/ddWuD"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )} */}
        {auth?.hasSchedulingPrivilege && (
          <Link component={RouterLink} to="/leaveManagement">
            <ListItemButton
              selected={location.pathname.startsWith('/leaveManagement')}
            >
              <ListItemIcon>
                <LeaveIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Leave Management"
                    id="4PBS4x"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
        {auth?.hasPayrollPrivilege && (
          <Link component={RouterLink} to="/payroll">
            <ListItemButton selected={location.pathname.startsWith('/payroll')}>
              <ListItemIcon>
                <PayrollIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Payroll"
                    id="1ZrDxJ"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
      </List>
      <Divider />
      <List>
        {auth?.hasSchedulingPrivilege && (
          <Link component={RouterLink} to="/positions">
            <ListItemButton
              selected={location.pathname.startsWith('/positions')}
            >
              <ListItemIcon>
                <PositionsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Positions"
                    id="OWsP7S"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
        {auth?.hasStaffManagementPrivilege && (
          <Link component={RouterLink} to="/employees">
            <ListItemButton
              selected={location.pathname.startsWith('/employees')}
            >
              <ListItemIcon>
                <EmployeesIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Employees"
                    id="NgeFUU"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
        {auth?.hasSchedulingPrivilege && (
          <Link component={RouterLink} to="/masterSchedule">
            <ListItemButton
              selected={location.pathname.startsWith('/masterSchedule')}
            >
              <ListItemIcon>
                <EventIcon style={{ width: 22, height: 22 }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Master Schedule"
                    id="W5N6Ey"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
        {auth?.hasFullPrivilege && (
          <Link component={RouterLink} to="/settings">
            <ListItemButton
              selected={location.pathname.startsWith('/settings')}
            >
              <ListItemIcon>
                <PreferencesIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Workplace Settings"
                    id="BdVpzm"
                    description="drawer link"
                  />
                }
              />
            </ListItemButton>
          </Link>
        )}
      </List>
    </>
  )
}

export default WorkplaceDrawer
