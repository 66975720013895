import Button from '@mui/material/Button'
import { authAtom } from 'atoms/Auth'
import { businessAtom } from 'atoms/Session/Business'
import { addDays, isAfter, isBefore } from 'date-fns'
import getToday from 'helpers/getToday'
import parseDate from 'helpers/parseDate'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function RenewalPrompt({ children }: { children: JSX.Element }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const business = useAtomValue(businessAtom)
  const auth = useAtomValue(authAtom)

  const isSubscribed = useMemo(
    () =>
      business?.subscriptionPlanEndDate &&
      isAfter(parseDate(business?.subscriptionPlanEndDate), getToday()),
    [business?.subscriptionPlanEndDate]
  )

  const subscriptionEndDateInTwoWeeks = useMemo(
    () =>
      isSubscribed &&
      business?.subscriptionPlanEndDate &&
      isBefore(
        parseDate(business?.subscriptionPlanEndDate),
        addDays(getToday(), 14)
      ),
    [business?.subscriptionPlanEndDate, isSubscribed]
  )

  const goToBillingPage = () => {
    if (auth?.isAdmin || auth?.isOwner) {
      navigate('/billing')
    }
  }

  if (
    !business ||
    business?.name === 'Aibou Demo企業' ||
    business?.name === 'Crew 有限公司' ||
    pathname === '/billing' ||
    (isSubscribed && !subscriptionEndDateInTwoWeeks)
  )
    return children

  return (
    <>
      {children}
      <div
        style={{
          position: 'absolute',
          bottom: 8,
          left: 8,
          zIndex: 6000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
        }}
      >
        <Button
          variant="outlined"
          style={{ color: 'red' }}
          onClick={goToBillingPage}
        >
          {subscriptionEndDateInTwoWeeks
            ? `${business?.subscriptionPlanEndDate} 訂閱截止，請盡快訂閱 Aibou Crew 服務以免服務中斷`
            : '請訂閱 Aibou Crew 服務以使用完整功能'}
        </Button>
      </div>
    </>
  )
}

export default RenewalPrompt
