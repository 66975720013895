import Typography from '@mui/material/Typography'
import Avatar from 'components/atoms/Avatar'
import { User } from 'gql/types'
import isLowRes from 'helpers/isLowRes'
import { atom, useAtomValue } from 'jotai'

export const currentUserAtom = atom<User | null>(null)

export default function CurrentUserName() {
  const currentUser = useAtomValue(currentUserAtom)
  if (!currentUser) return null

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 32 }}>
      <Avatar size={isLowRes ? 24 : 32} user={currentUser} />
      <Typography style={{ marginLeft: 16 }}>{currentUser.name}</Typography>
    </div>
  )
}
