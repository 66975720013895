import WorkplaceSelect from '../WorkplaceSelect'
import CustomFields from './_components/CustomFields'
import MobilePreview from './_components/MobilePreview'
import ReportTemplateEditor from './_components/ReportTemplateEditor'

function DailyReportTemplate() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8,
      }}
    >
      <WorkplaceSelect />
      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          alignItems: 'stretch',
          overflow: 'hidden',
        }}
      >
        <CustomFields />
        <ReportTemplateEditor />

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <MobilePreview />
        </div>
      </div>
    </div>
  )
}

export default DailyReportTemplate
