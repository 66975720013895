import isBefore from 'date-fns/isBefore'
import currencyFormatter from 'helpers/currencyFormatter'
import getToday from 'helpers/getToday'
import useDailyReports from '../../../../_hooks/useDailyReports'
import useDateRange from '../../../../_hooks/useDateRange'
import useLaborCost from './_hooks/useLaborCost'
import styles from './index.module.css'
import getRoundedNumber from 'helpers/getRoundedNumber'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/InfoOutlined'

function Stats() {
  const { endDate } = useDateRange()
  const dailyReports = useDailyReports()
  const laborCost = useLaborCost()
  const today = getToday()

  const formatter = new Intl.NumberFormat('zh-TW', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const revenue =
    dailyReports?.reduce(
      (acc, dailyReport) => acc + (dailyReport.revenue || 0),
      0
    ) || 0

  const customers =
    dailyReports?.reduce(
      (acc, dailyReport) => acc + (dailyReport.customers || 0),
      0
    ) || 0

  const overtimeHours =
    dailyReports?.reduce(
      (acc, dailyReport) => acc + (dailyReport.overtimeHours || 0),
      0
    ) || 0

  const isCurrentMonth = isBefore(today, endDate)

  const ratio = ((laborCost || 0) / revenue) * 100

  return (
    <div className={styles.root}>
      <div className={styles.col}>
        <div className={styles.label}>營業額</div>
        <div className={styles.stats}>{currencyFormatter.format(revenue)}</div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>來客數</div>
        <div className={styles.stats}>{`${customers} 人`}</div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>總超時</div>
        <div className={styles.stats} style={{ color: 'darkorange' }}>
          {`${formatter.format(overtimeHours)} 小時`}
        </div>
      </div>

      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <div className={styles.col}>
          <div className={styles.label}>人事成本</div>
          {!laborCost && laborCost !== 0 && (
            <div className={styles.stats}>計算中...</div>
          )}
          {(laborCost || laborCost === 0) && (
            <div className={styles.stats}>
              {currencyFormatter.format(laborCost)}
            </div>
          )}
          {isCurrentMonth && (
            <div style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>
              （未確認）
            </div>
          )}
        </div>
        <Tooltip title="這個月至今預計支出的人事成本。包含已產生的加班費、缺勤、請假等等。月薪員工的薪資及勞健保為整月計算。">
          <InfoIcon style={{ color: 'grey' }} />
        </Tooltip>
      </div>

      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <div className={styles.col}>
          <div className={styles.label}>人事成本佔比</div>

          {((!laborCost && laborCost !== 0) || (!revenue && revenue !== 0)) && (
            <div className={styles.stats}>計算中...</div>
          )}
          {(laborCost || laborCost === 0) && (revenue || revenue === 0) && (
            <div
              className={styles.stats}
              style={{
                color: ratio >= 30 && ratio <= 40 ? 'green' : 'red',
              }}
            >
              {getRoundedNumber(ratio)} %
            </div>
          )}
        </div>
      </div>
      <Tooltip title="人事成本 / 營業額">
        <InfoIcon style={{ color: 'grey' }} />
      </Tooltip>
    </div>
  )
}

export default Stats
