import { gqlClientAtom } from 'gql'
import { BusinessWorkplaceDailyReport, SetDailyReportInput } from 'gql/types'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import {
  DailyReportsPage_GetBusinessWorkplaceDailyReports,
  DailyReportsPage_SetBusinessWorkplaceDailyReport,
} from './_gql'

const queryResultAtom = atomFamily((input: string) =>
  atom<BusinessWorkplaceDailyReport[] | null>(null)
)

export const workplaceDailyReportsAtom = atomFamily((input: string) =>
  atom(
    get => get(queryResultAtom(input)),
    (get, set) => {
      const queryData = async () => {
        const client = get(gqlClientAtom)
        if (!client) return
        const [workplaceId, startDate, endDate] = input.split(':')
        if (!workplaceId) return
        const result = await client
          .query(DailyReportsPage_GetBusinessWorkplaceDailyReports, {
            workplaceId,
            startDate,
            endDate,
          })
          .toPromise()
        if (result.data) {
          set(queryResultAtom(input), parse(result))
        }
      }
      queryData()
    }
  )
)

const parse = (result: any): BusinessWorkplaceDailyReport[] | null =>
  result?.data?.businessWorkplaceDailyReports

export const setWorkplaceDailyReportAtom = atom(
  null,
  async (get, set, input: SetDailyReportInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(DailyReportsPage_SetBusinessWorkplaceDailyReport, {
        input,
      })
      .toPromise()
    if (error) {
      throw error
    }
  }
)
