import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import parseDate from 'helpers/parseDate'
import styles from './index.module.css'

const weekdayLocale = ['ㄧ', '二', '三', '四', '五', '六', '日']

interface Props {
  date: string
  color?: string
}

function CellDate({ date, color }: Props) {
  const cellDate = parseDate(`${date}T00:00:00`)
  const weekdayIndex = getDay(cellDate) > 0 ? getDay(cellDate) - 1 : 6
  const weekday = weekdayLocale[weekdayIndex]
  const formattedDate = format(cellDate, 'M/dd')
  return (
    <div className={styles.root}>
      <span className={styles.weekday} style={{ color: color || 'black' }}>
        {weekday}
      </span>
      <span className={styles.date} style={{ color: color || 'black' }}>
        {formattedDate}
      </span>
    </div>
  )
}

export default CellDate
