import { sessionAtom } from 'atoms/Session'
import debug from 'debug'
import { gqlClientAtom } from 'gql'
import { Business, UpdateBusinessInput } from 'gql/types'
import { atom } from 'jotai'
import { GetBusiness, UpdateBusiness } from './_gql'

const log = debug('crew:atoms/Session/Business')

const queryResultAtom = atom<Business | null>(null)

export const businessAtom = atom(
  get => get(queryResultAtom),
  (get, set) => {
    const queryData = async () => {
      const client = get(gqlClientAtom)
      const businessId = get(sessionAtom)?.businessId
      if (!client || !businessId) return
      const result = await client
        .query(GetBusiness, {
          businessId,
        })
        .toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

businessAtom.onMount = runQuery => {
  log('businessAtom.onMount')
  runQuery()
}

const parse = (result: any): Business | null => result?.data?.node

export const updateBusinessAtom = atom(
  null,
  async (get, set, input: UpdateBusinessInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(UpdateBusiness, { input })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(businessAtom)
    }
  }
)
