import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

function LoadingPrompt() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <CircularProgress size={20} style={{ marginRight: 16 }} />
      <Typography>載入資料中...</Typography>
    </div>
  )
}

export default LoadingPrompt
