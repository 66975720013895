import Button from '@mui/material/Button'
import axios from 'axios'
import { getEnv } from 'env'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ECPayCheckout from '../BillingPage/_components/ECPayCheckout'
import NewWindow from '../BillingPage/_components/Subscription/_components/NewWindow'
import ResellerInvoice from './_components/ResellerInvoice'
import useResellerInvoice from './_hooks/useResellerInvoice'

function ResellerCheckoutPage() {
  const [searchParams] = useSearchParams()
  const [resellerInvoice, queryResellerInvoice] = useResellerInvoice()

  const [checkoutParams, setCheckoutParams] = useState<any>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => queryResellerInvoice(), [queryResellerInvoice])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const { data } = await axios.post(
      `${getEnv().paymentApiUrl}/ecpay/checkout`,
      { invoiceId: searchParams.get('invoice_id') },
      { withCredentials: true }
    )

    setCheckoutParams(data.params)
    setIsSubmitting(false)
  }

  const handleECPayCheckoutOnClose = () => {
    setCheckoutParams(null)
  }

  if (!resellerInvoice) return <div>loading...</div>

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ResellerInvoice
        business={{
          name: resellerInvoice.business.name,
          taxId: resellerInvoice.business.billingInfo?.taxId || '',
        }}
        reseller={{
          name: resellerInvoice.reseller?.name || '',
        }}
        invoice={{
          id: resellerInvoice.id,
          issueDate: resellerInvoice.issueDate,
          detailsJson: JSON.parse(resellerInvoice.detailsJson) as any,
        }}
      />
      {resellerInvoice.status !== 'paid' && (
        <Button variant="contained" onClick={handleSubmit}>
          線上付款
        </Button>
      )}
      {checkoutParams && (
        <NewWindow onClose={handleECPayCheckoutOnClose}>
          <ECPayCheckout params={checkoutParams} />
        </NewWindow>
      )}
    </div>
  )
}

export default ResellerCheckoutPage
