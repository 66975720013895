import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import { useMemo } from 'react'
import useDailyReports from '../../_hooks/useDailyReports'
import useDateRange from '../../_hooks/useDateRange'
import DailyReport from './_components/DailyReport'
import styles from './index.module.css'
import CellDate from './_components/CellDate'
import StarIcon from '@mui/icons-material/Star'
import LoadingPrompt from 'components/prompts/LoadingPrompt'

function DailyReports() {
  const dailyReports = useDailyReports()
  const { startDate, endDate } = useDateRange()
  const dates = useMemo(() => {
    const days = differenceInDays(endDate, startDate) + 1
    return [...Array(days).keys()].map(index => addDays(startDate, index))
  }, [endDate, startDate])

  if (!dailyReports) return <LoadingPrompt />

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {dates.map(date => {
          const dailyReport = dailyReports.find(
            dailyReport => dailyReport.date === format(date, 'yyyy-MM-dd')
          )!
          return (
            <div
              className={styles.cell}
              style={{
                backgroundColor:
                  dailyReport.margin && dailyReport.margin > 0
                    ? '#32CD3210'
                    : '#DC143C10',
              }}
            >
              <CellDate date={date} />
              <DailyReport dailyReport={dailyReport} />
              {dailyReport.starred && (
                <StarIcon
                  className={styles.star}
                  color="primary"
                  fontSize="small"
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DailyReports
