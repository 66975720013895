import WorkplaceSelect from '../../../WorkplaceSelect'
import ChartButton from './_components/ChartButton'
import MonthSelect from './_components/MonthSelect'
import Stats from './_components/Stats'
import styles from './index.module.css'

function Header() {
  return (
    <div className={styles.root}>
      <WorkplaceSelect />
      <MonthSelect />
      <Stats />
      <ChartButton />
    </div>
  )
}

export default Header
