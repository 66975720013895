import { gqlClientAtom } from 'gql'
import { Business } from 'gql/types'
import { atom } from 'jotai'
import { Impersonator_GetActiveBusinesses } from './_gql'

const queryResultAtom = atom<Business[] | null>(null)

export const activeBusinessesAtom = atom(
  get => get(queryResultAtom),
  (get, set) => {
    const queryData = async () => {
      const client = get(gqlClientAtom)
      if (!client) return
      const result = await client
        .query(Impersonator_GetActiveBusinesses, {})
        .toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

const parse = (result: any): Business[] | null => result?.data?.activeBusinesses
