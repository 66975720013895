import * as Sentry from '@sentry/react'
import App from 'components/routes/App'
import PageWrapper from 'components/routes/PageWrapper'
import { gqlClientAtom } from 'gql'
import { useAtomValue } from 'jotai'
import messages from 'lang-compiled/zh-Hant-TW.json'
import { SnackbarProvider } from 'notistack'
import React, { Suspense } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-quill-new/dist/quill.snow.css'
import { IntlProvider } from 'react-intl'
import { Route, Routes } from 'react-router-dom'
import { Provider as GraphqlProvider } from 'urql'
import './App.css'
import useBetaSchedulePageV1Enabled from 'hooks/Auth/useBetaSchedulePageV1Enabled'
import CircularProgress from '@mui/material/CircularProgress'
import ResellerCheckoutPage from 'components/pages/ResellerCheckoutPage'
import ReportsPage from 'components/pages/ReportsPage'

const AlertsPage = React.lazy(() => import('components/pages/AlertsPage'))
const AdminOnlyPage = React.lazy(() => import('components/pages/AdminOnlyPage'))
const AttendancePageV1 = React.lazy(
  () => import('components/pages/AttendancePageV1')
)
const MonthlyReportsPage = React.lazy(
  () => import('components/pages/ReportsPage/_components/MonthlyReportsPage')
)

const DashboardPage = React.lazy(() => import('components/pages/DashboardPage'))
const LoginPage = React.lazy(() => import('components/pages/LoginPage'))
const SignupPage = React.lazy(() => import('components/pages/SignupPage'))
const BillingPage = React.lazy(() => import('components/pages/BillingPage'))
const ReferralsPage = React.lazy(() => import('components/pages/ReferralsPage'))
const EmployeesPage = React.lazy(() => import('components/pages/EmployeesPage'))
const Employee = React.lazy(
  () => import('components/pages/EmployeesPage/_components/Employee')
)
const EmployeeAttendancesV1 = React.lazy(
  () =>
    import('components/pages/AttendancePageV1/_components/EmployeeAttendances')
)
const ErrorPage = React.lazy(() => import('components/pages/ErrorPage'))
const EmployeeLeaves = React.lazy(
  () =>
    import('components/pages/LeaveManagementPage/_components/EmployeeLeaves')
)
const LeaveManagementPage = React.lazy(
  () => import('components/pages/LeaveManagementPage')
)
const MasterSchedulePage = React.lazy(
  () => import('components/pages/MasterSchdulePage')
)
const PayrollPageV1 = React.lazy(() => import('components/pages/PayrollPageV1'))
const PositionsPage = React.lazy(() => import('components/pages/PositionsPage'))
const SchedulePage = React.lazy(() => import('components/pages/SchedulePage'))
const SchedulePageV1 = React.lazy(
  () => import('components/pages/SchedulePageV1')
)
const SettingsPage = React.lazy(() => import('components/pages/SettingsPage'))
const SubscribePage = React.lazy(() => import('components/pages/SubscribePage'))
const WorkplacesPage = React.lazy(
  () => import('components/pages/WorkplacesPage')
)
const TargetRevenueSettings = React.lazy(
  () => import('components/pages/TargetRevenueSettingsPage')
)

const SchedulePageV1Component = () => {
  const betaSchedulePageV1Enabled = useBetaSchedulePageV1Enabled()
  return betaSchedulePageV1Enabled ? <SchedulePageV1 /> : <CircularProgress />
}

function AppComponent() {
  const gqlClient = useAtomValue(gqlClientAtom)

  if (!gqlClient) return null
  return (
    <GraphqlProvider value={gqlClient}>
      <IntlProvider locale="zh-Hant-TW" messages={messages}>
        <SnackbarProvider>
          <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <Suspense fallback="">
              <Routes>
                <Route path="/adminOnly" element={<AdminOnlyPage />}></Route>
                <Route path="/" element={<App />}>
                  <Route
                    index
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<DashboardPage />}
                      />
                    }
                  />
                  <Route
                    path="login"
                    element={<PageWrapper guestsOnly page={<LoginPage />} />}
                  />
                  <Route
                    path="signup"
                    element={<PageWrapper guestsOnly page={<SignupPage />} />}
                  />
                  <Route
                    path="reseller_checkout"
                    element={<PageWrapper page={<ResellerCheckoutPage />} />}
                  />
                  <Route
                    path="/schedule"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        enableDnd
                        withPageLayout
                        page={<SchedulePage />}
                      />
                    }
                  />
                  <Route
                    path="/scheduleV1"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        enableDnd
                        withPageLayout
                        page={<SchedulePageV1Component />}
                      />
                    }
                  />
                  <Route
                    path="/alerts"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<AlertsPage />}
                      />
                    }
                  />
                  <Route
                    path="/attendance"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<AttendancePageV1 />}
                      />
                    }
                  >
                    <Route
                      path=":employeeId"
                      element={<EmployeeAttendancesV1 />}
                    />
                  </Route>
                  {/* <Route
                    path="/attendanceV1"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<AttendancePageV1 />}
                      />
                    }
                  >
                    <Route
                      path=":employeeId"
                      element={<EmployeeAttendancesV1 />}
                    />
                  </Route> */}
                  <Route
                    path="/billing"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<BillingPage />}
                      />
                    }
                  />
                  <Route
                    path="/subscribe"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        page={<SubscribePage />}
                      />
                    }
                  />
                  <Route
                    path="/referrals"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<ReferralsPage />}
                      />
                    }
                  />
                  <Route
                    path="/reports"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<ReportsPage />}
                      />
                    }
                  />
                  <Route
                    path="/targetRevenueSettings"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<TargetRevenueSettings />}
                      />
                    }
                  />

                  <Route
                    path="/monthlyReports"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<MonthlyReportsPage />}
                      />
                    }
                  />
                  <Route
                    path="/leaveManagement"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<LeaveManagementPage />}
                      />
                    }
                  >
                    <Route path=":employeeId" element={<EmployeeLeaves />} />
                  </Route>
                  <Route
                    path="/payroll"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<PayrollPageV1 />}
                      />
                    }
                  />
                  <Route
                    path="/positions"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<PositionsPage />}
                      />
                    }
                  />
                  <Route
                    path="/employees"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<EmployeesPage />}
                      />
                    }
                  >
                    <Route path=":employeeId" element={<Employee />} />
                  </Route>
                  <Route
                    path="/masterSchedule"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        enableDnd
                        withPageLayout
                        page={<MasterSchedulePage />}
                      />
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<SettingsPage />}
                      />
                    }
                  />

                  <Route
                    path="/workplaces"
                    element={
                      <PageWrapper
                        authenticatedUsersOnly
                        withPageLayout
                        page={<WorkplacesPage />}
                      />
                    }
                  />
                </Route>
              </Routes>
            </Suspense>
          </Sentry.ErrorBoundary>
        </SnackbarProvider>
      </IntlProvider>
    </GraphqlProvider>
  )
}

export default AppComponent
