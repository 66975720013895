import Avatar from 'components/atoms/Avatar'
import useShifts from './_hooks/useShifts'
import { useMemo } from 'react'
import isSameDay from 'date-fns/isSameDay'
import parseDate from 'helpers/parseDate'
import Typography from '@mui/material/Typography'
import ShiftCell from 'components/atoms/ShiftCell'
import { CircularProgress } from '@mui/material'
import AttendanceState from './_components/AttendanceState'
import { BusinessWorkplaceShift } from 'gql/types'
import getLeaveTypeName from 'helpers/Leave/getTypeName'

function Roster() {
  const shifts = useShifts()

  const employeeShiftsForToday = useMemo(() => {
    const employeeShifts =
      shifts
        ?.filter(sh => isSameDay(parseDate(sh!.date), new Date()))
        .reduce((acc: any, cur: any) => {
          if (acc[cur.assignee.id]) {
            acc[cur.assignee.id] = [...acc[cur.assignee.id], cur]
          } else {
            acc[cur.assignee.id] = [cur]
          }
          return acc
        }, []) || {}

    return Object.values(employeeShifts)
  }, [shifts]).sort((a: any, b: any) => {
    if (a[0].assignee.index > b[0].assignee.index) return 1
    if (a[0].assignee.index < b[0].assignee.index) return -1
    return 0
  })

  if (!shifts)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          padding: '16px 32px',
          marginLeft: 32,
          border: '1px solid grey',
        }}
      >
        <CircularProgress />
      </div>
    )

  if (shifts.length === 0)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          padding: '16px 32px',
          marginLeft: 32,
          border: '1px solid grey',
        }}
      >
        <Typography variant="h6">今日無排定員工出勤</Typography>
      </div>
    )

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 600,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        padding: '16px 32px',
        border: '1px solid grey',
      }}
    >
      <Typography variant="h6" style={{ marginBottom: 8 }}>
        今日員工出勤
      </Typography>
      {employeeShiftsForToday.map((employeeShifts: any) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Avatar
            size={24}
            user={employeeShifts[0].assignee.user || employeeShifts[0].assignee}
          />
          <Typography
            style={{
              flexShrink: 0,
              paddingLeft: 16,
              fontWeight: 'bold',
              color: 'grey',
            }}
          >
            {employeeShifts[0].assignee.name}
          </Typography>

          <div style={{ flex: 1 }} />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexShrink: 0,
            }}
          >
            {employeeShifts.map((shift: BusinessWorkplaceShift) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexShrink: 0,
                }}
              >
                <div style={{ flexShrink: 0, position: 'relative' }}>
                  {shift.approvedLeaveRequests.length > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        left: 8,
                        zIndex: 1,
                        backgroundColor: 'lightgrey',
                        borderRadius: 6,
                        padding: '0 6px',
                      }}
                    >
                      <span style={{ fontSize: 10, fontWeight: 600 }}>
                        {getLeaveTypeName(shift.approvedLeaveRequests[0]!.type)}
                      </span>
                    </div>
                  )}
                  <ShiftCell showDate={false} shift={shift} />
                </div>
                <div style={{ flexShrink: 0 }}>
                  <AttendanceState shift={shift} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Roster
