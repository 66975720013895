import {
  Button,
  Column,
  Container,
  Heading,
  Hr,
  Img,
  Row,
  Section,
  Text,
} from '@react-email/components'

interface Business {
  name: string
  taxId: string
}

interface Reseller {
  name: string
}

interface Sku {
  id: string
  description: string
  quantity: number
  price: number
}

interface SubscriptionPlan {
  startDate: string
  endDate: string
  seats: number
}

interface InvoiceDetails {
  skus: Sku[]
  subscriptionPlan: SubscriptionPlan
}

interface Invoice {
  id: string
  issueDate: string
  detailsJson: InvoiceDetails
}

interface Props {
  business: Business
  reseller: Reseller
  invoice: Invoice
}

export const ResellerInvoice = ({ reseller, business, invoice }: Props) => {
  const formatter = new Intl.NumberFormat('zh-TW', {
    style: 'currency',
    currency: 'TWD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const priceFormatter = new Intl.NumberFormat('zh-TW', {
    style: 'currency',
    currency: 'TWD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const sku = invoice.detailsJson.skus[0]
  const description = sku.description
  const quantity = sku.quantity
  const subTotal = Math.round(quantity * sku.price)
  const tax = Math.round(0.05 * subTotal)
  const total = Math.round(subTotal + tax)

  return (
    <Container
      style={{
        backgroundColor: 'white',
        border: '1px solid #eaeaea',
        borderRadius: 8,
        margin: '48px auto',
        padding: 20,
        maxWidth: 800,
      }}
    >
      <Section>
        <Row>
          <Column>
            <Img
              src="https://images.aiboucrew.com/assets/appIcon128.png"
              width="48"
              height="48"
              alt="Aibou Crew"
              style={{
                borderRadius: 6,
              }}
            />
          </Column>

          <Column align="right">
            <Heading
              style={{
                fontSize: 20,
                color: '#D34500',
              }}
            >
              Aibou Crew x {reseller.name} 經銷訂單
            </Heading>
          </Column>
        </Row>
      </Section>
      <Section style={{ marginTop: 16 }}>
        <Row align="left" width={'33%'}>
          <Column>
            <Text style={{ margin: 0 }}>訂單編號:</Text>
          </Column>
          <Column align="right">
            <Text style={{ margin: 0 }}>{invoice.id}</Text>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row align="left" width={'33%'}>
          <Column>
            <Text style={{ margin: 0 }}>訂單日期:</Text>
          </Column>
          <Column align="right">
            <Text style={{ margin: 0 }}>{invoice.issueDate}</Text>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row align="left" width={'33%'}>
          <Column>
            <Text style={{ margin: 0 }}>客戶公司名稱:</Text>
          </Column>
          <Column align="right">
            <Text style={{ margin: 0 }}>{business.name}</Text>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row align="left" width={'33%'}>
          <Column>
            <Text style={{ margin: 0 }}>客戶統一編號:</Text>
          </Column>
          <Column align="right">
            <Text style={{ margin: 0 }}>{business.taxId}</Text>
          </Column>
        </Row>
      </Section>
      <Section style={{ margin: '16px 0' }}>
        <Row style={{ backgroundColor: '#ddd' }}>
          <Column width={'60%'}>
            <Text style={{ margin: '0 16px' }}>品項</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'33%'}>
                <Text style={{ margin: 16 }}>數量</Text>
              </Column>
              <Column align="center" width={'33%'}>
                <Text style={{ margin: 16 }}>單價</Text>
              </Column>
              <Column align="right" width={'33%'}>
                <Text style={{ margin: 16 }}>金額</Text>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column width={'60%'}>
            <Text style={{ margin: '0 16px' }}>{description}</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'33%'}>
                <Text style={{ margin: 16 }}>{quantity}</Text>
              </Column>
              <Column align="center" width={'33%'}>
                <Text style={{ margin: 16 }}>
                  {priceFormatter.format(sku.price)}
                </Text>
              </Column>
              <Column align="right" width={'33%'}>
                <Text style={{ margin: 16 }}>{formatter.format(subTotal)}</Text>
              </Column>
            </Row>
          </Column>
        </Row>
      </Section>
      <Section style={{ margin: '20px 0' }}>
        <Row align="right">
          <Column width={'66%'} />
          <Column>
            <Text style={{ margin: '0 16px' }}>營業稅 (5%)</Text>
          </Column>
          <Column align="right">
            <Text style={{ margin: '0 16px' }}>{formatter.format(tax)}</Text>
          </Column>
        </Row>
        <Row align="right" style={{ marginTop: 8 }}>
          <Column width={'66%'} />
          <Column>
            <Row style={{ backgroundColor: '#eee' }}>
              <Column>
                <Text style={{ margin: '8px 16px', fontSize: 20 }}>總計</Text>
              </Column>
              <Column align="right">
                <Text
                  style={{ margin: '8px 16px', fontSize: 20, color: '#D34500' }}
                >
                  {formatter.format(total)}
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      </Section>
      <Hr />
    </Container>
  )
}

export default ResellerInvoice
