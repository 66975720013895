import Error from '@mui/icons-material/Error'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { fetchAndActivate } from 'firebase/remote-config'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  getRemoteConfigValue,
  remoteConfig,
} from 'services/Firebase/RemoteConfig'

// https://stackoverflow.com/a/65687141
// return
//    0: version strings are equal
//    1: version a is greater than b
//   -1: version b is greater than a
const compareSemver = (verA: string, verB: string) =>
  verA.localeCompare(verB, undefined, {
    numeric: true,
    sensitivity: 'base',
  })

function NewAppVersionPrompt({ children }: { children: JSX.Element }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPromptDialog, setShowPromptDialog] = useState(false)

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const currentVersion = process.env.REACT_APP_VERSION! || '1.0.0'
        const minVersion = getRemoteConfigValue('web_min_version').asString()

        const latestVersion =
          getRemoteConfigValue('web_latest_version').asString()

        const isUpToDate = compareSemver(currentVersion, latestVersion) >= 0
        const isOutdated = compareSemver(currentVersion, minVersion) < 0

        console.log(`currentVersion: ${currentVersion}`)
        console.log(`latestVersion: ${latestVersion}`)
        console.log(`minVersion: ${minVersion}`)
        console.log(`isUpToDate: ${isUpToDate}`)
        console.log(`isOutdated: ${isOutdated}`)

        // if (!isUpToDate && !isOutdated) {
        //   enqueueSnackbar(
        //     <FormattedMessage
        //       defaultMessage="New App version avalible!"
        //       id="0zjZzm"
        //       description="prompt"
        //     />,
        //     {
        //       variant: 'warning',
        //       persist: true,
        //       action: key => (
        //         <>
        //           <Button onClick={() => window.location.reload()}>
        //             Update
        //           </Button>
        //           <Button onClick={() => closeSnackbar(key)}>Dismiss</Button>
        //         </>
        //       ),
        //     }
        //   )
        // }

        // setShowPromptDialog(isOutdated)
      })
      .catch(error => console.log(error))
  }, [closeSnackbar, enqueueSnackbar])

  return (
    <>
      {children}
      <Dialog open={showPromptDialog}>
        <DialogContent>
          <div style={{ display: 'flex', color: 'red' }}>
            <Error style={{ marginRight: 12 }} />
            <Typography>
              <FormattedMessage
                defaultMessage="App version outdated! Please refresh to update."
                id="GCZd+1"
                description="prompt"
              />
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NewAppVersionPrompt
