import currencyFormatter from 'helpers/currencyFormatter'
import getRoundedNumber from 'helpers/getRoundedNumber'
import useDailyReports from '../../../../_hooks/useDailyReports'
import styles from './index.module.css'
import useLaborCosts from './_hooks/useLaborCost'
import { useMemo } from 'react'

function Stats() {
  const dailyReports = useDailyReports()
  const { laborCosts, progress } = useLaborCosts()

  const formatter = new Intl.NumberFormat('zh-TW', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const revenue =
    dailyReports?.reduce(
      (acc, dailyReport) => acc + (dailyReport.revenue || 0),
      0
    ) || 0

  const customers =
    dailyReports?.reduce(
      (acc, dailyReport) => acc + (dailyReport.customers || 0),
      0
    ) || 0

  const overtimeHours =
    dailyReports?.reduce(
      (acc, dailyReport) => acc + (dailyReport.overtimeHours || 0),
      0
    ) || 0

  const laborCost = useMemo(() => {
    if (progress < 100) return null
    return laborCosts.reduce((acc, { laborCost }) => acc + laborCost, 0)
  }, [laborCosts, progress])

  const ratio = ((laborCost || 0) / revenue) * 100

  return (
    <div className={styles.root}>
      <div className={styles.col}>
        <div className={styles.label}>營業額</div>
        <div className={styles.stats}>{currencyFormatter.format(revenue)}</div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>來客數</div>
        <div className={styles.stats}>{`${customers} 人`}</div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>總超時</div>
        <div className={styles.stats} style={{ color: 'darkorange' }}>
          {`${formatter.format(overtimeHours)} 小時`}
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>人事成本</div>
        {!laborCost && laborCost !== 0 && (
          <div className={styles.stats}>計算中...</div>
        )}
        {(laborCost || laborCost === 0) && (
          <div className={styles.stats}>
            {currencyFormatter.format(laborCost)}
          </div>
        )}
      </div>

      <div className={styles.col}>
        <div className={styles.label}>人事成本佔比</div>

        {((!laborCost && laborCost !== 0) || (!revenue && revenue !== 0)) && (
          <div className={styles.stats}>計算中...</div>
        )}
        {(laborCost || laborCost === 0) && (revenue || revenue === 0) && (
          <div
            className={styles.stats}
            style={{
              color: ratio >= 30 && ratio <= 40 ? 'green' : 'red',
            }}
          >
            {getRoundedNumber(ratio)} %
          </div>
        )}
      </div>
    </div>
  )
}

export default Stats
