import format from 'date-fns/format'
import { useSetAtom } from 'jotai'
import { workplaceDailyReportsAtom } from '../_atoms/WorkplaceDailyReports'
import useDateRange from './useDateRange'
import useWorkplaceId from './useWorkplaceId'

function useQueryDailyReports() {
  const workplaceId = useWorkplaceId()
  const { startDate, endDate } = useDateRange()
  const sDate = format(startDate, 'yyyy-MM-dd')
  const eDate = format(endDate, 'yyyy-MM-dd')
  return useSetAtom(
    workplaceDailyReportsAtom(`${workplaceId}:${sDate}:${eDate}`)
  )
}

export default useQueryDailyReports
