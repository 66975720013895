import PageLayout from 'components/layouts/PageLayout'
import { Suspense } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AuthOnly from '../AuthOnly'
import GuestOnly from '../GuestOnly'
import ContextProvider from './ContextProvider'

interface Props {
  page: any
  authenticatedUsersOnly?: boolean
  guestsOnly?: boolean
  enableDnd?: boolean
  withPageLayout?: boolean
}

interface WrapperProps {
  children: any
  wrap?: boolean
}

const AuthOnlyWrapper = ({ wrap, children }: WrapperProps) =>
  wrap ? (
    <AuthOnly>
      <ContextProvider>{children}</ContextProvider>
    </AuthOnly>
  ) : (
    children
  )

const GuestOnlyWrapper = ({ wrap, children }: WrapperProps) =>
  wrap ? <GuestOnly>{children}</GuestOnly> : children

const DndProviderWrapper = ({ wrap, children }: WrapperProps) =>
  wrap ? <DndProvider backend={HTML5Backend}>{children}</DndProvider> : children

const PageLayoutWrapper = ({ wrap, children }: WrapperProps) =>
  wrap ? <PageLayout>{children}</PageLayout> : children

function PageWrapper(props: JSX.IntrinsicAttributes & Props) {
  if (props.authenticatedUsersOnly && props.guestsOnly) {
    throw new Error('page cannot be both authenticatedUsersOnly & guestsOnly')
  }
  return (
    <AuthOnlyWrapper wrap={props.authenticatedUsersOnly}>
      <GuestOnlyWrapper wrap={props.guestsOnly}>
        <DndProviderWrapper wrap={props.enableDnd}>
          <PageLayoutWrapper wrap={props.withPageLayout}>
            <Suspense fallback="">{props.page}</Suspense>
          </PageLayoutWrapper>
        </DndProviderWrapper>
      </GuestOnlyWrapper>
    </AuthOnlyWrapper>
  )
}

export default PageWrapper
