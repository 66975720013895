import { loader } from 'graphql.macro'

export const GetBusinessWorkplaces = loader('./queries/get.graphql')
export const CreateWorkplace = loader('./mutations/create.graphql')
export const SessionBusinessWorkplaces_UpdateWorkplace = loader(
  './mutations/update.graphql'
)
export const DeleteWorkplace = loader('./mutations/delete.graphql')
export const SetSchedulerPreference = loader(
  './mutations/setSchedulerPreference.graphql'
)
