import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { workplacesAtom } from '../_atoms/Workplaces'

function useWorkplaces() {
  const [workplaces, queryWorkplaces] = useAtom(workplacesAtom)

  useEffect(() => queryWorkplaces(), [queryWorkplaces])

  return workplaces || []
}

export default useWorkplaces
