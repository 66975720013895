import TimeClockIcon from '@mui/icons-material/AccessTime'
import Button from '@mui/material/Button'
import { businessModeAtom, sessionAtom, sessionReducer } from 'atoms/Session'
import useImmerReducer from 'helpers/useImmerReducer'
import { useAtomValue } from 'jotai'

function TimeClockButton() {
  const businessMode = useAtomValue(businessModeAtom)
  const [, dispatch] = useImmerReducer(sessionAtom, sessionReducer)

  const handleOnClick = () => {
    dispatch({ type: 'update', session: { mode: 'timeclock' } })
  }

  if (businessMode) return null

  return (
    <Button
      style={{
        color: '#d34500',
        backgroundColor: 'white',
        borderColor: '#d34500',
        borderRadius: 40,
        marginRight: 20,
      }}
      variant="outlined"
      startIcon={<TimeClockIcon />}
      onClick={handleOnClick}
    >
      開啟打卡鐘模式
    </Button>
  )
}

export default TimeClockButton
