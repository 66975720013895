import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { atom, useAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import Delta from 'quill-delta'
import { useEffect, useMemo } from 'react'
import ReactQuill from 'react-quill-new'
import useWorkplaceId from '../../../DailyReportsPage/_hooks/useWorkplaceId'
import useReportTemplate from '../../_hooks/useReportTemplate'
import useSetReportTemplate from '../../_hooks/useSetReportTemplate'

export const reportAtom = atomFamily((workplaceId: string) =>
  atom<Delta | null>(null)
)

interface Props {
  workplaceId: string
  reportTemplate: Delta
}

const defaultReport = new Delta(
  JSON.parse(
    '{"ops":[{"insert":"管理員可以為每個門市設計專屬的門市日誌或規定。\\n員工於每日結束營業之後能按照格式填寫日誌。\\n\\n可以為"},{"attributes":{"color":"orange"},"insert":"字體上色"},{"insert":"\\n或者"},{"attributes":{"background":"yellow"},"insert":"背景上色"},{"insert":"\\n\\n可以列出規則提醒\\n回報今日營業額"},{"attributes":{"list":"ordered"},"insert":"\\n"},{"insert":"回報今日來客數"},{"attributes":{"list":"ordered"},"insert":"\\n"},{"insert":"填寫門市日誌"},{"attributes":{"list":"ordered"},"insert":"\\n"},{"insert":"\\n或者打勾項目：\\n例行檢查1"},{"attributes":{"list":"checked"},"insert":"\\n"},{"insert":"例行檢查2"},{"attributes":{"list":"unchecked"},"insert":"\\n"},{"insert":"例行檢查3"},{"attributes":{"list":"unchecked"},"insert":"\\n"},{"insert":"\\n左下角標示重要的日誌，月會能重點討論\\n"}]}'
  )
)

function ReportTemplateEditor({ workplaceId, reportTemplate }: Props) {
  const [report, setReport] = useAtom(reportAtom(workplaceId))
  const setReportTemplate = useSetReportTemplate()
  const myColors = [
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'cyan',
    'purple',
    'white',
    'black',
  ]

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ]

  useEffect(() => setReport(reportTemplate), [reportTemplate, setReport])

  const isModified = useMemo(
    () => JSON.stringify(reportTemplate) !== JSON.stringify(report),
    [report, reportTemplate]
  )

  const isDefault = useMemo(
    () => JSON.stringify(defaultReport) === JSON.stringify(report),
    [report]
  )

  const handleChange = (
    value: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    setReport(editor.getContents())
  }

  const handleSetDefault = () => setReport(defaultReport)
  const handleReset = () => setReport(reportTemplate)
  const handleSave = () =>
    setReportTemplate(workplaceId, JSON.stringify(report))

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 8,
        }}
      >
        <Typography variant="h6">日報樣板</Typography>
        <div style={{ flex: 1 }} />
        <Button
          disabled={isDefault}
          variant="outlined"
          style={{ marginRight: 8 }}
          onClick={handleSetDefault}
        >
          系統預設
        </Button>
        <Button
          disabled={!isModified}
          variant="outlined"
          style={{ marginRight: 8 }}
          onClick={handleReset}
        >
          重設
        </Button>
        <Button disabled={!isModified} variant="contained" onClick={handleSave}>
          儲存
        </Button>
      </div>
      {report && (
        <ReactQuill
          style={{ zIndex: 2 }}
          theme="snow"
          modules={modules}
          formats={formats}
          value={report}
          onChange={handleChange}
        />
      )}
    </div>
  )
}

const ReportTemplateEditorComponent = () => {
  const workplaceId = useWorkplaceId()
  const reportTemplate = useReportTemplate()
  if (!reportTemplate || !workplaceId) return null
  return (
    <div key={workplaceId}>
      <ReportTemplateEditor
        reportTemplate={reportTemplate}
        workplaceId={workplaceId}
      />
    </div>
  )
}

export default ReportTemplateEditorComponent
