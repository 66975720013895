import { currentUserAtom } from 'components/layouts/PageLayout/AppBar/_components/CurrentUserName'
import { getEnv } from 'env'
import { useGetBusinessQuery } from 'gql/hooks'
import { Business } from 'gql/types'
import { atom, useAtom, useAtomValue } from 'jotai'
import { useEffect } from 'react'

export const betaSchedulePageV1EnabledAtom = atom(false)

function useBetaSchedulePageV1Enabled() {
  const currentUser = useAtomValue(currentUserAtom)
  const [betaSchedulePageV1Enabled, setBetaSchedulePageV1Enabled] = useAtom(
    betaSchedulePageV1EnabledAtom
  )
  const [result] = useGetBusinessQuery({
    pause: getEnv().storybookMode || !currentUser?.defaultBusinessId,
    requestPolicy: 'network-only',
    variables: {
      businessId: currentUser?.defaultBusinessId!,
    },
  })

  useEffect(() => {
    if (!getEnv().storybookMode) {
      setBetaSchedulePageV1Enabled(
        (result.data?.node as Business)?.betaSchedulePageV1Enabled
      )
    }
  }, [result.data?.node, setBetaSchedulePageV1Enabled])

  return betaSchedulePageV1Enabled
}

export default useBetaSchedulePageV1Enabled
