import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import getToday from 'helpers/getToday'
import parseDate from 'helpers/parseDate'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

function useDateRange() {
  const today = getToday()
  const location = useLocation()
  const { start } = queryString.parse(location.search)
  const startDate = start ? parseDate(start as string) : startOfMonth(today)
  const endDate = endOfMonth(startDate)
  return { startDate, endDate }
}

export default useDateRange
