import addDays from 'date-fns/addDays'
import isBefore from 'date-fns/isBefore'
import { BusinessWorkplaceShift } from 'gql/types'
import parseDate from 'helpers/parseDate'

const getShiftEndDateTime = (shift: BusinessWorkplaceShift) => {
  const startDateTime = parseDate(`${shift.date} ${shift.startTime}`)
  const endDateTime = parseDate(`${shift.date} ${shift.endTime}`)

  if (isBefore(startDateTime, endDateTime)) {
    return endDateTime
  }
  return addDays(endDateTime, 1)
}

export default getShiftEndDateTime
