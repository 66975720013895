import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import debug from 'debug'
import { getEnv } from 'env'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ProvideSocket } from 'websocket/provider'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './theme'
import packageInfo from '../package.json'
import { browserTracingIntegration } from '@sentry/react'

if (getEnv().debug) {
  debug.enable(getEnv().debug)
  debug.log = console.info.bind(console)
}

Sentry.init({
  dsn: getEnv().sentryDsn,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    browserTracingIntegration(),
    Sentry.breadcrumbsIntegration({
      console: false,
    }),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        'api.aiboucrew.com',
        /^https:\/\/api\.aiboucrew\.com/,
      ],
    }),
  ],
  tracesSampleRate: 1,
  release: packageInfo.version,
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideSocket>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ProvideSocket>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
