import useIsSessionExpired from 'hooks/Auth/useIsSessionExpired'
import { Navigate, useLocation } from 'react-router-dom'

function GuestOnly({ children }: { children: JSX.Element }) {
  const sessionExpired = useIsSessionExpired()
  const { pathname } = useLocation()

  if (!sessionExpired && pathname !== '/signup') {
    return <Navigate to="/" />
  }

  return children
}

export default GuestOnly
