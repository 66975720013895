import { sessionAtom } from 'atoms/Session'
import { gqlClientAtom } from 'gql'
import { BusinessWorkplace, UpdateWorkplaceInput } from 'gql/types'
import { atom } from 'jotai'
import { GetWorkplace, UpdateWorkplace } from './_gql'

const queryResultAtom = atom<BusinessWorkplace | null>(null)

export const workplaceAtom = atom(
  get => get(queryResultAtom),
  (get, set) => {
    const queryData = async () => {
      const client = get(gqlClientAtom)
      const workplaceId = get(sessionAtom)?.workplaceId
      if (!client || !workplaceId) return
      const result = await client
        .query(GetWorkplace, {
          workplaceId,
        })
        .toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

const parse = (result: any): BusinessWorkplace | null => result?.data?.node

export const updateWorkplaceAtom = atom(
  null,
  async (get, set, input: UpdateWorkplaceInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(UpdateWorkplace, { input })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(workplaceAtom)
    }
  }
)
