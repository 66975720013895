import AlertIcon from '@mui/icons-material/Report'
import Button from '@mui/material/Button'
import * as Sentry from '@sentry/react'
import { sessionAtom } from 'atoms/Session'
import { gqlClientAtom } from 'gql'
import logout from 'helpers/logout'
import useIsSessionExpired from 'hooks/Auth/useIsSessionExpired'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSocket } from 'websocket/provider'

const AdminOnlyPage = () => {
  const sessionExpired = useIsSessionExpired()
  const navigate = useNavigate()
  const socket = useSocket()
  const session = useAtomValue(sessionAtom)
  const resetGqlClient = useSetAtom(gqlClientAtom)

  useEffect(() => {
    if (sessionExpired) {
      Sentry.setContext('session', { session })
      Sentry.captureMessage('AdminOnlyPage route sessionExpired')
      window.localStorage.removeItem('session')
      navigate('/login', { replace: true })
    }
  }, [navigate, session, sessionExpired])

  useEffect(() => {
    if (socket?.unAuthenticated) {
      Sentry.setContext('session', { session })
      Sentry.captureMessage('AdminOnlyPage socket unAuthenticated')
      window.localStorage.removeItem('session')
      navigate('/login', { replace: true })
    }
  }, [navigate, session, socket?.unAuthenticated])

  const handleLogout = async () => {
    await logout()
    resetGqlClient()
  }

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 20,
          color: 'red',
        }}
      >
        <AlertIcon style={{ marginRight: 20 }} />
        您無分店管理權限
      </div>
      <div>
        <Button variant="outlined" onClick={handleLogout}>
          登出
        </Button>
      </div>
    </div>
  )
}

export default AdminOnlyPage
