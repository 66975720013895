import Typography from '@mui/material/Typography'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import { BusinessWorkplaceShift } from 'gql/types'
import getShiftEndDateTime from 'helpers/Shift/getEndDateTime'
import getShiftStartDateTime from 'helpers/Shift/getStartDateTime'
import ClockIn from './_components/ClockIn'
import ClockOut from './_components/ClockOut'

interface Props {
  shift: BusinessWorkplaceShift
}

function AttendanceState({ shift }: Props) {
  const now = new Date()
  const shiftStartDateTime = getShiftStartDateTime(shift)
  const shiftEndDateTime = getShiftEndDateTime(shift)

  const isStarted = !isBefore(now, shiftStartDateTime)
  const isOngoing = !isAfter(now, shiftEndDateTime)

  if (!isStarted) return <div style={{ flex: 1 }} />

  if (!shift.linkedAttendance)
    return (
      <Typography style={{ color: 'red', fontSize: 12 }}>上班未打卡</Typography>
    )

  const attendance = shift.linkedAttendance
  const leaveRequests = shift.approvedLeaveRequests

  const isAbsent =
    !attendance.clockInOrigin &&
    !attendance.clockOutOrigin &&
    leaveRequests.length === 0

  if (isAbsent) {
    return <Typography style={{ color: 'red', fontSize: 12 }}>曠班</Typography>
  }

  const isAbsentWithLeave =
    !attendance.clockInOrigin &&
    !attendance.clockOutOrigin &&
    leaveRequests.length > 0

  if (isAbsentWithLeave) {
    return (
      <Typography style={{ color: 'red', fontSize: 12 }}>
        曠班 (已請假)
      </Typography>
    )
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <ClockIn attendance={attendance} />
      {isOngoing && !attendance.clockOutOrigin ? (
        <Typography style={{ fontSize: 12 }}>執勤中</Typography>
      ) : (
        <ClockOut attendance={attendance} />
      )}
    </div>
  )
}

export default AttendanceState
