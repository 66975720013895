import { sessionAtom } from 'atoms/Session'
import debug from 'debug'
import { gqlClientAtom } from 'gql'
import {
  BusinessWorkplace,
  CreateWorkplaceInput,
  DeleteWorkplaceInput,
  SetSchedulerPreferenceInput,
  UpdateWorkplaceInput,
} from 'gql/types'
import { atom } from 'jotai'
import { Client } from 'urql'
import {
  CreateWorkplace,
  DeleteWorkplace,
  GetBusinessWorkplaces,
  SetSchedulerPreference,
  SessionBusinessWorkplaces_UpdateWorkplace,
} from './_gql'

const log = debug('crew:atoms/Session/Business/Workplaces')

export const queryResultAtom = atom<BusinessWorkplace[] | null>(null)

export const updatedWorkplacesAtom = atom<any[]>([])

export const workplacesAtom = atom(
  get => {
    const workplaces = get(queryResultAtom)
    if (!workplaces) return null
    const updatedWorkplaces = get(updatedWorkplacesAtom)
    return [
      ...workplaces.filter(
        workplace => !updatedWorkplaces.find(({ id }) => id === workplace.id)
      ),
      ...updatedWorkplaces.map(updatedWorkplace => {
        const workplace = workplaces.find(
          ({ id }) => id === updatedWorkplace.id
        )
        return {
          ...workplace,
          ...updatedWorkplace,
        }
      }),
    ] as BusinessWorkplace[]
  },
  (get, set) => {
    const queryData = async () => {
      const client = get(gqlClientAtom)
      const businessId = get(sessionAtom)?.businessId
      if (!client || !businessId) return
      const result = await client
        .query(GetBusinessWorkplaces, {
          businessId,
        })
        .toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

workplacesAtom.onMount = runQuery => {
  log('workplacesAtom.onMount')
  runQuery()
}

const parse = (result: any): BusinessWorkplace[] | null =>
  result?.data?.businessWorkplaces

export const createWorkplaceAtom = atom(
  null,
  async (get, set, input: CreateWorkplaceInput) => {
    const client: Client | null = get(gqlClientAtom)
    const businessId = get(sessionAtom)?.businessId
    if (!client) throw new Error('gqlClient not setup')
    if (!businessId) throw new Error('businessId is null')
    const { data, error } = await client
      .mutation(CreateWorkplace, {
        input: {
          ...input,
          workplace: {
            ...input.workplace,
            businessId,
          },
        },
      })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(workplacesAtom)
    }
    return data.createWorkplace.workplace.id
  }
)

export const updateWorkplaceAtom = atom(
  null,
  async (get, set, input: UpdateWorkplaceInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(SessionBusinessWorkplaces_UpdateWorkplace, { input })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(workplacesAtom)
    }
  }
)

export const deleteWorkplaceAtom = atom(
  null,
  async (get, set, input: DeleteWorkplaceInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(DeleteWorkplace, { input })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(workplacesAtom)
    }
  }
)

export const setWorkplaceSchedulerPreferenceAtom = atom(
  null,
  async (get, set, input: SetSchedulerPreferenceInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(SetSchedulerPreference, { input })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(workplacesAtom)
    }
  }
)
