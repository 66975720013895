import * as Sentry from '@sentry/react'
import { sessionAtom } from 'atoms/Session'
import getUnixTime from 'date-fns/getUnixTime'
import { useAtomValue } from 'jotai'

function useIsSessionExpired() {
  const session = useAtomValue(sessionAtom)
  const currentUnixTime = getUnixTime(Date.now())
  Sentry.setContext('session', { session })
  Sentry.setContext('currentUnixTime', { currentUnixTime })
  const expired = !(session.expireAt && session.expireAt > currentUnixTime)
  if (!!session.expireAt && expired) {
    Sentry.captureMessage('useIsSessionExpired sessionExpired')
  }
  return expired
}

export default useIsSessionExpired
