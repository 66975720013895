import { useMemo } from 'react'
import useDailyReportTemplate from './useDailyReportTemplate'
import Delta from 'quill-delta'

function useReportTemplate() {
  const dailyReportTemplate = useDailyReportTemplate()

  const reportTemplate = useMemo(() => {
    if (!dailyReportTemplate?.templateJson) return null
    const templateJson = JSON.parse(dailyReportTemplate.templateJson)
    return new Delta(JSON.parse(templateJson.report))
  }, [dailyReportTemplate])

  return reportTemplate
}

export default useReportTemplate
