import { useAtom, useAtomValue } from 'jotai'
import { employeeLeaderboardsAtom } from '../_atoms/MonthlyReportsSummaryEmployeeLeaderboards'
import { dateRangeAtom } from '../../../_atoms'

const useEmployeeLeaderboards = () => {
  const dateRange = useAtomValue(dateRangeAtom)
  return useAtom(employeeLeaderboardsAtom(dateRange))
}

export default useEmployeeLeaderboards
