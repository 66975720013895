import React from 'react'
import UpIcon from '@mui/icons-material/PlayArrow'

interface Props {
  margin: number | null | undefined
}

function Margin({ margin }: Props) {
  if (!margin) return null

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <UpIcon
        style={{
          color: margin >= 0 ? 'green' : 'red',
          width: 16,
          height: 16,
          transform: margin >= 0 ? 'rotate(270deg)' : 'rotate(90deg)',
        }}
      />
      <span style={{ color: margin >= 0 ? 'green' : 'red' }}>
        {`${(margin * 100).toFixed(1)}%`}
      </span>
    </div>
  )
}

export default Margin
