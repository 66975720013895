import { useSetAtom } from 'jotai'
import { setWorkplaceDailyReportTemplateAtom } from '../_atoms/DailyReportTemplate'
import useDailyReportTemplate from './useDailyReportTemplate'

function useSetReportTemplate() {
  const dailyReportTemplate = useDailyReportTemplate()
  const setDailyReportTemplate = useSetAtom(setWorkplaceDailyReportTemplateAtom)

  if (!dailyReportTemplate) return () => {}
  return (workplaceId: string, reportTemplate: string, refetch?: boolean) =>
    setDailyReportTemplate(
      {
        dailyReportTemplate: {
          workplaceId,
          templateJson: JSON.stringify({
            ...JSON.parse(dailyReportTemplate?.templateJson!),
            report: reportTemplate,
          }),
        },
      },
      refetch
    )
}

export default useSetReportTemplate
