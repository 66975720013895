import { gqlClientAtom } from 'gql'
import {
  BusinessWorkplaceDailyReportTemplate,
  SetDailyReportTemplateInput,
} from 'gql/types'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import {
  DailyReportsPage_GetBusinessWorkplaceDailyReportTemplate,
  DailyReportsPage_SetBusinessWorkplaceDailyReportTemplate,
} from './_gql'

const queryResultAtom = atomFamily((workplaceId?: string) =>
  atom<BusinessWorkplaceDailyReportTemplate | null>(null)
)

export const dailyReportTemplateAtom = atomFamily((workplaceId?: string) =>
  atom(
    get => get(queryResultAtom(workplaceId)),
    (get, set) => {
      const queryData = async () => {
        const client = get(gqlClientAtom)
        if (!client || !workplaceId) return
        const result = await client
          .query(DailyReportsPage_GetBusinessWorkplaceDailyReportTemplate, {
            workplaceId,
          })
          .toPromise()
        if (result.data) {
          set(queryResultAtom(workplaceId), parse(result))
        }
      }
      queryData()
    }
  )
)

const parse = (result: any): BusinessWorkplaceDailyReportTemplate | null =>
  result?.data?.businessWorkplaceDailyReportTemplate

export const setWorkplaceDailyReportTemplateAtom = atom(
  null,
  async (get, set, input: SetDailyReportTemplateInput, refetch = true) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(DailyReportsPage_SetBusinessWorkplaceDailyReportTemplate, {
        input,
      })
      .toPromise()
    if (error) {
      throw error
    } else {
      if (refetch) {
        set(dailyReportTemplateAtom(input.dailyReportTemplate?.workplaceId!))
      }
    }
  }
)
