import { gqlClientAtom } from 'gql'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { DailyReportsPageHeader_LaborCost } from './_gql'

const queryResultAtom = atomFamily((input: string) => atom<number | null>(null))

export const laborCostAtom = atomFamily((input: string) =>
  atom(
    get => get(queryResultAtom(input)),
    (get, set) => {
      const queryData = async () => {
        const client = get(gqlClientAtom)
        if (!client) return
        const [workplaceId, startDate, endDate] = input.split(':')
        const result = await client
          .query(DailyReportsPageHeader_LaborCost, {
            workplaceId,
            startDate,
            endDate,
          })
          .toPromise()
        if (result.data) {
          set(queryResultAtom(input), parse(result))
        }
      }
      queryData()
    }
  )
)

const parse = (result: any): number | null =>
  result?.data?.businessWorkplaceLaborCost
