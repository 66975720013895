import { useAtom } from 'jotai'
import { laborCostAtom } from '../_atoms/LaborCost'
import { useEffect } from 'react'
import useWorkplaceId from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useWorkplaceId'
import useDateRange from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useDateRange'
import format from 'date-fns/format'

function useLaborCost() {
  const workplaceId = useWorkplaceId()
  const { startDate, endDate } = useDateRange()
  const sDate = format(startDate, 'yyyy-MM-dd')
  const eDate = format(endDate, 'yyyy-MM-dd')
  const [laborCost, query] = useAtom(
    laborCostAtom(`${workplaceId}:${sDate}:${eDate}`)
  )

  useEffect(() => query(), [query])

  return laborCost
}

export default useLaborCost
