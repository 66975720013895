import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Column, Row, Text } from '@react-email/components'
import getRoundedNumber from 'helpers/getRoundedNumber'
import { useEffect } from 'react'
import useEmployeeLeaderboards from './_hooks/useEmployeeLeaderboards'

function EmployeeLeaderboards() {
  const [employeeLeaderboards, queryEmployeeLeaderboards] =
    useEmployeeLeaderboards()

  useEffect(() => queryEmployeeLeaderboards(), [queryEmployeeLeaderboards])

  if (!employeeLeaderboards)
    return (
      <div style={{ height: 80, width: '100%' }}>
        <CircularProgress size={20} style={{ marginRight: 16 }} />
        <Typography>載入資料中...</Typography>
      </div>
    )

  return (
    <>
      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>簽核工時 Top 5</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>員工</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>簽核工時</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {employeeLeaderboards?.attendanceHours.map(attendanceHour => (
        <Row>
          <Column width={'60%'}>
            <Text style={{ margin: '0 8px', color: 'transparent' }}>dummy</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'50%'}>
                <Text style={{ margin: 8 }}>{attendanceHour.name}</Text>
              </Column>
              <Column align="right" width={'50%'}>
                <Text style={{ margin: 8, color: '#d34500' }}>
                  {getRoundedNumber(attendanceHour.value!)} 小時
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}

      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>加班工時 Top 5</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>員工</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>加班工時</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {employeeLeaderboards?.overtimeHours.map(overtimeHour => (
        <Row>
          <Column width={'60%'}>
            <Text style={{ margin: '0 8px', color: 'transparent' }}>dummy</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'50%'}>
                <Text style={{ margin: 8 }}>{overtimeHour.name}</Text>
              </Column>
              <Column align="right" width={'50%'}>
                <Text style={{ margin: 8, color: '#d34500' }}>
                  {getRoundedNumber(overtimeHour.value!)} 小時
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}

      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>請假次數 Top 5</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>員工</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>請假次數</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {employeeLeaderboards?.leaveCounts.map(leaveCount => (
        <Row>
          <Column width={'60%'}>
            <Text style={{ margin: '0 8px', color: 'transparent' }}>dummy</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'50%'}>
                <Text style={{ margin: 8 }}>{leaveCount.name}</Text>
              </Column>
              <Column align="right" width={'50%'}>
                <Text style={{ margin: 8, color: '#d34500' }}>
                  {leaveCount.value}次
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}

      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>忘打卡次數 Top 5</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>員工</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>忘打卡次數</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {employeeLeaderboards?.missedClockInOrOutCounts.map(
        missedClockInOrOutCounts => (
          <Row>
            <Column width={'60%'}>
              <Text style={{ margin: '0 8px', color: 'transparent' }}>
                dummy
              </Text>
            </Column>
            <Column width={'40%'}>
              <Row>
                <Column align="center" width={'50%'}>
                  <Text style={{ margin: 8 }}>
                    {missedClockInOrOutCounts.name}
                  </Text>
                </Column>
                <Column align="right" width={'50%'}>
                  <Text style={{ margin: 8, color: '#d34500' }}>
                    {missedClockInOrOutCounts.value}次
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
        )
      )}

      <Row style={{ backgroundColor: '#ddd' }}>
        <Column width={'60%'}>
          <Text style={{ margin: '0 8px' }}>遲到次數 Top 5</Text>
        </Column>
        <Column width={'40%'}>
          <Row>
            <Column align="center" width={'50%'}>
              <Text style={{ margin: 8 }}>員工</Text>
            </Column>
            <Column align="right" width={'50%'}>
              <Text style={{ margin: 8 }}>遲到次數</Text>
            </Column>
          </Row>
        </Column>
      </Row>

      {employeeLeaderboards?.lateCounts.map(lateCount => (
        <Row>
          <Column width={'60%'}>
            <Text style={{ margin: '0 8px', color: 'transparent' }}>dummy</Text>
          </Column>
          <Column width={'40%'}>
            <Row>
              <Column align="center" width={'50%'}>
                <Text style={{ margin: 8 }}>{lateCount.name}</Text>
              </Column>
              <Column align="right" width={'50%'}>
                <Text style={{ margin: 8, color: '#d34500' }}>
                  {lateCount.value}次
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}
    </>
  )
}

export default EmployeeLeaderboards
