import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import styles from './index.module.css'

const weekdayLocale = ['ㄧ', '二', '三', '四', '五', '六', '日']

interface Props {
  date: Date
}

function CellDate({ date }: Props) {
  const weekdayIndex = getDay(date) > 0 ? getDay(date) - 1 : 6
  const weekday = weekdayLocale[weekdayIndex]
  const formattedDate = format(date, 'M/dd')
  return (
    <div className={styles.root}>
      <span className={styles.weekday}>{weekday}</span>
      <span className={styles.date}>{formattedDate}</span>
    </div>
  )
}

export default CellDate
