import ChartIcon from '@mui/icons-material/StackedBarChart'
import { IconButton } from '@mui/material'
import DailyReportsChartDialog from './_components/DailyReportChartsDialog'
import { useState } from 'react'

function ChartButton() {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <div style={{ marginRight: 16 }}>
      <IconButton color="primary" onClick={() => setShowDialog(true)}>
        <ChartIcon />
      </IconButton>
      <DailyReportsChartDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </div>
  )
}

export default ChartButton
