import { useMemo } from 'react'
import useDailyReportTemplate from './useDailyReportTemplate'
import { CustomField } from '../_components/CustomFields'

function useCustomFields() {
  const dailyReportTemplate = useDailyReportTemplate()

  const customFields = useMemo(() => {
    if (!dailyReportTemplate?.templateJson) return []
    return JSON.parse(dailyReportTemplate.templateJson)[
      'fields'
    ] as CustomField[]
  }, [dailyReportTemplate])

  return customFields
}

export default useCustomFields
