import { getRemoteConfig, getValue } from 'firebase/remote-config'
import { app } from '..'

export const remoteConfig = getRemoteConfig(app)
remoteConfig.settings.minimumFetchIntervalMillis = 3600000
remoteConfig.defaultConfig = {
  web_min_version: '1.0.0',
  web_latest_version: '1.0.0',
}

export const getRemoteConfigValue = (key: string) => getValue(remoteConfig, key)
