import { useAtom, useAtomValue } from 'jotai'
import { dateRangeAtom } from '../_atoms'
import { monthlyReportSummaryAtom } from '../_atoms/MonthlyReportsSummary'

const useMonthlyReportSummary = () => {
  const dateRange = useAtomValue(dateRangeAtom)
  return useAtom(monthlyReportSummaryAtom(dateRange))
}

export default useMonthlyReportSummary
