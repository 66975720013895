import format from 'date-fns/format'
import getToday from 'helpers/getToday'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

function useYear() {
  const today = getToday()
  const location = useLocation()
  const { year } = queryString.parse(location.search)
  return (year || format(today, 'yyyy')) as string
}

export default useYear
