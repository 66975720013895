import { useAtom, useAtomValue } from 'jotai'
import { workplaceLeaderboardsAtom } from '../_atoms/MonthlyReportsSummaryWorkplaceLeaderboards'
import { dateRangeAtom } from '../../../_atoms'

const useWorkplaceLeaderboards = () => {
  const dateRange = useAtomValue(dateRangeAtom)
  return useAtom(workplaceLeaderboardsAtom(dateRange))
}

export default useWorkplaceLeaderboards
