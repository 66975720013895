import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import addMonths from 'date-fns/addMonths'
import format from 'date-fns/format'
import getToday from 'helpers/getToday'
import { useMemo } from 'react'
import useDateRange from '../../../../_hooks/useDateRange'
import startOfMonth from 'date-fns/startOfMonth'
import { Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

function MonthSelect() {
  const [, setSearchParams] = useSearchParams()
  const { startDate } = useDateRange()
  const dates = useMemo(() => {
    const today = getToday()
    return [...Array(12).keys()].map(index =>
      addMonths(startOfMonth(today), -index)
    )
  }, [])

  const onSelect = (event: SelectChangeEvent<string>) => {
    setSearchParams(prev => {
      prev.delete('start')
      prev.append('start', event.target.value)
      return prev
    })
  }

  return (
    <FormControl
      style={{ width: 160, marginRight: 8, flexShrink: 0 }}
      variant="outlined"
    >
      <Select value={format(startDate, 'yyyy-MM-dd')} onChange={onSelect}>
        {dates.map(date => (
          <MenuItem value={format(date, 'yyyy-MM-dd')}>
            <Typography style={{ fontWeight: 'bold', color: 'grey' }}>
              {format(date, 'yyyy 年 M 月')}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MonthSelect
