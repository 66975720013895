import Delta from 'quill-delta'
import ReactQuill from 'react-quill-new'

interface Props {
  readonly: boolean
  report: Delta
  onChanged: (delta: Delta) => void
}

function Report({ readonly, report, onChanged }: Props) {
  const myColors = [
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'cyan',
    'purple',
    'white',
    'black',
  ]

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ]

  const handleChange = (
    value: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    onChanged(editor.getContents())
  }

  return (
    <div style={{ flex: 1 }}>
      <ReactQuill
        readOnly={readonly}
        theme="snow"
        modules={modules}
        formats={formats}
        value={report}
        onChange={handleChange}
      />
    </div>
  )
}

export default Report
