import { useAtom } from 'jotai'
import { resellerInvoiceAtom } from '../_atoms/ResellerInvoiceQuery'
import { useSearchParams } from 'react-router-dom'

const useResellerInvoice = () => {
  const [searchParams] = useSearchParams()
  const invoiceId = searchParams.get('invoice_id')
  const reseller = searchParams.get('reseller')
  return useAtom(resellerInvoiceAtom(`${invoiceId}:${reseller}`))
}

export default useResellerInvoice
