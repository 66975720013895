import { getEnv } from 'env'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  params: any
}

function ECPayCheckout({ params }: Props) {
  const formRef = useRef<any>()
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!submitted && formRef.current) {
      setSubmitted(true)
      formRef.current.submit()
    }
  }, [submitted])

  const paramInputs = React.useMemo(() => {
    let inputs: any[] = []
    Object.keys(params).forEach(key => {
      inputs = [
        ...inputs,
        <input type="hidden" id={key} name={key} value={params[key]} />,
      ]
    })
    return inputs
  }, [params])

  return (
    <form
      ref={formRef}
      id="ecpay"
      action={getEnv().ecpayCheckoutUrl}
      method="post"
    >
      {paramInputs}
    </form>
  )
}

export default ECPayCheckout
