import React from 'react'
import SelectMui from '@mui/material/Select'
import TextField from 'components/atoms/TextField'

function Select({ children, ...props }: any) {
  return (
    <SelectMui {...props} input={<TextField />}>
      {children}
    </SelectMui>
  )
}

export default Select
