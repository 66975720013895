import { sessionAtom } from 'atoms/Session'
import { businessAtom } from 'atoms/Session/Business'
import { workplaceAtom } from 'atoms/Session/Workplace'
import LoadingPrompt from 'components/prompts/LoadingPrompt'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect } from 'react'

interface Props {
  children: any
}

function ContextProvider({ children }: Props) {
  const workplaceId = useAtomValue(sessionAtom)?.workplaceId
  const business = useAtomValue(businessAtom)
  const [workplace, queryWorkplace] = useAtom(workplaceAtom)

  useEffect(() => queryWorkplace(), [queryWorkplace])

  if (!business || (workplaceId && !workplace)) {
    return <LoadingPrompt />
  }

  return children
}

export default ContextProvider
