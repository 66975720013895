import { User } from 'gql/types'
import { atom, useAtomValue } from 'jotai'
import { useState } from 'react'
import ImpersonateDialog from './_components/ImpersonateDialog'
import axios from 'axios'
import { getEnv } from 'env'
import { useNavigate } from 'react-router-dom'
import useImmerReducer from 'helpers/useImmerReducer'
import { sessionAtom, sessionReducer } from 'atoms/Session'
import sleep from 'utils/sleep'
import Fab from '@mui/material/Fab'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { currentUserAtom } from 'components/layouts/PageLayout/AppBar/_components/CurrentUserName'

const url = getEnv().authApiUrl

export const impersonatorAtom = atom<User | null>(null)

function ImpersonatorPrompt({ children }: { children: JSX.Element }) {
  const currentUser = useAtomValue(currentUserAtom)
  const navigate = useNavigate()
  const [, dispatch] = useImmerReducer(sessionAtom, sessionReducer)
  const impersonator = useAtomValue(impersonatorAtom)

  const [showDialog, setShowDialog] = useState(false)

  const handleOnClick = async () => {
    const { data } = await axios.post(
      `${url}/stop_impersonate`,
      {},
      {
        withCredentials: true,
      }
    )

    dispatch({
      type: 'update',
      session: {
        userId: data.userId,
        businessId: data.businessId,
        employeeId: data.employeeId,
        workplaceId: data.workplaceId,
      },
    })

    await sleep(1000)

    navigate('/', { replace: true })
    window.location.reload()
  }

  if (!currentUser?.isImpersonator && !impersonator) return children

  if (!impersonator)
    return (
      <>
        {children}
        <Fab
          variant="extended"
          style={{
            position: 'absolute',
            bottom: 8,
            left: 8,
            zIndex: 6000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#D34500',
            color: 'white',
          }}
          onClick={() => setShowDialog(true)}
        >
          開啟上帝視角 <VisibilityIcon />
        </Fab>
        <ImpersonateDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
        />
      </>
    )

  return (
    <>
      {children}
      <Fab
        variant="extended"
        style={{
          position: 'absolute',
          bottom: 8,
          left: 8,
          zIndex: 6000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#D34500',
          color: 'white',
        }}
        onClick={handleOnClick}
      >
        上帝視角： {impersonator.name} <VisibilityIcon />
      </Fab>
    </>
  )
}

export default ImpersonatorPrompt
