import { useAtom } from 'jotai'
import { workplacesAtom } from '../_atoms/Workplaces'
import { useEffect } from 'react'

function useWorkplaces() {
  const [workplaces, query] = useAtom(workplacesAtom)

  useEffect(() => query(), [query])

  return workplaces
}

export default useWorkplaces
