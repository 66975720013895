import MuiAvatar from '@mui/material/Avatar'

interface Props {
  user: {
    name: string
    avatar?: string | null
  }
  size?: number
  style?: any
  className?: any
}

function Avatar({ user, size = 40, style, className }: Props) {
  if (!user) {
    return (
      <MuiAvatar
        className={className}
        style={{ width: size, height: size, ...style }}
      />
    )
  }
  if (user.avatar) {
    return (
      <MuiAvatar
        alt={user.name}
        className={className}
        src={user.avatar.replace('w150.jpg', 'w300.jpg')}
        style={{ width: size, height: size, ...style }}
      />
    )
  }
  return (
    <MuiAvatar
      alt={user.name}
      className={className}
      style={{ width: size, height: size, ...style }}
    >
      {user.name.charAt(0).toUpperCase()}
    </MuiAvatar>
  )
}

export default Avatar
