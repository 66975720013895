import * as Sentry from '@sentry/react'
import axios from 'axios'
import debug from 'debug'
import { getEnv } from 'env'
import { Channel, ConnectionState, Socket } from 'phoenix'
import { make } from 'wonka'

const log = debug('crew:socket')

interface Props {
  businessId: string | undefined
  employeeId: string | undefined
  workplaceId: string | undefined
}

interface PushProps {
  event: string
  payload: object
}

class PhoenixSocket {
  params: { jwt?: string }
  socket: Socket
  timeoutId: any
  businessId: string | undefined
  employeeId: string | undefined
  workplaceId: string | undefined
  businessChannel: Channel | undefined
  workplaceChannel: Channel | undefined
  unAuthenticated: boolean | undefined

  constructor({ businessId, employeeId, workplaceId }: Props) {
    this.businessId = businessId
    this.employeeId = employeeId
    this.workplaceId = workplaceId
    this.params = {}
    this.socket = new Socket(getEnv().wsUrl, {
      params: () => this.params,
    })
    this.socket.onOpen(() => {
      log('onOpen')
      if (!this.businessChannel && businessId) {
        this.joinBusinessChannel()
      }
      if (!this.workplaceChannel && workplaceId) {
        this.joinWorkplaceChannel()
      }
    })
    this.socket.onMessage(message => {
      log('onMessage: %O', message)
    })
    this.socket.onError(error => {
      log('onError: %O', error)
      this.socket.disconnect()
      this.scheduleReconnect()
    })
    this.scheduleReconnect()
  }

  subscribeToConnectionState = () =>
    make<ConnectionState>(observer => {
      this.socket.onOpen(() => {
        observer.next(this.socket.connectionState())
      })
      this.socket.onError(() => {
        observer.next(this.socket.connectionState())
      })
      this.socket.onClose(() => {
        observer.next(this.socket.connectionState())
      })

      return () => {}
    })

  connect = async () => {
    log('connect')
    try {
      const token = await this.getSocketToken()
      this.params['jwt'] = token
      this.socket.connect()
    } catch (error: any) {
      if (
        error.message !== 'Network Error' &&
        error.message !== 'Request failed with status code 400'
      ) {
        log('getSocketToken: %O', error)
        Sentry.setContext('error', { error: JSON.stringify(error) })
        Sentry.captureMessage('getSocketToken error')
      }
    }
  }

  getSocketToken = async () => {
    const { data, status } = await axios.get(
      `${getEnv().authApiUrl}/socket_token`,
      { withCredentials: true }
    )
    if (status === 200) return data.token
    throw new Error(`getSocketToken error: ${status}`)
  }

  joinBusinessChannel = () => {
    this.businessChannel = this.socket.channel(`business:${this.businessId}`)
    this.businessChannel
      .join()
      .receive('ok', ({ messages }) => console.log('catching up', messages))
      .receive('error', ({ reason }) => console.log('failed join', reason))
      .receive('timeout', () =>
        console.log('Networking issue. Still waiting...')
      )
  }

  joinWorkplaceChannel = () => {
    this.workplaceChannel = this.socket.channel(`workplace:${this.workplaceId}`)
    this.workplaceChannel
      .join()
      .receive('ok', ({ messages }) => console.log('catching up', messages))
      .receive('error', ({ reason }) => console.log('failed join', reason))
      .receive('timeout', () =>
        console.log('Networking issue. Still waiting...')
      )
  }

  checkNewData = (context: string, latestUpdatedAt: string) => {
    this.pushToWorkplaceChannel({
      event: 'hasNewData?',
      payload: {
        context,
        lastFetchTimestamp: latestUpdatedAt,
      },
    })
  }

  checkNewDataWithDateRange = (
    context: string,
    latestUpdatedAt: string,
    dateRange: string,
    activeEmployeesOnly: boolean
  ) => {
    const [startDate, endDate] = dateRange.split(':')
    this.pushToWorkplaceChannel({
      event: 'hasNewData?',
      payload: {
        context,
        lastFetchTimestamp: latestUpdatedAt,
        startDate,
        endDate,
        activeEmployeesOnly,
      },
    })
  }

  getCurrentTime = () => {
    this.pushToWorkplaceChannel({
      event: 'current_time',
      payload: {},
    })
  }

  subscribeToBusinessChannel = () =>
    make(observer => {
      this.socket.onMessage(message => {
        if (message.topic === `business:${this.businessId}`) {
          observer.next(message)
        }
      })

      return () => {}
    })

  pushToWorkplaceChannel = ({ event, payload }: PushProps) => {
    log('pushToWorkplaceChannel: %O', { event, payload })
    this.workplaceChannel?.push(event, payload)
  }

  subscribeToWorkplaceChannel = () =>
    make(observer => {
      this.socket.onMessage(message => {
        if (message.topic === `workplace:${this.workplaceId}`) {
          observer.next(message)
        }
      })

      return () => {}
    })

  scheduleReconnect = () => {
    setTimeout(async () => {
      log('scheduleReconnect connect')
      this.connect()
    }, 1000)
  }
}

export default PhoenixSocket
