function DeviceFrame() {
  return (
    <div
      style={{
        position: 'absolute',
        width: 350,
        zIndex: 1,
      }}
    >
      <img
        src={process.env.PUBLIC_URL + '/iphone-frame.png'}
        alt=""
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}

export default DeviceFrame
